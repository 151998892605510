import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  HouseSummaryResultsDocument,
  HouseSummaryResultsQuery,
  HouseSummaryResultsQueryHookResult,
  HouseSummaryResultsQueryVariables,
} from 'src/graphql';
import {
  selectActiveConfigurationJobUuid,
  selectConfigType,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';

import { HouseSummaryResultsPartial } from 'src/graphql/subscriptions/houseSummaryResultsPartial';
import { NN } from 'src/typings/helpers';
import { ScatterDataPoint } from 'chart.js';
import { TAsset } from 'src/typings/configuration.types';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { getUnixFromDate } from 'src/utils/getUnixFromDate';
import { mergeSimulationResults } from 'src/utils/mergeSimulationResults';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSubscribeToQuery } from 'src/hooks/useSubscribeToQuery';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useHouseSummaryResults({
  assetUuid,
  jobId,
}: {
  assetUuid: TAsset['uuid'] | undefined;
  jobId?: string;
}) {
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const jobUuid = jobId || activeJobUuid;
  const resultsEndTimeState = useSelector(selectResultsEndTime);
  const configType = useSelector(selectConfigType);
  const resultsStartTimeState = useSelector(selectResultsStartTime);
  const simulationStatus = useSelector(selectSimulationStatus);

  const resultsStartTime = useMemo(() => {
    return UTCMoment.utc(resultsStartTimeState)
      .startOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [resultsStartTimeState]);

  const avoidLiveSubscriptionData = useMemo(() => {
    const noSimStatus = typeof simulationStatus === 'undefined';
    const simIsFinishedOrFailed = ['finished', 'failed'].includes(simulationStatus || '');
    const isCN = configType === 'CANARY_NETWORK';
    const indicatorMatchedWithEnd =
      UTCMoment.fromBackend(resultsEndTimeState).format('MMMM D') ===
      UTCMoment.utc().format('MMMM D');
    return noSimStatus ? true : isCN ? !indicatorMatchedWithEnd : simIsFinishedOrFailed;
  }, [configType, resultsEndTimeState, simulationStatus]);

  const { data, loading, error } = useSubscribeToQuery<
    HouseSummaryResultsQuery,
    HouseSummaryResultsQueryVariables
  >({
    queryDoc: HouseSummaryResultsDocument,
    subscriptionDoc: HouseSummaryResultsPartial,
    avoidLiveSubscriptionData: avoidLiveSubscriptionData,
    variables: {
      jobId: jobUuid || '',
      assetUuid: assetUuid || '',
      // @ts-ignore
      startTime: resultsStartTime,
    },
    updateQuery: (prev, { subscriptionData }) => {
      const resultsPartial: NonNullable<
        HouseSummaryResultsQueryHookResult['data']
      >['simulationResults'] = subscriptionData?.data.simulationAreaResultsPartial[0];
      if (!resultsPartial) return prev;

      const currentMarketUTC = UTCMoment.fromBackend(
        resultsPartial.currentMarket,
        BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
      );
      const weekEnd = UTCMoment.utc(resultsEndTimeState).endOf('day');
      const weekStart = UTCMoment.utc(resultsStartTimeState).startOf('day');

      // Don't update data while current market date is not a part of selected week (in WeeklyResultsIndicator)
      if (configType !== 'CANARY_NETWORK' && !currentMarketUTC.isBetween(weekStart, weekEnd)) {
        return prev;
      }

      const next = Object.assign({}, prev, {
        simulationResults: {
          ...prev.simulationResults,
          ...resultsPartial,

          areaThroughput: JSON.stringify(
            mergeSimulationResults.areaThroughput(
              JSON.parse(prev.simulationResults?.areaThroughput || 'null'),
              JSON.parse(resultsPartial.areaThroughput || 'null'),
            ),
          ),
          marketSummary: mergeSimulationResults.marketSummary(
            prev.simulationResults?.marketSummary,
            resultsPartial.marketSummary,
          ),
        },
      });

      return next;
    },
  });

  const netEnergyData = useMemo(() => {
    const output: ScatterDataPoint[] = [];
    const areaThroughput: NN<
      TSimulationResultData['energyTradeProfileAreaThroughput']
    >['areaThroughput'] = JSON.parse(data?.simulationResults?.areaThroughput || 'null');

    if (!areaThroughput) return output;

    Object.entries(areaThroughput).forEach(([date, values]) => {
      const timestamp = getUnixFromDate(date, BACKEND_DATE_FORMATS.AREA_THROUGHPUT);

      const netEnergyFlow = values.net_energy_flow?.peak_energy_kWh;

      if (typeof netEnergyFlow === 'number' && netEnergyFlow !== 0) {
        output.push({
          x: timestamp,
          y: netEnergyFlow,
        });
      }
    });

    return output;
  }, [data?.simulationResults?.areaThroughput]);

  const pricesData = useMemo(() => {
    const output: ScatterDataPoint[] = [];
    const marketSummary = data?.simulationResults?.marketSummary;

    if (!marketSummary) return output;

    marketSummary.forEach((item) => {
      if (!item) return;
      output.push({
        x: new Date(item.timestamp).getTime(),
        y: item.averageEnergyRate || 0,
      });
    });

    return output;
  }, [data?.simulationResults?.marketSummary]);

  return {
    netEnergyData,
    pricesData,
    currentMarket: data?.simulationResults?.currentMarket || '',
    data,
    loading,
    error,
  };
}
