import React, { useCallback, useMemo } from 'react';
import {
  TCanaryRegistryUserTileProps,
  TCanaryRegistryUsersListProps,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  selectActiveConfigurationUuid,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';

import { CanaryRegistryAssetTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetTile';
import { CanaryRegistryUserTile } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryUserTile';
import { EUserRoles } from 'src/typings/base-types';
import { InviteStatusOutputEnum } from 'src/graphql';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import s from './CanaryRegistryList.module.scss';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

export const CanaryRegistryUsersList: React.FC<TCanaryRegistryUsersListProps> = ({
  assetsData,
  usersData,
  //usersToAssetRelations,
  selectedItems,
  //searchValue,
  onItemClick,
  getParentUuid,
  getOwners,
  getAppliedUser,
}) => {
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const communityAsset = useSelector(selectCommunityAsset);
  const assetsUnderSelectedUuid = useSelector(selectAssetsUnderUuid(communityAsset?.uuid));
  const assetValues = useSelector(selectAssetsValues);
  //const isResearcher = userRole === EUserRoles.Researcher;

  const list = useMemo<TCanaryRegistryUserTileProps['data'][]>(() => {
    return usersData.map((user) => {
      const assignedAsset = assetsData.find((item) => item.uuid === user.uuid);

      let data = {};
      if (assignedAsset) {
        const owners = getOwners(assignedAsset.uuid);
        const appliedUser = getAppliedUser(assignedAsset.uuid);
        const parentUuid = getParentUuid(assignedAsset.uuid);

        // find children assets in assetsData
        const children = assetsData.filter(
          (asset) => getParentUuid(asset.uuid) === assignedAsset.uuid,
        );

        data = {
          uuid: assignedAsset.uuid,
          name: assignedAsset.name,
          type: assignedAsset.type,
          icon: 'house' as TIconNames,
          owners: owners,
          children,
          status: 'Applied', //(appliedUser ? 'Applied' : usersToAssetRelations[user.name]) as TCanaryAssetState,
          appliedUserName: appliedUser?.userName,
          appliedUserAvatar: appliedUser?.avatar,
          parentUuid: assignedAsset.type !== 'Area' && parentUuid ? parentUuid : null,
        };
      }
      return {
        uuid: user.uuid,
        name: user.name,
        isAggregator: user.isAggregator,
        isGridOperator: user.isGridOperator,
        assignedAssets: data,
        joined: ![InviteStatusOutputEnum.Blocked, InviteStatusOutputEnum.Invited].includes(
          user.canaryInvitations.find((item) => item.configUuid === configUuid)
            ?.status as InviteStatusOutputEnum,
        ),
        avatarUrl: user.avatarUrl,
        subtitle: user?.subtitle,
        isNewUser: user?.isNewUser,
      };
    });
  }, [assetsData, configUuid, getAppliedUser, getOwners, getParentUuid, usersData]);

  const selectboxUsersList = useMemo(() => usersData.map(({ name, uuid }) => ({ name, uuid })), [
    usersData,
  ]);

  const getHomesList = useCallback(
    (userName: string) =>
      assetsUnderSelectedUuid.reduce<TCanaryRegistryUserTileProps['homesList']>((acc, item) => {
        const owners = getOwners(item.uuid).filter((owner) => !owner.isAggregator);
        const name = assetValues[item.uuid].name as string;
        const isUserOwner = !!owners.find((item) => item.name === userName);
        if (isUserOwner || owners.length === 0) {
          acc.push({
            uuid: item.uuid,
            name,
          });
        }
        return acc;
      }, []),
    [assetValues, assetsUnderSelectedUuid, getOwners],
  );

  return (
    <div className={s.usersList}>
      {list.map((item) => {
        const isSelected = userRole === EUserRoles.DSO ? true : selectedItems.includes(item.name);
        const homesList = getHomesList(item.name);
        return (
          <React.Fragment key={item.name}>
            <CanaryRegistryUserTile
              data={item}
              selected={isSelected}
              onClick={onItemClick}
              homesList={homesList}
              withoutSelectedUIEffect={userRole === EUserRoles.DSO}
            />
            {isSelected &&
              Object.keys(item.assignedAssets).map((key, i) => {
                const asset = item.assignedAssets[key];
                return (
                  <React.Fragment key={i}>
                    <CanaryRegistryAssetTile
                      data={asset.data}
                      childrenCount={asset.children.length}
                      usersList={selectboxUsersList}
                      selected={false}
                    />
                    {asset.children.map((childAsset) => {
                      return (
                        <CanaryRegistryAssetTile
                          key={childAsset.uuid}
                          data={childAsset}
                          isChildren
                          usersList={selectboxUsersList}
                          selected={false}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};
