import { TAssetType, TUploadedProfile } from 'src/typings/base-types';
import { TFileFieldTemplate, TFileFieldWithValue } from 'src/utils/assetsFields/assetsFields.types';
import {
  TAreaDeviceInput,
  TDeviceTypesInput,
  TFiniteDieselGeneratorInput,
  THeatPumpInput,
  TInfiniteBusInput,
  TLoadInput,
  TMarketMakerInput,
  TPrepareDevicePayload,
  TPvInput,
  TStorageInput,
} from 'src/utils/mutationsGates/prepareAreaInput/prepareAreaInput.types';

import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { Maybe } from 'src/graphql';

/*
 *
 * Helpers
 *
 */
function getFile(file: TUploadedProfile | undefined) {
  if (typeof file === 'undefined') {
    return undefined;
  } else if (typeof file === 'string') {
    return file;
  } else {
    return uploadedFilesCache.get(file);
  }
}

function optional<T>(key: keyof T, object: Partial<T>) {
  if (key in object) {
    return { [key]: object[key] };
  }

  return null;
}

export const optionalOrNull = <T>(
  key: keyof T,
  object: Partial<T>,
): { [key: string]: Maybe<T[keyof T]> | undefined } => {
  if (key in object) {
    return { [key]: object[key] };
  }

  return { [key]: null };
};

function optionalFile(
  key: TFileFieldTemplate['name'],
  value: TFileFieldWithValue['value'] | undefined,
) {
  if (value) {
    return { [key]: getFile(value) || value };
  }

  return null;
}

/*
 *
 * Prepare functions
 *
 */
function prepareArea({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TAreaDeviceInput {
  type T = TAreaDeviceInput;

  const values = assetsValues[uuid];

  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('geoTagLocation', values),
    // ...(settingsData?.gridFeeType === GridFeeType.Percentage &&
    //   optional<T>('gridFeePercentage', values)),
    // ...(settingsData?.gridFeeType === GridFeeType.Constant &&
    ...optionalMethod<T>('gridFeeConstant', values),
    ...optionalMethod<T>('importCapacityKva', values),
    ...optionalMethod<T>('exportCapacityKva', values),
    ...optionalMethod<T>('coefficientPercentage', values),
    ...optionalMethod<T>('baselinePeakEnergyImportKwh', values),
    ...optionalMethod<T>('baselinePeakEnergyExportKwh', values),
    ...optionalMethod<T>('baselinePeakEnergyExportKwh', values),

    ...optionalMethod<T>('fixedMonthlyFee', values),
    // ...optional<T>('marketMakerRate', values),
    // ...optional<T>('feedInTariff', values),
    ...optionalMethod<T>('marketplaceMonthlyFee', values),
    ...optionalMethod<T>('assistanceMonthlyFee', values),
    ...optionalMethod<T>('taxesSurcharges', values),

    // TODO: fitAreaBoundary probably needs work
    fitAreaBoundary: true,
    children: [],
    marketMakerRate: values?.marketMakerRate ? values.marketMakerRate : 0,
    feedInTariff: values?.feedInTariff ? values.feedInTariff : 0,
    coefficientPercentage: values?.coefficientPercentage ? values?.coefficientPercentage : 0,
  };

  return output;
}

function prepareFiniteDieselGenerator({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TFiniteDieselGeneratorInput {
  type T = TFiniteDieselGeneratorInput;
  const values = assetsValues[uuid];

  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('geoTagLocation', values),
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('energyRate', values),
    ...optionalMethod<T>('maxAvailablePowerKw', values),
  };

  return output;
}

function prepareLoad({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TLoadInput {
  type T = TLoadInput;
  const { energyRateIncreasePerUpdate, ...values } = assetsValues[uuid];
  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    geoTagLocation: values.geoTagLocation,
    /*hrsPerDay: values.hrsPerDay,
    hrsOfDay: values.hrsOfDay
      ? convertHrsOfDay({ value: values.hrsOfDay, direction: 'forBE' })
      : null,*/
    ...optionalMethod<T>('avgPowerW', values),
    ...optionalMethod<T>('initialBuyingRate', values),
    ...optionalMethod<T>('finalBuyingRate', values),
    ...optionalMethod<T>('fitToLimit', values),
    ...optionalMethod<T>('updateInterval', values),
    ...optionalMethod<T>('useMarketMakerRate', values),
    ...optionalFile('dailyLoadProfile', values.dailyLoadProfile),
    dailyLoadProfileUuid: values.dailyLoadProfileUuid,
    libraryUUID: values.libraryUUID,
    forecastStreamEnabled: values.forecastStreamEnabled,
    // As per PH-1179: forecastStreamEnabled and allowExternalConnection should be synced
    allowExternalConnection: values.forecastStreamEnabled,
  };

  if (!output.fitToLimit) {
    if (energyRateIncreasePerUpdate && energyRateIncreasePerUpdate !== 'Varying rate') {
      output.energyRateIncreasePerUpdate = energyRateIncreasePerUpdate;
    }
  }

  return output;
}

function prepareHeatPump({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): THeatPumpInput {
  type T = THeatPumpInput;
  const values = assetsValues[uuid];
  console.log('values', values);
  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    geoTagLocation: values.geoTagLocation,
    ...optionalMethod<T>('minTempC', values),
    ...optionalMethod<T>('maxTempC', values),
    ...optionalMethod<T>('tankVolumeL', values),
    ...optionalMethod<T>('sourceType', values),
    ...optionalMethod<T>('initialTempC', values),
    ...optionalMethod<T>('maximumPowerRatingKw', values),
    ...optionalMethod<T>('initialBuyingRate', values),
    ...optionalMethod<T>('updateInterval', values),
    finalBuyingRate: values.useMarketMakerRate ? null : values.finalBuyingRate,
    useMarketMakerRate: values.useMarketMakerRate,
    ...optionalMethod<T>('preferredBuyingRate', values),
    ...optionalFile('consumptionKwhProfile', values.consumptionKwhProfile),
    ...optionalFile('externalTempCProfile', values.externalTempCProfile),
    consumptionKwhProfileUuid: values.consumptionKwhProfileUuid,
    externalTempCProfileUuid: values.externalTempCProfileUuid,
    // libraryUUID: values.libraryUUID,
  };

  return output;
}


function preparePV({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TPvInput {
  type T = TPvInput;

  const { energyRateDecreasePerUpdate, ...values } = assetsValues[uuid];

  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('geoTagLocation', values),
    ...optionalMethod<T>('capacityKw', values),
    ...optionalMethod<T>('initialSellingRate', values),
    ...optionalMethod<T>('finalSellingRate', values),
    ...optionalMethod<T>('fitToLimit', values),
    ...optionalMethod<T>('updateInterval', values),
    ...optionalMethod<T>('cloudCoverage', values),
    ...optionalFile('powerProfile', values.powerProfile),
    powerProfileUuid: values.powerProfileUuid,
    libraryUUID: values.libraryUUID,
    azimuth: values.azimuth,
    tilt: values.tilt,
    ...optional<T>('useMarketMakerRate', values),
    // forecastStreamEnabled: ,
    forecastStreamEnabled: values.forecastStreamEnabled,
    // As per PH-1179: forecastStreamEnabled and allowExternalConnection should be synced
    allowExternalConnection: values.forecastStreamEnabled,
  };

  if (!output.fitToLimit) {
    if (energyRateDecreasePerUpdate && energyRateDecreasePerUpdate !== 'Varying rate') {
      output.energyRateDecreasePerUpdate = energyRateDecreasePerUpdate;
    }
  }

  return output;
}

function prepareStorage({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TStorageInput {
  type T = TStorageInput;
  const { energyRateIncreasePerUpdate, energyRateDecreasePerUpdate, ...values } = assetsValues[
    uuid
  ];

  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('geoTagLocation', values),
    ...optionalMethod<T>('initialSoc', values),
    ...optionalMethod<T>('minAllowedSoc', values),
    ...optionalMethod<T>('batteryCapacityKwh', values),
    ...optionalMethod<T>('maxAbsBatteryPowerKw', values),
    ...optionalMethod<T>('capPriceStrategy', values),
    ...optionalMethod<T>('initialSellingRate', values),
    ...optionalMethod<T>('finalSellingRate', values),
    ...optionalMethod<T>('initialBuyingRate', values),
    ...optionalMethod<T>('finalBuyingRate', values),
    ...optionalMethod<T>('fitToLimit', values),
    ...optionalMethod<T>('updateInterval', values),
  };

  if (!output.fitToLimit) {
    if (typeof energyRateIncreasePerUpdate === 'number') {
      output.energyRateIncreasePerUpdate = energyRateIncreasePerUpdate;
    }

    if (typeof energyRateDecreasePerUpdate === 'number') {
      output.energyRateDecreasePerUpdate = energyRateDecreasePerUpdate;
    }
  }

  return output;
}

function prepareMarketMaker({
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TMarketMakerInput {
  type T = TMarketMakerInput;
  const values = assetsValues[uuid];

  const output: T = {
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('gridFeeConstant', values),
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('geoTagLocation', values),
    ...optionalMethod<T>('energyRate', values),
    ...optionalFile('energyRateProfile', values.energyRateProfile),
    energyRateProfileUuid: values.energyRateProfileUuid,
    libraryUUID: values.libraryUUID,
    ...optionalMethod<T>('gridConnected', values),
  };

  return output;
}

function prepareInfiniteBus({
  type,
  uuid,
  assetsValues,
  optionalMethod = optional,
}: TPrepareDevicePayload): TInfiniteBusInput {
  type T = TInfiniteBusInput;
  const values = assetsValues[uuid];

  const output: T = {
    type,
    uuid,
    name: values.name || '',
    ...optionalMethod<T>('allowExternalConnection', values),
    ...optionalMethod<T>('geoTagLocation', values),
    ...optionalMethod<T>('energyRate', values),
    ...optionalMethod<T>('energyBuyRate', values),
    libraryUUID: values.libraryUUID,
  };

  return output;
}

export const prepareDeviceInputFunctions: {
  [key in TAssetType]: (payload: TPrepareDevicePayload) => TDeviceTypesInput;
} = {
  Area: prepareArea,
  FiniteDieselGenerator: prepareFiniteDieselGenerator,
  Load: prepareLoad,
  PV: preparePV,
  Storage: prepareStorage,
  MarketMaker: prepareMarketMaker,
  InfiniteBus: prepareInfiniteBus,
  HeatPump: prepareHeatPump,
};
