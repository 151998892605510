import { $Keys, $PropertyType, $Values, DeepNonNullable, ValuesType } from 'utility-types';
import { ConfigType, SettingsDataFieldsFragment } from 'src/graphql';
import {
  EFormVariant,
  TAssetType,
  TFieldUnits,
  TFieldValue,
  TUploadedProfile,
} from 'src/typings/base-types';

import { GRID_MARKET_MODE } from 'src/constants/application';
import { TBaseCheckboxProps } from 'src/components/BaseCheckbox';
import { TBaseCounterProps } from 'src/components/BaseCounter';
import { TBaseDateRangePickerProps } from 'src/components/BaseDateRangePicker';
import { TBaseFileUploadProps } from 'src/components/BaseFileUpload';
import { TBaseSelectProps } from 'src/components/BaseSelect';
import { TBaseSliderProps } from 'src/components/BaseSlider';
import { TBaseSwitchProps } from 'src/components/BaseSwitch';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';
import { TFieldContainerProps } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { TLocationSearchValue } from 'src/components/LocationSearch';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { getAllFieldTemplatesWithValuesForAsset } from 'src/utils/assetsFields/fieldTemplatesWithValues';

export type TIsLibrary = boolean;

export type TAssetFieldTemplatesArgs = {
  type: TAssetType;
  settingsData: TSettingsData;
  configType?: ConfigType;
  values?: TValuesByFieldName;
  isLibrary: TIsLibrary;
  configurationCharacteristic: TConfigurationCharacteristic;
  includeAll?: boolean;
  forecastStreamEnabled?: boolean;
  isSCM?: boolean;
  isCustomLoad?: boolean;
  isCustomPV?: boolean;
};

export type TUpdateFields = {
  type: TAssetType;
  settingsData: TSettingsData;
  configType?: ConfigType;
  isLibrary: TIsLibrary;
  updatedField: { name: TAllFieldNames; value: TFieldValue };
  fields: ReturnType<typeof getAllFieldTemplatesWithValuesForAsset>;
  configurationCharacteristic: TConfigurationCharacteristic;
};

export type TupdateSiblingFieldsArgs = {
  type: TAssetType;
  settingsData: TSettingsData;
  configType?: ConfigType;
  isLibrary: TIsLibrary;
  fields: TUpdateFields['fields'];
  updatedFieldName: TAllFieldNames;
  newValue: TFieldValue;
  prevValue: TFieldValue;
  configurationCharacteristic: TConfigurationCharacteristic;
};

type TField = {
  name: TAllFieldNames;
  label?: string;
  formView: EFormVariant;
  disabled?: boolean;
  placeholder?: string;

  fullWidthErr?: boolean;
  EXCLUDE?: boolean;
} & Pick<TFieldContainerProps, 'tooltipText' | 'inlineAlign' | 'showTooltip'>;

/*
 * Text
 */
export type TTextFieldTemplate = {
  type: 'text' | 'textarea';
} & TField;

export type TTextFieldWithValue = {
  value: string;
} & TTextFieldTemplate;

/*
 * Textarea
 */
export type TTextareaFieldTemplate = {
  type: 'textarea';
} & TField;

export type TTextareaFieldWithValue = {
  value: string;
} & TTextareaFieldTemplate;

/*
 * Number
 */
export type TNumberFieldTemplate = {
  type: 'number';
  unit?: TFieldUnits;
} & TField;

export type TNumberFieldWithValue = {
  value: number;
} & TNumberFieldTemplate;

/*
 * Switcher
 */
export type TSwitcherFieldTemplate = {
  type: 'switcher';
  options: TBaseSwitchProps['options'];
} & TField;

export type TSwitcherFieldWithValue = {
  value: TBaseSwitchProps['value'];
} & TSwitcherFieldTemplate;

/*
 * Location
 */
export type TLocationFieldTemplate = {
  name: 'geoTagLocation';
  type: 'location';
} & TField;

export type TLocationFieldWithValue = {
  value: TLocationSearchValue['coords'];
} & TLocationFieldTemplate;

/*
 * Enum (Select)
 */
export type TEnumFieldTemplate = {
  type: 'enum';
  options: TBaseSelectProps['options'];
} & TField;

export type TEnumFieldWithValue = {
  value: TBaseSelectProps['value'];
} & TEnumFieldTemplate;

/*
 * Slider
 */
export type TSliderFieldTemplate = {
  type: 'slider';
  step: number;
  // labelStep: number;
  minVal: number;
  maxVal: number;
  unit?: TFieldUnits;
  showUnit?: boolean;
} & TField;

export type TSliderFieldWithValue = {
  value: TBaseSliderProps['value'];
} & TSliderFieldTemplate;

/*
 * File
 */
export type TFileFieldTemplate = {
  name:
    | 'powerProfile'
    | 'dailyLoadProfile'
    | 'energyRateProfile'
    | 'buyingRateProfile'
    | 'consumptionKwhProfile'
    | 'prosumptionKwhProfile'
    | 'externalTempCProfile';
  type: 'file';
  allowDownload?: boolean;
  unit?: TFieldUnits;
} & TField;

export type TFileFieldWithValue = {
  value: TBaseFileUploadProps['value'];
} & TFileFieldTemplate;

/*
 * Checkbox
 */
export type TCheckboxFieldTemplate = {
  type: 'checkbox';
} & TField;

export type TCheckboxFieldWithValue = {
  value: TBaseCheckboxProps['value'];
} & TCheckboxFieldTemplate;

/*
 * DateRange
 */
export type TDateRangeFieldTemplate = {
  type: 'dateRange';
  valueFormat: TBaseDateRangePickerProps['valueFormat'];
} & TField;

export type TDateRangeFieldWithValue = {
  value: {
    startDate: TBaseDateRangePickerProps['startValue'];
    endDate: TBaseDateRangePickerProps['endValue'];
  };
} & TDateRangeFieldTemplate;

/*
 * Counter
 */
export type TCounterFieldTemplate = {
  type: 'counter';
  min?: number;
  max?: number;
} & TField;

export type TCounterFieldWithValue = {
  value: TBaseCounterProps['value'];
} & TCounterFieldTemplate;

/*
 * UUID
 */
export type TUuidFieldTemplate = {
  name:
    | 'dailyLoadProfileUuid'
    | 'powerProfileUuid'
    | 'libraryUUID'
    | 'externalTempCProfileUuid'
    | 'consumptionKwhProfileUuid'
    | 'energyRateProfileUuid'
    | 'buyingRateProfileUuid';
  type?: undefined;
} & TField;

export type TFieldsTemplateUnion =
  | TSwitcherFieldTemplate
  | TTextFieldTemplate
  | TTextareaFieldTemplate
  | TNumberFieldTemplate
  | TLocationFieldTemplate
  | TEnumFieldTemplate
  | TSliderFieldTemplate
  | TFileFieldTemplate
  | TUuidFieldTemplate
  | TCheckboxFieldTemplate
  | TCounterFieldTemplate;

export type TFieldsUnionWithValue =
  | TSwitcherFieldWithValue
  | TTextFieldWithValue
  | TTextareaFieldWithValue
  | TNumberFieldWithValue
  | TLocationFieldWithValue
  | TEnumFieldWithValue
  | TSliderFieldWithValue
  | TFileFieldWithValue
  | TCheckboxFieldWithValue
  | TDateRangeFieldWithValue
  | TCounterFieldWithValue;

export type TCloudCoverageOptions = [
  {
    option: 'Local Generation Profile';
    value: 0;
  },
  {
    option: 'Upload Profile';
    value: 1;
  },
];

export type TCloudCoverageValues = $PropertyType<ValuesType<TCloudCoverageOptions>, 'value'>;

export enum ESCMGroupSettingsName {
  VERGY = 'VERGY',
  ENGIE_HEMERA = 'ENGIE_HEMERA',
  GERENTA = 'GERENTA',
  DEFAULT_STATIC = 'DEFAULT_STATIC',
  DEFAULT_DYNAMIC = 'DEFAULT_DYNAMIC',
  DEFAULT = '',
}

export type TSettingsData = {
  importCapacityKva?: number;
  exportCapacityKva?: number;
  coefficientPercentage?: number;
  baselinePeakEnergyImportKwh?: number;
  baselinePeakEnergyExportKwh?: number;
  scmCoefficientAlgorithm?: string;
  scmGroupSettingsName?: ESCMGroupSettingsName | string;
} & DeepNonNullable<Omit<SettingsDataFieldsFragment, '__typename'>>;

export type TCommunitySettingsFields = {
  name: string;
  description: string;
  startEndDate: TDateRangeFieldWithValue['value'];
  locationVisible: boolean;
  timezone?: string;
} & TSettingsData;

type TFieldNamesMapping = {
  name: 'name';
  libraryUUID: 'libraryUUID';
  allow_external_connection: 'allowExternalConnection';
};

export type TFieldNamesMappingArea = {
  __typename: 'Area';
  grid_fee_constant: 'gridFeeConstant';
  grid_fee_percentage: 'gridFeePercentage';
  fit_area_boundary: 'fitAreaBoundary';
  geo_tag_location: 'geoTagLocation';
  import_capacity_kVA: 'importCapacityKva';
  export_capacity_kVA: 'exportCapacityKva';
  market_maker_rate: 'marketMakerRate';
  feed_in_tariff: 'feedInTariff';
  coefficient_percentage: 'coefficientPercentage';
  fixed_monthly_fee: 'fixedMonthlyFee';
  taxes_surcharges: 'taxesSurcharges';
  marketplace_monthly_fee: 'marketplaceMonthlyFee';
  assistance_monthly_fee: 'assistanceMonthlyFee';
  baseline_peak_energy_import_kWh: 'baselinePeakEnergyImportKwh';
  baseline_peak_energy_export_kWh: 'baselinePeakEnergyExportKwh';
} & TFieldNamesMapping;

export type TFieldNamesMappingFiniteDieselGenerator = {
  __typename: 'FiniteDieselGenerator';
  energy_rate: 'energyRate';
  max_available_power_kW: 'maxAvailablePowerKw';
  geo_tag_location: 'geoTagLocation';
} & TFieldNamesMapping;

export type TFieldNamesMappingLoad = {
  __typename: 'Load';
  daily_load_profile: 'dailyLoadProfile';
  daily_load_profile_uuid: 'dailyLoadProfileUuid';
  avg_power_W: 'avgPowerW';
  //hrs_per_day: 'hrsPerDay';
  //hrs_of_day: 'hrsOfDay';
  initial_buying_rate: 'initialBuyingRate';
  use_market_maker_rate: 'useMarketMakerRate';
  final_buying_rate: 'finalBuyingRate';
  energy_rate_increase_per_update: 'energyRateIncreasePerUpdate';
  fit_to_limit: 'fitToLimit';
  update_interval: 'updateInterval';
  geo_tag_location: 'geoTagLocation';
  forecast_stream_enabled: 'forecastStreamEnabled';
} & TFieldNamesMapping;

export type TFieldNamesMappingPV = {
  __typename: 'PV';
  capacity_kW: 'capacityKw';
  cloud_coverage: 'cloudCoverage';
  power_profile: 'powerProfile';
  power_profile_uuid: 'powerProfileUuid';
  initial_selling_rate: 'initialSellingRate';
  final_selling_rate: 'finalSellingRate';
  energy_rate_decrease_per_update: 'energyRateDecreasePerUpdate';
  fit_to_limit: 'fitToLimit';
  update_interval: 'updateInterval';
  use_market_maker_rate: 'useMarketMakerRate';
  geo_tag_location: 'geoTagLocation';
  forecast_stream_enabled: 'forecastStreamEnabled';
  azimuth: 'azimuth';
  tilt: 'tilt';
} & TFieldNamesMapping;

export type TFieldNamesMappingHeatPump = {
  __typename: 'HeatPump';
  consumption_kWh_profile: 'consumptionKwhProfile';
  consumption_kWh_profile_uuid: 'consumptionKwhProfileUuid';
  external_temp_C_profile: 'externalTempCProfile';
  external_temp_C_profile_uuid: 'externalTempCProfileUuid';
  initial_buying_rate: 'initialBuyingRate';
  final_buying_rate: 'finalBuyingRate';
  initial_temp_C: 'initialTempC';
  max_temp_C: 'maxTempC';
  maximum_power_rating_kW: 'maximumPowerRatingKw';
  update_interval: 'updateInterval';
  min_temp_C: 'minTempC';
  geo_tag_location: 'geoTagLocation';
  preferred_buying_rate: 'preferredBuyingRate';
  source_type: 'sourceType';
  tank_volume_l: 'tankVolumeL';
  use_market_maker_rate: 'useMarketMakerRate';
} & TFieldNamesMapping;

export type TFieldNamesMappingStorage = {
  __typename: 'Storage';
  battery_capacity_kWh: 'batteryCapacityKwh';
  initial_soc: 'initialSoc';
  min_allowed_soc: 'minAllowedSoc';
  max_abs_battery_power_kW: 'maxAbsBatteryPowerKw';
  initial_selling_rate: 'initialSellingRate';
  final_selling_rate: 'finalSellingRate';
  initial_buying_rate: 'initialBuyingRate';
  final_buying_rate: 'finalBuyingRate';
  energy_rate_decrease_per_update: 'energyRateDecreasePerUpdate';
  energy_rate_increase_per_update: 'energyRateIncreasePerUpdate';
  fit_to_limit: 'fitToLimit';
  update_interval: 'updateInterval';
  cap_price_strategy: 'capPriceStrategy';
  geo_tag_location: 'geoTagLocation';
  forecast_stream_enabled: 'forecastStreamEnabled';
} & TFieldNamesMapping;

export type TFieldNamesMappingMarketMaker = {
  __typename: 'MarketMaker';
  grid_connected: 'gridConnected';
  energy_rate: 'energyRate';
  energy_rate_profile: 'energyRateProfile';
  energy_rate_profile_uuid: 'energyRateProfileUuid';
  energy_buy_rate: 'energyBuyRate';
  buying_rate_profile: 'buyingRateProfile';
  buying_rate_profile_uuid: 'buyingRateProfileUuid';
} & TFieldNamesMapping;

export type TFieldNamesMappingUnion =
  | TFieldNamesMappingArea
  | TFieldNamesMappingFiniteDieselGenerator
  | TFieldNamesMappingLoad
  | TFieldNamesMappingPV
  | TFieldNamesMappingHeatPump
  | TFieldNamesMappingStorage
  | TFieldNamesMappingMarketMaker;

// FE = FrontEnd
export type TFieldNamesFE =
  | $Values<TFieldNamesMappingArea>
  | $Values<TFieldNamesMappingFiniteDieselGenerator>
  | $Values<TFieldNamesMappingLoad>
  | $Values<TFieldNamesMappingPV>
  | $Values<TFieldNamesMappingHeatPump>
  | $Values<TFieldNamesMappingStorage>
  | $Values<TFieldNamesMappingMarketMaker>
  | 'count'
  | 'gridFeeEnabled'
  | 'allowExternalConnection'
  | 'powerProfile';

// BE = BackEnd
export type TFieldNamesBE =
  | $Keys<TFieldNamesMappingArea>
  | $Keys<TFieldNamesMappingFiniteDieselGenerator>
  | $Keys<TFieldNamesMappingLoad>
  | $Keys<TFieldNamesMappingPV>
  | $Keys<TFieldNamesMappingStorage>
  | $Keys<TFieldNamesMappingHeatPump>
  | $Keys<TFieldNamesMappingMarketMaker>;

export type TAllFieldNames = $Keys<TValuesByFieldName>;

export type TReactionPayload = {
  type: TAssetType;
  fields: ReturnType<typeof getAllFieldTemplatesWithValuesForAsset>;
  settingsData: TSettingsData;
  newValue: TFieldValue;
  prevValue: TFieldValue;
  isLibrary: TIsLibrary;
  configType?: ConfigType;
  configurationCharacteristic: TConfigurationCharacteristic;
};

export type TReactionOutput = TReactionPayload['fields'];

export type TSingleReaction = (arg: TReactionPayload) => TReactionOutput;

export type TReactionByFieldName = {
  [key in TAllFieldNames]?: TSingleReaction;
};

export type TValuesByFieldNameCopy = TValuesByFieldName;
