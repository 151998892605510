import { EFormVariant, EGridMarketTab, TFieldValue } from 'src/typings/base-types';
import {
  FormAssetsParams,
  TAssetsSaveProps,
  TFormAssetsParamsProps,
} from 'src/components/FormAssetsParams';
import React, { useCallback, useRef, useState } from 'react';
import {
  TFieldsUnionWithValue,
  TTextFieldWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import { TValidationErrors, rules, validateFields } from 'src/utils/validation';
import {
  selectAssetsValues,
  selectGridMarketAsset,
  selectRootAssetUuid,
  selectSelectedAssetUuid,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';

import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { GridMarketFormNameInput } from 'src/components/ModalAssetsManager/GridMarketAndAgent/GridMarketFormNameInput';
import { GridMarketModalHeader } from 'src/components/ModalAssetsManager/GridMarketAndAgent/GridMarketModalHeader';
import { GridMarketSubmitButton } from 'src/components/ModalAssetsManager/GridMarketAndAgent/GridMarketSubmitButton';
import { GridMarketTabs } from 'src/components/ModalAssetsManager/GridMarketAndAgent/GridMarketTabs';
import { TAsset } from 'src/typings/configuration.types';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import _ from 'lodash';
import classnames from 'classnames';
import s from '../ModalAssetsManager.module.scss';
import { selectActiveGridMarketForm } from 'src/redux/application/application.selectors';
import { setModalAssetManagerActiveView } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

type GridMarketFormProps = {
  hostAssetUuid: TAsset['uuid'];
  handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'];
};

export const GridMarketForm: React.FC<GridMarketFormProps> = ({
  hostAssetUuid,
  handleAssetValuesSavePromise,
}) => {
  const dispatch = useAppDispatch();

  const formId = useRef(v4());
  const formHasErrorsRef = useRef(false);

  const formVariant = useSelector(selectActiveGridMarketForm);
  const assetsValues = useSelector(selectAssetsValues);
  const usedAssetsNames = useSelector(selectUsedAssetsNames);
  const gridMarketKey = useSelector(selectRootAssetUuid) as string;

  const gridAgentAsset = useSelector(selectGridMarketAsset);
  const editingAssetUuid = useSelector(selectSelectedAssetUuid) || hostAssetUuid;

  /*const [editingAssetUuid, setEditingAssetUuid] = useState<GridMarketFormProps['hostAssetUuid']>(
    formVariant === EFormVariant.GridAgent ? hostAssetUuid : gridMarketKey,
  );*/

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [inputName, setInputName] = useState<string>(assetsValues[hostAssetUuid]?.name ?? '');
  const [errors, setErrors] = useState<null | TValidationErrors>(null);

  const formTitle = 'Market Settings';

  const generateInputNameField: (value: TFieldValue) => TTextFieldWithValue = useCallback(
    (value) => ({
      value: value as string,
      type: 'text',
      name: 'name',
      formView: formVariant,
    }),
    [formVariant],
  );

  const handleNameValidation = (inputNameField: Array<TFieldsUnionWithValue>) =>
    validateFields({
      validators: {
        name: [
          ...((n) => [
            rules.required(n),
            rules.noForwardSlash(n),
            rules.isNameUnique(
              usedAssetsNames.filter((item) => item != assetsValues[editingAssetUuid].name),
              inputName,
            ),
          ])('Name'),
        ],
      },
      fields: inputNameField,
    });

  const handleNameBlur: TFormFieldsGeneratorProps['onBlur'] = () => {
    const { errors } = handleNameValidation([generateInputNameField(inputName)]);
    setErrors(errors);
  };

  const handleNameChange: TFormFieldsGeneratorProps['onChange'] = ({ value }) => {
    setInputName(value as string);
    const { errors } = handleNameValidation([generateInputNameField(value)]);
    setErrors(errors);
  };

  const gridMarketFormSave = ({ values }: TAssetsSaveProps) => {
    const sendData =
      formVariant === EFormVariant.GridMarket
        ? {
            name: 'Grid Market',
            gridFeeConstant: values.gridFeeConstant,
          }
        : _.omit(values, ['name']);

    if (!handleAssetValuesSavePromise || !gridAgentAsset?.uuid) return;

    if (formVariant === EFormVariant.GridMarket)
      handleAssetValuesSavePromise({
        assetUuid: gridMarketKey,
        values: sendData,
        assetType: 'MarketMaker',
      });

    if (formVariant === EFormVariant.GridAgent)
      handleAssetValuesSavePromise({
        assetUuid: gridAgentAsset?.uuid,
        values: sendData,
        assetType: 'InfiniteBus',
      });
  };

  if (collapsed)
    return (
      <div
        className={s.saveOrSettings}
        onClick={() => {
          setCollapsed(false);
        }}>
        {formTitle}
      </div>
    );

  return (
    <>
      <GridMarketModalHeader
        formTitle={formTitle}
        onClose={() => {
          dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddHouse));
          setCollapsed(true);
        }}
      />
      <GridMarketFormNameInput
        inputValue={inputName}
        onChange={handleNameChange}
        onBlur={handleNameBlur}
        error={errors?.name}
      />
      <GridMarketTabs
        assetKeyWithTabs={[
          { tabName: EGridMarketTab.Agent, key: gridAgentAsset?.uuid as string },
          { tabName: EGridMarketTab.Market, key: gridMarketKey },
        ]}
        gridAgentAssetUuid={gridAgentAsset?.uuid as string}
        gridMarketAssetUuid={gridMarketKey}
      />
      <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
        <FormAssetsParams
          hasErrorsRef={formHasErrorsRef}
          id={formId.current}
          assetUuid={editingAssetUuid}
          formVariant={formVariant}
          onSubmit={gridMarketFormSave}
          assetType={'InfiniteBus'}
          currentValues={{
            ...assetsValues[gridAgentAsset?.uuid as string],
            ...assetsValues[gridMarketKey],
          }}
          disableLocationField={false}
          isCustomPV={false}
          isEdit={true}
        />
      </CustomScrollbar>
      <GridMarketSubmitButton formId={formId}>Save</GridMarketSubmitButton>
    </>
  );
};
