import {
  TAreaSummaryResult,
  TCanaryAssetData,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary';

import { useAreaSummary } from 'src/hooks/useAreaSummary';
import { useMemo } from 'react';

export type TUseAssetsSummaryResponse = {
  createAssetsData: (payload?: TAreaSummaryResult | undefined) => void;
  assetsData: Array<TCanaryAssetData>;
};

export function useAssetsData(): TUseAssetsSummaryResponse {
  const { areaSummary } = useAreaSummary();

  const createAssetsData: (payload?: TAreaSummaryResult | undefined) => Array<TCanaryAssetData> = (
    areaSummary,
  ) => {
    const result: TCanaryAssetData[] = [];
    const createAsset = (item: TAreaSummaryResult): TCanaryAssetData => ({
      uuid: item.uuid,
      name: item.name === 'Grid' ? 'Grid Market' : item.name,
      type: item.type,
      icon: 'house',
    });
    const parseAssetResult = (item: TAreaSummaryResult) => {
      if (item.type !== 'InfiniteBus') {
        result.push(createAsset(item));
      }

      if (item.children) {
        item.children.forEach(parseAssetResult);
      }
    };

    if (areaSummary) [areaSummary].forEach(parseAssetResult);

    return result;
  };

  const assetsData = useMemo(() => createAssetsData(areaSummary), [areaSummary]);

  return {
    createAssetsData,
    assetsData,
  };
}
