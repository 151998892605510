import React, { useMemo } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TStorageInfoProps } from './StorageInfo.types';
import classnames from 'classnames';
import { devide } from 'src/utils/mathUtils';
import { formatter } from 'src/utils/formatter';
import s from './StorageInfo.module.scss';

export const StorageInfo: React.FC<TStorageInfoProps> = ({ priceEnergyAreaBalance, currency }) => {
  const currencySymbol = formatter.getCurrencySymbol(currency);

  const { dataSet, traded } = useMemo(() => {
    return {
      traded: formatter.toOptionalFixed(
        priceEnergyAreaBalance.bought - priceEnergyAreaBalance.sold,
        2,
      ),
      dataSet: [
        {
          icon: 'energy-bought' as TIconNames,
          text: 'Energy bought',
          num: priceEnergyAreaBalance.bought,
          unit: 'kWh',
        },
        {
          icon: 'usd-minus' as TIconNames,
          className: s.icon,
          text: 'Avg. buying price',
          num: devide(priceEnergyAreaBalance.spent, priceEnergyAreaBalance.bought),
          unit: `${currencySymbol}/kWh`,
        },
        {
          icon: 'energy-sold' as TIconNames,
          text: 'Energy sold',
          num: priceEnergyAreaBalance.sold,
          unit: 'kWh',
        },
        {
          icon: 'usd-plus' as TIconNames,
          className: s.icon,
          text: 'Avg. selling price',
          num: devide(priceEnergyAreaBalance.earned, priceEnergyAreaBalance.sold),
          unit: `${currencySymbol}/kWh`,
        },
      ],
    };
  }, [currencySymbol, priceEnergyAreaBalance]);

  return (
    <>
      <div className={s.netEnergy}>
        <BaseIcon icon="net-energy" size={26} />
        <span className={s.text}>Net energy traded</span>
        <span
          className={classnames(s.tag, s.lastItem, {
            [s.red]: traded < 0,
          })}>
          {traded} kWh charged
        </span>
      </div>
      <div className={s.dataSet}>
        {dataSet.map((item, index) => (
          <div
            className={classnames(s.netEnergy, {
              [s.marginTop]: index != 0 && index !== 1,
            })}
            key={index}>
            <BaseIcon icon={item.icon} size={26} className={item?.className} />
            <span className={s.text}>{item.text}</span>
            <span className={classnames(s.lastItem)}>
              {formatter.toOptionalFixed(item.num, 2)}
              <br />
              {item.unit}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};
