import { ChartEnergyProfile, TChartProfileInfo } from 'src/components/ChartEnergyProfile';
import { DataAggregationResolution, Maybe } from 'src/graphql';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  TResolutionButtonState,
  TSCMSimulationGraphData,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';

import { BaseSelect } from 'src/components/BaseSelect';
import { ChartEnergyProfileBrush } from 'src/components/ChartEnergyProfile/ChartEnergyProfileBrush';
import { EScreenMode } from 'src/typings/configuration.types';
import { EnergyBadge } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadge';
import { EnergyBageDot } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadgeDot';
import { InfoHelper } from 'src/components/InfoHelper';
import { MobileLineChartWrapper } from 'src/components/ChartEnergyProfile/MobileLineChartWrapper';
import { RegularNoResults } from 'src/components/MapSidebarSCM/components/MapSidebarResults/components/RegularNoResults';
import { TEnergyProfileComponentProps } from 'src/components/EnergyProfileComponent/EnergyProfileComponent.types';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './EnergyProfileComponent.module.scss';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useGraphBrush } from 'src/hooks/useGraphBrush';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export const EnergyProfileComponent: React.FC<TEnergyProfileComponentProps> = ({
  title,
  info,
  mode,
  field,
  initialData,
}) => {
  const {
    queries: { getAccumulatedResultsDaily, getAccumulatedResultsMonthly },
    resultsDataDaily,
    resultsDataMonthly,
  } = useAccumulatedResults({
    initialRunQueries: [],
    mode: field,
  });
  //console.log('initialData', initialData);

  const { t } = useTranslation();

  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const screenMode = useSelector(selectScreenMode);
  const [localMode, setLocalMode] = useState<TResolutionButtonState | null>(null);
  const localModeRef = useRef<TResolutionButtonState | null>(null);
  const modePropRef = useRef<TResolutionButtonState | null>(null);

  useEffect(() => {
    if (modePropRef.current === mode) return;
    modePropRef.current = mode;
    setLocalMode(mode);
  }, [mode]);
  // create localCommunityKpi state
  const [localCommunityKpi, setLocalCommunityKpi] = useState<
    Maybe<TSCMSimulationGraphData> | undefined
  >(initialData);

  const { data, graphIndexes, setDebouncedGraphIndexes } = useGraphBrush<TChartProfileInfo>({
    initialData: localCommunityKpi?.length ? localCommunityKpi : [],
  });
  const [graphKey, setGraphKey] = useState<string>(v4());

  const checkStatement = useMemo(() => !data || data.every((d) => !d || !d.time), [data]);

  useEffect(() => {
    if (localModeRef.current === localMode || isOperationCommunity) return;
    localModeRef.current = localMode;
    if (localMode === DataAggregationResolution.Daily) {
      getAccumulatedResultsDaily();
    } else {
      getAccumulatedResultsMonthly();
    }
  }, [localMode, isOperationCommunity, getAccumulatedResultsDaily, getAccumulatedResultsMonthly]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    const data =
      localMode === DataAggregationResolution.Daily ? resultsDataDaily : resultsDataMonthly;

    if (data?.kpi && data?.billsEnergy) {
      setLocalCommunityKpi(
        pickKpiData({ kpi: JSON.parse(data?.kpi), billsEnergy: JSON.parse(data?.billsEnergy) }),
      );
    }
  }, [resultsDataDaily, resultsDataMonthly, localMode]);

  useEffect(() => setGraphKey(v4()), [sidebarExpanded, initialData, localMode, localCommunityKpi]);

  useEffect(() => {
    setLocalCommunityKpi(initialData);
  }, [initialData]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3>{title}</h3>
        <InfoHelper info={info} position="bottom" />
      </div>
      {checkStatement && (
        <div className={s.container}>
          <div style={{ height: 300 }}>
            <RegularNoResults communityNotFound={false} />
          </div>
        </div>
      )}
      {!checkStatement && (
        <div className={s.container}>
          {screenMode === EScreenMode.Mobile ? (
            <MobileLineChartWrapper>
              <ChartEnergyProfile data={data} mode={mode} />
            </MobileLineChartWrapper>
          ) : (
            <div className={s.chartArea} style={{ height: 300 }}>
              <ChartEnergyProfile data={data} mode={mode} />
            </div>
          )}
          <div className={s.brushArea}>
            <div className={s.selectWrapper}>
              <BaseSelect
                name="selectedDay"
                value={localMode}
                options={[
                  {
                    label: t('DAILY'),
                    value: DataAggregationResolution.Daily,
                  },
                  {
                    label: t('MONTHLY'),
                    value: DataAggregationResolution.Monthly,
                  },
                ]}
                onChange={({ value }) => {
                  setLocalMode(value as TResolutionButtonState);
                }}
                theme="filled-gray"
                className={s.select}
              />
            </div>
            <div className={s.brushWrapper}>
              {localCommunityKpi && (
                <ChartEnergyProfileBrush
                  key={`${graphKey}-brush`}
                  data={localCommunityKpi}
                  setGraphIndexes={(val) => {
                    setDebouncedGraphIndexes(val);
                  }}
                  startIndex={graphIndexes.startIndex}
                  endIndex={graphIndexes.endIndex}
                />
              )}
            </div>
          </div>
          <div className={s.badgeRow}>
            <EnergyBadge
              title={t('GENERATION')}
              size="M"
              icon={<EnergyBageDot backgroundClassname={s.bgGreen} />}
            />
            <EnergyBadge
              title={t('CONSUMPTION')}
              size="M"
              icon={<EnergyBageDot backgroundClassname={s.bgRed} />}
            />
            <EnergyBadge
              title={t('INJECTION')}
              size="M"
              icon={<EnergyBageDot backgroundClassname={s.bgBlue} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};
