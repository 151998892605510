import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';

import { CANARY_PROPERTIES_FIELDS } from 'src/utils/assetsFields/fieldTemplates';
import { CLOUD_COVERAGE_OPTIONS } from 'src/constants/application';
import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';

export type TPVSCMArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
  configurationCharacteristic: TConfigurationCharacteristic;
  settingsData: TSettingsData;
  configType: ConfigType | undefined;
};

export const PVSCM = ({ isLibrary, configType }: TPVSCMArgs): TFieldsTemplateUnion[] => {
  const evaluatedCanaryNetworkFields = CANARY_PROPERTIES_FIELDS({
    configType,
  });

  return [
    // ...evaluatedDeviceKpisFields,
    {
      name: 'name',
      type: 'text',
      label: 'Asset Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    // {
    //   name: 'count',
    //   type: 'counter',
    //   label: 'Multiply same unit',
    //   min: 1,
    //   formView: EFormVariant.Express,
    // },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'Capacity',
      formView: EFormVariant.Advanced,
      unit: 'kWp',
    },
    {
      name: 'cloudCoverage',
      type: 'enum',
      label: 'Profile',
      options: CLOUD_COVERAGE_OPTIONS,
      formView: EFormVariant.Advanced,
      showTooltip: true,
      tooltipText: 'To get correct results, please make sure that all entries in the date column match the selected market time slots'
    },
    {
      name: 'powerProfile',
      type: 'file',
      label: 'Override Profile',
      formView: EFormVariant.Advanced,
    },
    ...evaluatedCanaryNetworkFields,
    {
      name: 'powerProfileUuid',
      formView: EFormVariant.Express,
    },
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
  ];
};
