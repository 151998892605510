import gql from 'graphql-tag';

export const ScmAccumulatedBillsDifferences = gql`
  subscription scmAccumulatedBillsDifferences(
    $jobId: String!
    $areaUUID: String!
    $startTime: DateTime!
  ) {
    scmAccumulatedBillsDifferences(jobId: $jobId, areaUUID: $areaUUID, startTime: $startTime)
  }
`;
