import { useEffect, useRef } from 'react';

import { ApolloClient } from '@apollo/client';

export type TApolloSubscriptionOptions<T> = {
  onSubscriptionData?: (data: T) => void;
  onError?: (error: Error) => void;
  onSubscriptionComplete?: () => void;
};

export type TUseApolloSubscriptionResult = {
  subscribe: (options?: Partial<Parameters<typeof ApolloClient.prototype.subscribe>[0]>) => void;
  unsubscribe: () => void;
};

export const useApolloSubscription = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  subscriptionOptions: Parameters<typeof ApolloClient.prototype.subscribe>[0],
  options: TApolloSubscriptionOptions<T>,
): TUseApolloSubscriptionResult => {
  const { onSubscriptionData, onError, onSubscriptionComplete } = options;
  const subscriptionRef = useRef<ZenObservable.Subscription | null>(null);

  useEffect(() => {
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  const subscribe = (options?: Partial<Parameters<typeof ApolloClient.prototype.subscribe>[0]>) => {
    if (!subscriptionRef.current) {
      subscriptionRef.current = client
        .subscribe({
          ...subscriptionOptions,
          ...options,
        })
        .subscribe({
          next: (data) => {
            if (onSubscriptionData) {
              onSubscriptionData(data.data as T);
            }
          },
          error: (error) => {
            if (onError) {
              onError(error);
            }
          },
          complete: () => {
            if (onSubscriptionComplete) {
              onSubscriptionComplete();
            }
          },
        });
    }
  };

  const unsubscribe = () => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    }
  };

  return { subscribe, unsubscribe };
};
