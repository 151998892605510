import { TAllFieldNames, TUpdateFields } from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldValuesByName,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';
import { TValidationObject, TValidators, rules } from 'src/utils/validation';
import { findField, isSingleLibraryDevice } from 'src/utils/fieldUtils';

import { MAX_ENERGY_RATE_CHANGE_PER_UPDATE } from 'src/constants/application';
import { TAssetType } from 'src/typings/base-types';
import { TUsedNames } from 'src/typings/configuration.types';

/*
    Validation rules for all types of nodes.
    If given field is absent then it's always valid.
    Validation rules are objects with the following structure:
    {
      r: - validation function
      m: - error message
    }

    Validation function accepts an object argument with the following structure:
    {
      newValue: - any type, value of checked field,
      fields: - array of objects, all fields,
      settingsData: - object, relevant global settings,
      isLibrary: - boolean type, whether the validation is occurring within a library configuration or not
    }
    It should return true when given value is correct and false otherwise.

    Rules are processed in order in which they are declared and error message is set to the one
    corresponding to the first rule that failed (or to null if none of rules failed).

    Swappable fields can be marked as required becasue they are absent
    when they are not displayed (i.e. doesn't have null values).
  */
type TPayload = Pick<
  TUpdateFields,
  'settingsData' | 'fields' | 'isLibrary' | 'configurationCharacteristic'
> & {
  usedAssetsNames: TUsedNames;
  currentValues?: TValuesByFieldName;
};

const mmAndIbValidators: (payload: TPayload) => TValidators<TAllFieldNames> = ({
  fields,
  usedAssetsNames,
  currentValues,
}) => ({
  name: [
    ...((n) => [
      rules.required(n),
      rules.noForwardSlash(n),
      rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || '' || ''),
    ])('Name'),
  ],
  energyRate: [
    ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])('Market maker rate'),
  ],
  energyRateProfile: [...((n) => [rules.required(n)])('Profile')],
  energyBuyRate: [
    ...((n) => [
      rules.required(n),
      rules.float(n),
      rules.range(n, 0, 10000),
      {
        r: ({ newValue }) => {
          const energyRateField = findField(fields, 'energyRate');
          const energyRateProfileField = findField(fields, 'energyRateProfile');
          if (
            !energyRateField ||
            energyRateProfileField ||
            !newValue ||
            typeof energyRateField.value !== 'number'
          ) {
            return true;
          }
          return newValue <= energyRateField.value;
        },
        m: `${n} has to be lower than or equal to selling rate.`,
      } as TValidationObject<TFieldValuesByName<'InfiniteBus'>['energyBuyRate']>,
    ])('Buying rate'),
  ],
  buyingRateProfile: [...((n) => [rules.required(n)])('Profile')],
});

export const validatorsByAssetType: {
  [assetType in TAssetType]: (payload: TPayload) => TValidators<TAllFieldNames>;
} = {
  /* Area */
  Area: ({ usedAssetsNames, currentValues }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    timezone: [...((n) => [rules.required(n)])('Value')],
    gridFeePercentage: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 100)])('Value'),
    ],
    gridFeeConstant: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 100)])('Value'),
    ],
    importCapacityKva: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 2147483647)])('Value'),
    ],
    exportCapacityKva: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 2147483647)])('Value'),
    ],
    coefficientPercentage: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 1)])('Value'),
    ],
    baselinePeakEnergyImportKwh: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 2147483647)])('Value'),
    ],
    baselinePeakEnergyExportKwh: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 2147483647)])('Value'),
    ],
    geoTagLocation: [...((n) => [rules.isGeoTagSet(n)])('Location')],
  }),

  /* PV */
  PV: ({ fields, isLibrary, usedAssetsNames, currentValues, configurationCharacteristic }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    capacityKw: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 1, 100000000)])('Capacity'),
    ],
    cloudCoverage: [rules.required('Solar profile')],
    powerProfile: [...((n) => [rules.required(n)])('Profile')],
    finalSellingRate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, 10000),
        {
          r: ({ newValue }) => {
            if (isSingleLibraryDevice({ isLibrary, configurationCharacteristic })) {
              return true;
            }

            if (typeof newValue !== 'number') return true;

            const initialSellingRateField = findField(fields, 'initialSellingRate');

            if (!initialSellingRateField) {
              if (configurationCharacteristic.gridMakerHasUploadedProfile) {
                return true;
              }
              if (configurationCharacteristic.marketMakerRate) {
                return newValue <= configurationCharacteristic.marketMakerRate;
              }

              return true;
            } else if (initialSellingRateField.value) {
              return newValue <= initialSellingRateField.value;
            }

            return true;
          },
          m: `${n} has to be lower than or equal to initial selling rate.`,
        } as TValidationObject<TFieldValuesByName<'PV'>['energyRateDecreasePerUpdate']>,
      ])('Final selling rate'),
    ],
    energyRateDecreasePerUpdate: configurationCharacteristic.gridMakerHasUploadedProfile
      ? []
      : [
          ...((n) => [
            rules.required(n),
            rules.float(n),
            rules.range(n, 0, MAX_ENERGY_RATE_CHANGE_PER_UPDATE),
            {
              r: ({ newValue }) => {
                if (isLibrary) {
                  const fitToLimit = fields.find((field) => field.name === 'fitToLimit');
                  if (fitToLimit && !fitToLimit.value && !(newValue || newValue === 0)) {
                    return false;
                  }
                }
                return true;
              },
              m: `${n} must be between 0 and ${MAX_ENERGY_RATE_CHANGE_PER_UPDATE}.`,
            } as TValidationObject<TFieldValuesByName<'PV'>['energyRateDecreasePerUpdate']>,
          ])('Energy rate decrease per update'),
        ],
    updateInterval: [
      ...((n) => [rules.required(n), rules.integer(n), rules.range(n, 1, 60)])('Update interval'),
    ],
    initialSellingRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])(
        'Initial selling rate',
      ),
    ],
  }),

  /* Storage */
  Storage: ({ fields, isLibrary, usedAssetsNames, currentValues }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    batteryCapacityKwh: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 200000)])(
        'Battery capacity',
      ),
    ],
    initialSoc: [
      ...((n) => [
        rules.required(n),
        rules.integer(n),
        {
          r: ({ newValue }) => {
            const field = findField(fields, 'minAllowedSoc');
            if (!field || !field.value || !newValue) return true;
            return newValue >= field.value && newValue <= 100;
          },
          m: `${n} has to be larger than minimum state of charge and lower than 100.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['initialSoc']>,
      ])('Initial state of charge'),
    ],
    minAllowedSoc: [
      ...((n) => [rules.required(n), rules.integer(n), rules.range(n, 0, 99)])(
        'Minimum state of charge',
      ),
    ],
    maxAbsBatteryPowerKw: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 200000)])(
        'Max power rating for battery',
      ),
    ],
    initialSellingRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])(
        'Initial selling rate',
      ),
    ],
    finalSellingRate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, 10000),
        {
          r: ({ newValue }) => {
            const field = findField(fields, 'initialSellingRate');
            if (!field || !newValue || !field.value) return true;
            return newValue <= field.value;
          },
          m: `${n} has to be lower than or equal to initial selling rate.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['finalSellingRate']>,
      ])('Final selling rate'),
    ],
    energyRateDecreasePerUpdate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, MAX_ENERGY_RATE_CHANGE_PER_UPDATE),
        {
          r: ({ newValue }) => {
            if (isLibrary) {
              const fitToLimit = fields.find((field) => field.name === 'fitToLimit');
              if (fitToLimit && !fitToLimit.value && !(newValue || newValue === 0)) {
                return false;
              }
            }
            return true;
          },
          m: `${n} must be between 0 and ${MAX_ENERGY_RATE_CHANGE_PER_UPDATE}.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['energyRateDecreasePerUpdate']>,
      ])('Energy rate decrease per update'),
    ],
    initialBuyingRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])(
        'Initial buying rate',
      ),
    ],
    finalBuyingRate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, 10000),
        {
          r: ({ newValue }) => {
            const field = findField(fields, 'initialBuyingRate');
            if (!field || !newValue || !field.value) return true;
            return newValue >= field.value;
          },
          m: `${n} has to be more than or equal to initial buying rate.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['finalBuyingRate']>,
        {
          r: ({ newValue }) => {
            const field = findField(fields, 'finalSellingRate');
            if (!field || !newValue || !field.value) return true;
            return newValue < field.value;
          },
          m: `${n} has to be lower than final selling rate.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['finalBuyingRate']>,
      ])('Final Buying Rate'),
    ],
    energyRateIncreasePerUpdate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, MAX_ENERGY_RATE_CHANGE_PER_UPDATE),
        {
          r: ({ newValue }) => {
            if (isLibrary) {
              const fitToLimit = fields.find((field) => field.name === 'fitToLimit');
              if (fitToLimit && !fitToLimit.value && !(newValue || newValue === 0)) {
                return false;
              }
            }
            return true;
          },
          m: `${n} must be between 0 and ${MAX_ENERGY_RATE_CHANGE_PER_UPDATE}.`,
        } as TValidationObject<TFieldValuesByName<'Storage'>['energyRateIncreasePerUpdate']>,
      ])('Energy rate increase per update'),
    ],
    updateInterval: [
      ...((n) => [rules.required(n), rules.integer(n), rules.range(n, 1, 60)])('Update interval'),
    ],
  }),

  /* HeatPump*/
  HeatPump: ({ usedAssetsNames, currentValues }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    tankVolumeL: [...((n) => [rules.required(n)])('tankVolumeL')],
    sourceType: [...((n) => [rules.required(n)])('sourceType')],
    // externalTempCProfile: [...((n) => [rules.maxFileSize(n, 5)])('File')],
    initialBuyingRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])(
        'Initial buying rate',
      ),
    ],
    updateInterval: [
      ...((n) => [rules.required(n), rules.integer(n), rules.range(n, 1, 60)])('Update interval'),
    ],
    finalBuyingRate: [...((n) => [rules.required(n), rules.float(n)])('Final Buying Rate')],
  }),

  /* Load */
  Load: ({ fields, isLibrary, usedAssetsNames, currentValues, configurationCharacteristic }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    avgPowerW: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000000000)])(
        'Average power',
      ),
    ],
    dailyLoadProfile: [...((n) => [rules.required(n)])('Profile')],
    /*hrsPerDay: [
      {
        r: ({ newValue }) => {
          const field = findField(fields, 'hrsOfDay');
          if (!field) return true;
          const value = field.value as TFieldValuesByName<'Load'>['hrsOfDay'];
          if (!newValue || !value || !field.value) return true;
          return newValue <= value[1] - value[0] + 1;
        },
        m: 'Must be lower than or equal to hours of day range length.',
      } as TValidationObject<TFieldValuesByName<'Load'>['hrsPerDay']>,
    ],*/
    initialBuyingRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])(
        'Initial buying rate',
      ),
    ],
    energyRateIncreasePerUpdate: configurationCharacteristic.gridMakerHasUploadedProfile
      ? []
      : [
          ...((n) => [
            rules.required(n),
            rules.float(n),
            rules.range(n, 0, MAX_ENERGY_RATE_CHANGE_PER_UPDATE),
            {
              r: ({ newValue }) => {
                if (isLibrary) {
                  const fitToLimit = fields.find((field) => field.name === 'fitToLimit');
                  if (fitToLimit && !fitToLimit.value && !(newValue || newValue === 0)) {
                    return false;
                  }
                }
                return true;
              },
              m: `${n} must be between 0 and ${MAX_ENERGY_RATE_CHANGE_PER_UPDATE}.`,
            } as TValidationObject<TFieldValuesByName<'Load'>['energyRateIncreasePerUpdate']>,
          ])('Energy rate increase per update'),
        ],
    updateInterval: [
      ...((n) => [rules.required(n), rules.integer(n), rules.range(n, 1, 60)])('Update interval'),
    ],
    finalBuyingRate: [...((n) => [rules.required(n), rules.float(n)])('Final Buying Rate')],
  }),

  /* Finite Diesel Generator (Power Plant) */
  FiniteDieselGenerator: ({ usedAssetsNames, currentValues }) => ({
    name: [
      ...((n) => [
        rules.required(n),
        rules.noForwardSlash(n),
        rules.isNameUnique(usedAssetsNames, (currentValues || {}).name || ''),
      ])('Name'),
    ],
    energyRate: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000)])('Energy rate'),
    ],
    maxAvailablePowerKw: [
      ...((n) => [rules.required(n), rules.float(n), rules.range(n, 0, 10000000)])(
        'Max available power',
      ),
    ],
  }),

  /* MarketMaker */
  MarketMaker: mmAndIbValidators,

  /* InfiniteBus */
  InfiniteBus: mmAndIbValidators,
};
