import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AssetsCard } from './AssetsCard';
import { AssetsViewUserTile } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView/AssetsViewUserTile';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { TAssetsViewProps } from './AssetsView.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import s from './AssetsView.module.scss';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSelector } from 'react-redux';

export const AssetsView: React.FC<TAssetsViewProps> = ({
  assetsList,
  onAssetClick,
  onAddAssetClick,
  selectedMember,
  //onChangeNewUser,
  clickOnAllMembers,
  toggleAssetView,
}) => {
  const { mapService } = useContext(WorldMapContext);

  const { children, data } = assetsList;
  const { isNewUser } = selectedMember || {};
  const dispatch = useAppDispatch();
  const scmMembers = useSelector(selectSCMHomeDetails);
  const { isUserACommunityMember, isAdmin } = useIsUserACommunityMember();

  const readOnly = useSelector(selectReadOnly);

  const [isNewUserSaved, setIsNewUserSaved] = useState(false);
  const isAppLoading = useSelector(selectIsAppLoading);

  const currentItem = useMemo(
    () =>
      isNewUser && selectedMember
        ? { ...selectedMember, email: selectedMember.uuid, address: undefined, zipCode: undefined }
        : scmMembers.find((item) => item.name === data?.name),
    [data?.name, scmMembers, isNewUser, selectedMember],
  );

  const showAllMembersButton = !isNewUser || isNewUserSaved;

  useEffect(() => {
    if (isUserACommunityMember && mapService) {
      let isBusy = true;
      const customListener = setInterval(() => {
        isBusy =
          mapService.map.isEasing() || mapService.map.isMoving() || mapService.map.isRotating();
        if (isBusy === false) {
          clearInterval(customListener);
          mapService.set3DView(true);
        }
      }, 1000);
    }
  }, [isUserACommunityMember, mapService]);

  //if (!children || !data) {
  //  return null;
  //}

  const handleAssetClick = (data) => {
    onAssetClick?.(assetsList);
    dispatch(setSelectedAssetUuid(data.uuid));
  };

  /*  const handleDeleteUser = (user, isNewUser) => {
    onDeleteUser?.(user, isNewUser);
  };*/

  const handleNewUser = () => {
    setIsNewUserSaved(true);
  };

  return (
    <>
      {clickOnAllMembers && showAllMembersButton && (
        <div className={s.header} onClick={clickOnAllMembers}>
          <BaseIcon size={14} icon="arrow-left-full" className={s.iconBack} />
          All members
        </div>
      )}

      {!showAllMembersButton && (
        <div className={s.topButtonRight}>
          <BaseButtonSquare
            size="2"
            svgSize="3"
            icon="close"
            theme="flat-gray-dark"
            onClick={toggleAssetView}
          />
        </div>
      )}

      <div className={s.container}>
        {currentItem ? (
          <AssetsViewUserTile
            uuid={currentItem.uuid}
            name={currentItem.name}
            avatarUrl={undefined}
            // @ts-ignore
            email={currentItem.email}
            address={currentItem.address}
            zipCode={currentItem.zipCode}
            isAdmin={isAdmin}
            isNewUser={isNewUser || false}
            toggleAssetView={toggleAssetView}
            onChangeNewUser={handleNewUser}
          />
        ) : data?.owners && data?.owners.length ? (
          data.owners.map((owner) => {
            return (
              <div key={owner.id}>
                <AssetsViewUserTile
                  uuid={owner.uuid}
                  name={owner.name}
                  avatarUrl={owner.avatarUrl}
                  email={owner.email ?? owner.name}
                  address={owner?.address}
                  zipCode={owner?.zipCode}
                  isAdmin={owner?.isAdmin || false}
                  isNewUser={isNewUser || false}
                  toggleAssetView={toggleAssetView}
                />
              </div>
            );
          })
        ) : null}

        {/*isNewUser && (
        <BaseSelect
          inputHeight="2"
          theme="filled-gray"
          name="userRole"
          value={newUserRole}
          options={[
            { label: 'Member', value: '1' },
            { label: 'Manager', value: '2' },
          ]}
          className={s.selectbox}
          defaultLabel="Member"
          onChange={({ value }) => setNewUserRole(value as NewUserRole)}
        />
      )*/}

        {children && children.length !== 0 && (
          <div className={s.assetsContainer}>
            <AssetsCard
              isUserACommunityMember={isUserACommunityMember}
              key={data.uuid}
              data={data}
              assetIcon={get3dAssetIcon(data.type)}
              childrenCount={children.length}
              onClick={() => handleAssetClick(data)}
              isMoreButtonActive={true}
            />
          </div>
        )}
        {children && children.length ? (
          <div className={s.assetsContainer}>
            {[...children].map((asset) => {
              return (
                <AssetsCard
                  isUserACommunityMember={isUserACommunityMember}
                  key={asset.uuid}
                  data={asset}
                  assetIcon={get3dAssetIcon(asset.type)}
                  isMoreButtonActive={true}
                />
              );
            })}
          </div>
        ) : (
          <div className={s.noAssetsContainer}>
            <div className={s.noAssetPill}>No Assets</div>
          </div>
        )}
        {!readOnly && showAllMembersButton && !isUserACommunityMember && (
          <BaseButton
            isLoading={isAppLoading}
            onClick={onAddAssetClick}
            theme="grey2"
            className={s.addAssetButton}
            icon="plus-circle">
            Add Asset
          </BaseButton>
        )}
      </div>
    </>
  );
};

/*const UserTile = ({ name, avatarUrl, email, isAdmin, address, isNewUser, handleDeleteUser }) => (
  <div
    className={classnames(s.userTileContainer, {
      [s.newUser]: isNewUser,
    })}>
    <div className={s.imgContainer}>{avatarUrl && <img src={avatarUrl} />}</div>
    <div className={s.infoContainer}>
      <div className={s.actionsContainer}>
        <BaseButtonSquare className={s.button} icon="pencil-edit" size="2" />
        {(isAdmin || isNewUser) && (
          <BaseButtonSquare
            onClick={() => handleDeleteUser(email, isNewUser)}
            className={s.button}
            icon="trash"
            size="2"
          />
        )}
      </div>
      <div className={s.name}>{name}</div>
      <div className={s.email}>{email}</div>
      {address && (
        <div className={s.address}>
          <BaseIcon size={14} icon="pin" className={s.iconMap} />
          {address}
        </div>
      )}
    </div>
  </div>
);*/
