import { BaseErrorMessage, TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import { ELsKey, ls } from 'src/utils/localStorage';
import React, { useState, useRef, RefObject } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/validation';

import { BaseButton } from 'src/components/BaseButton';
import { TFormLoginProps } from './FormLogin.types';
import { afterAuth } from 'src/constants/refetchQueries';
import s from './FormLogin.module.scss';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useGraphQLMessage } from '../../hooks/useGraphQLMessage';
import { useTokenAuthMutation } from 'src/graphql';
import ReCAPTCHA from 'react-google-recaptcha';

const initFormData = {
  email: '',
  password: '',
};

const validators = () => ({
  email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  password: [...((n) => [rules.required(n)])('Password')],
});

export const FormLogin: React.FC<TFormLoginProps> = ({ className, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [tokenAuthMutation, { loading, error }] = useTokenAuthMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const recaptchaRef = useRef() as RefObject<ReCAPTCHA>;
  const [failedSignIn, setFailedSignIn] = useState(0);

  const [formData, setFormData] = useState(initFormData);

  const handleFieldChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    if (errors) {
      return;
    }

    try {
      const { data } = await tokenAuthMutation({
        variables: {
          username: formData.email,
          password: formData.password,
        },
        refetchQueries: ({ data }) => {
          ls.set(ELsKey.TOKEN_AUTH, data?.tokenAuth);

          return afterAuth;
        },
      });
      const recaptchaToken = recaptchaRef.current?.getValue();

      const checkData = failedSignIn >= 3 ? !!(data && recaptchaToken) : !!data;

      if (checkData) {
        const token = data?.tokenAuth;
        dispatch(setTokenAuth(token));

        onSuccess?.();
      }
    } catch (err) {
      setFailedSignIn((prev) => prev + 1);
    }
  }

  return (
    <form onSubmit={handleSubmit} className={className}>
      <BaseInput
        type="email"
        name="email"
        value={formData.email}
        onChange={handleFieldChange}
        label="Email"
        theme="line-light"
        className={s.inputEmail}
        error={validationErrors.email}
        disabled={loading}
        onBlur={(e) => handleFieldBlur(e, validators().email)}
      />
      <BaseInput
        type="password"
        name="password"
        value={formData.password}
        onChange={handleFieldChange}
        label="Password"
        theme="line-light"
        className={s.inputPassword}
        error={validationErrors.password}
        disabled={loading}
        onBlur={(e) => handleFieldBlur(e, validators().password)}
      />

      {graphqlMessage && (
        <BaseErrorMessage className={s.queryMessage}>{graphqlMessage}</BaseErrorMessage>
      )}
      {failedSignIn >= 3 && (
        <div className={s.recaptchaContainer}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
          />
        </div>
      )}
      <BaseButton type="submit" className={s.buttonLogin} isLoading={loading}>
        Login
      </BaseButton>
    </form>
  );
};
