import { LibraryTypesGraphql, ListLibrariesQuery, useListLibrariesQuery } from 'src/graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TLibrary, TLibraryChooserProps, TSelectedLibraryType } from './LibraryChooser.types';
import {
  customHeatPump,
  customHouse,
  customLoad,
  customPV,
  selectLibraryOptions,
} from 'src/components/LibraryChooser/LibraryChooserConstants';
import { formatSubtitle, generateIcon } from 'src/components/LibraryChooser/LibraryChooserHelpers';

import { AssetsListWithSearch } from 'src/components/AssetsListWithSearch';
import { BaseSelect } from 'src/components/BaseSelect';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { NN } from 'src/typings/helpers';
import { TAssetType } from 'src/typings/base-types';
import { debounce } from 'lodash';
import { produce } from 'immer';
import s from './LibraryChooser.module.scss';
import { useGraphQLMessage } from 'src/hooks/useGraphQLMessage';

export const LibraryChooser: React.FC<TLibraryChooserProps> = ({
  onlyCustomAssets,
  onLibraryChoose,
  isAllButtonRequiredInFilter,
  showLibraryTypeFilter = true,
  disableDescription = false,
  removeLibraryHomes = false,
  disableAssetDescription = false,
  ...rest
}: TLibraryChooserProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchVariable, setSearchVariable] = useState(''); // We use this second state to achieve search debouncing
  const [selectedLibraryType, setSelectedLibraryType] = useState<TSelectedLibraryType>(
    LibraryTypesGraphql.GsyLibrary,
  );
  const [searchForCustomAssets, setSearchForCustomAssets] = useState('');

  const allCustomAssets = useMemo(
    () => [customHouse, customPV, customLoad, customHeatPump],
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchVariableDebounced = useCallback(
    debounce((v: typeof searchVariable) => setSearchVariable(v), 300),
    [],
  );

  const { data: queriedData, loading: loadingA, error: errorA } = useListLibrariesQuery({
    variables: {
      search: searchVariable,
      tags: rest.activeFilter,
      libraryTypes: selectedLibraryType,
    },
  });

  const { data: houseTypeData, loading: loadingB, error: errorB } = useListLibrariesQuery({
    variables: {
      tags: LIBRARY_FILTERS_MAPPING.Area,
      libraryTypes: selectedLibraryType,
    },
  });

  const filteredAssets = useMemo(() => {
    if (!queriedData || !houseTypeData) return [];

    if (
      rest.activeFilter === LIBRARY_FILTERS_MAPPING.All &&
      rest.disabledFilters?.includes(LIBRARY_FILTERS_MAPPING.Area)
    ) {
      const output: NN<ListLibrariesQuery['listLibraries']>['libraries'] = [];
      const houses = houseTypeData.listLibraries?.libraries?.map((p) => p?.uuid) || [];

      queriedData.listLibraries?.libraries?.map((item) => {
        if (houses.includes(item?.uuid)) return;
        output.push(item);
      });

      return output;
    }

    return queriedData.listLibraries?.libraries || [];
  }, [queriedData, houseTypeData, rest.disabledFilters, rest.activeFilter]);

  const mappedAssets = useMemo((): TLibrary[] => {
    return filteredAssets.map((item) => {
      const { areaCountNumber, deviceCountNumber, representation } = item?.scenarioData || {};

      const libraryUuid = item?.uuid || '';

      const library: TLibrary = {
        libraryUuid,
        uuid: representation?.uuid || '',
        icon: generateIcon(item?.name, representation?.type as TAssetType),
        title: item?.name || '',
        subtitle: formatSubtitle(areaCountNumber, deviceCountNumber),
        description: item?.description,
        type: representation?.type as TAssetType,
        scenarioData: item?.scenarioData,
        key: libraryUuid,
      };

      return library;
    });
  }, [filteredAssets]);

  const customStorageFromQueriedData = useMemo(() => {
    if (!queriedData) return undefined;

    const storage = queriedData.listLibraries?.libraries?.find(
      (item) => item?.name === 'Custom Battery',
    );

    if (!storage) return undefined;
    //convert to TLibrary
    const { areaCountNumber, deviceCountNumber, representation } = storage?.scenarioData || {};

    const libraryUuid = storage?.uuid || '';

    const library: TLibrary = {
      libraryUuid,
      uuid: representation?.uuid || '',
      icon: generateIcon(storage?.name, representation?.type as TAssetType),
      title: storage?.name || '',
      subtitle: formatSubtitle(areaCountNumber, deviceCountNumber),
      description: storage?.description,
      type: representation?.type as TAssetType,
      scenarioData: storage?.scenarioData,
      key: libraryUuid,
    };

    return library;
  }, [queriedData]);

  const mappedCustomAssets = useMemo((): TLibrary[] => {
    if (onlyCustomAssets) {
      let extraQuery = '';
      const firstFilter = rest.activeFilter[0];
      switch (firstFilter) {
        case 'PV':
          extraQuery = 'PV';
          break;

        case 'Load':
          extraQuery = 'Load';
          break;

        case 'HeatPump':
          extraQuery = 'Heat';
          break;

        case 'Home':
          extraQuery = 'Home';
          break;

        case 'Storage':
          extraQuery = 'Battery';
          break;

        default:
          break;
      }

      return [...allCustomAssets, customStorageFromQueriedData as TLibrary].filter((item) => {
        return (
          item !== undefined &&
          item.title.toLowerCase().includes(extraQuery.toLowerCase()) &&
          item.title.toLowerCase().includes(searchForCustomAssets.toLowerCase())
        );
      });
    }

    return [];
  }, [
    onlyCustomAssets,
    allCustomAssets,
    searchForCustomAssets,
    rest.activeFilter,
    customStorageFromQueriedData,
  ]);

  const mappedAssetsExtended = useMemo(() => {
    if (
      searchVariable &&
      !customHouse.title.toLowerCase().includes(searchVariable.trim().toLowerCase())
    ) {
      return mappedAssets;
    }
    const unshiftAndReturn = (asset: TLibrary) => {
      return produce(mappedAssets, (draftState) => {
        draftState.splice(0, 0, asset);
      });
    };

    const findInResponseAndRemove = (title: string) => {
      const index = mappedAssets.findIndex((x) => x.title === title);
      if (index >= 0) {
        mappedAssets.splice(index, 1);
      }
    };

    if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.All) {
      findInResponseAndRemove('Custom Battery');
      findInResponseAndRemove('Custom Solar Panel');
      findInResponseAndRemove('Custom Load profile');
      let tempMappedAssets = [...mappedAssets];
      // if removeLibraryHomes is true, remove all homes from the list
      if (removeLibraryHomes) {
        // remove all homes from the list
        tempMappedAssets = tempMappedAssets.filter((item) => {
          return item.type !== 'Area';
        });
      }

      tempMappedAssets.unshift(customHouse, customPV, customLoad, customHeatPump);
      return tempMappedAssets;
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.Area) {
      // if removeLibraryHomes is true, remove all homes from the list
      if (removeLibraryHomes) {
        return [customHouse];
      }
      return unshiftAndReturn(customHouse);
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.PV) {
      // Append "Custom PV" asset and remove the one coming from BE
      findInResponseAndRemove('Custom Solar Panel');
      return unshiftAndReturn(customPV);
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.Load) {
      // Append "Custom Load" asset and remove the one coming from BE
      findInResponseAndRemove('Custom Load profile');
      return unshiftAndReturn(customLoad);
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.HeatPump) {
      // Append "Custom HeatPump" asset and remove the one coming from BE
      findInResponseAndRemove('Custom HeatPump profile');
      return unshiftAndReturn(customHeatPump);
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.Custom) {
      // Append "Custom PV" asset and remove the one coming from BE
      findInResponseAndRemove('Custom Solar Panel');
      findInResponseAndRemove('Custom Load profile');
      const tempMappedAssets = [...mappedAssets];
      tempMappedAssets.unshift(customHouse, customPV, customLoad, customHeatPump);
      return tempMappedAssets;
    }

    return mappedAssets;
  }, [mappedAssets, removeLibraryHomes, rest.activeFilter, searchVariable]);

  useEffect(() => {
    setSearchVariableDebounced(searchInputValue);
  }, [searchInputValue, setSearchVariableDebounced]);

  const errorComposed = mappedAssetsExtended ? undefined : (errorA || errorB); // prettier-ignore
  const errorFormatted = useGraphQLMessage({ error: errorComposed });

  const containerProps = {
    showTooltip: false,
    tooltipText: '',
    key: 'librarytype',
  };

  return (
    <>
      {showLibraryTypeFilter && (
        <FieldContainer {...containerProps} className={s.selectField}>
          <BaseSelect
            name="librarytype"
            value={selectedLibraryType}
            options={selectLibraryOptions}
            // onChange={(val) => handleSelectChange(val)}
            onChange={({ value }) => setSelectedLibraryType(value as TSelectedLibraryType)}
            theme="filled-gray"
            className={s.selectWrapper}
          />
        </FieldContainer>
      )}
      <AssetsListWithSearch
        disableDescription={disableDescription}
        disableAssetDescription={disableAssetDescription}
        assets={onlyCustomAssets ? mappedCustomAssets : mappedAssetsExtended}
        isAllButtonRequiredInFilter={isAllButtonRequiredInFilter}
        searchQuery={onlyCustomAssets ? searchForCustomAssets : searchInputValue}
        onSearchQueryChange={(v) => {
          if (onlyCustomAssets) {
            setSearchForCustomAssets(v);
            return;
          }
          setSearchInputValue(v);
        }}
        onAssetChoose={onLibraryChoose}
        loading={loadingA || loadingB}
        error={errorFormatted}
        {...rest}
      />
    </>
  );
};
