import { TCollaborationFields } from 'src/components/MapSidebar/components/MapSidebarResults/MapSidebarResults.types';

export const CollaborationFormFields: TCollaborationFields = {
  Basic: {
    visibleFieldsInfo: {
      name: true,
      description: true,
      startEndDate: true,
      startDate: true,
      endDate: true,
      currency: true,
      timezone: true,
      locationVisible: true,
    },
    visibleFieldsOrder: [
      'name',
      'description',
      'startEndDate',
      'startDate',
      'endDate',
      'currency',
      'timezone',
      'locationVisible',
    ],
  },
  Advanced: {
    visibleFieldsInfo: {
      spotMarketType: true,
      bidOfferMatchAlgo: true,
      marketCount: true,
      slotLengthMinutes: true,
      tickLengthSeconds: true,
      gridFeeType: true,
      slotLengthRealtimeSeconds: true,
      gridFeeEnabled: true,
      coefficientPercentage: false,
      gridFeeConstant: true,
      gridFeePercentage: true,
    },
    visibleFieldsOrder: [
      'spotMarketType',
      'bidOfferMatchAlgo',
      'marketCount',
      'slotLengthMinutes',
      'tickLengthSeconds',
      'gridFeeType',
      'slotLengthRealtimeSeconds',
      'coefficientPercentage',
      'gridFeeEnabled',
      'gridFeeConstant',
      'gridFeePercentage',
    ],
  },
};
