import React from 'react';
import { TEnergyBillInfoItem } from 'src/components/EnergyBillComponents/EnergyBillComponents.types';
import s from './EnergyBillComponents.module.scss';

export const EnergyBillListItem: React.FC<TEnergyBillInfoItem> = ({
  labelColor,
  label,
  percentage,
  cost,
}) => {
  return (
    <div className={s.listItem}>
      <span style={{ borderColor: labelColor }}>{label}</span>
      <span>{percentage}%</span>
      <span>{cost}</span>
    </div>
  );
};
