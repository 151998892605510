import React from 'react';
import classnames from 'classnames';
import s from '../GridMarket/GridMarket.module.scss';

type GridMarketModalHeaderProps = {
  formTitle: string;
  onClick?: () => void;
};

export const GridMarketModalHeader: React.FC<GridMarketModalHeaderProps> = ({
  formTitle,
  onClick,
}) => {
  return (
    <div className={classnames(s.paddingT)} onClick={onClick}>
      <header className={s.header}>
        <h3 className={s.title}>{formTitle}</h3>
      </header>
    </div>
  );
};
