import 'react-circular-progressbar/dist/styles.css';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import {
  ETradeType,
  TChartAssetProps,
  TDataOfTopLeftRows,
  TTopLeftRowProps,
} from './ChartAsset.types';
import React, { useMemo } from 'react';

import { AssetBlock } from 'src/components/AssetBlock';
import { BaseIcon } from 'src/components/BaseIcon';
import { StorageInfo } from 'src/components/StorageInfo';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import classnames from 'classnames';
import { devide } from 'src/utils/mathUtils';
import { formatter } from 'src/utils/formatter';
import s from './ChartAsset.module.scss';

const TopLeftRow: React.FC<TTopLeftRowProps> = ({
  trade,
  isProfit,
  icon,
  currencySymbol,
  num1,
  num2,
  deviceType,
}) => (
  <>
    <div
      className={classnames(s.badge, {
        [s.trade]: isProfit,
        [s.sold]: trade === ETradeType.Sold,
        [s.bought]: trade === ETradeType.Bought,
      })}>
      <BaseIcon icon={icon} size={14} />
      <p>{trade}</p>
      <span>{formatter.toOptionalFixed(num1, 2)} kWh </span>
      <p>for</p>
      <span>
        {' '}
        {currencySymbol}
        {formatter.toOptionalFixed(num2, 2)}
      </span>
    </div>
    {deviceType !== 'Storage' && (
      <div>
        Average price:{' '}
        <span className={s.cost}>
          {formatter.toOptionalFixed(devide(num2, num1), 2)} {currencySymbol}/kWh
        </span>
      </div>
    )}
  </>
);
export const ChartAsset: React.FC<TChartAssetProps> = ({
  priceEnergyAreaBalance,
  currency,
  deviceType,
  stateOfCharge,
  capacityKw,
}) => {
  const currencySymbol = formatter.getCurrencySymbol(currency);

  const icon: TIconNames = useMemo(() => {
    switch (deviceType) {
      case 'PV':
        return 'solar-3d';
      case 'Load':
        return 'plug-3d';
      case 'HeatPump':
        return 'heatpump-3d';
      case 'Storage':
        return 'battery-3d';
      case 'InfiniteBus':
        return 'power-plant-3d';
    }
    return 'plug-3d';
  }, [deviceType]);

  const currentStateOfCharge = useMemo(() => {
    if (!stateOfCharge) return 0;
    const lastItem = Object.values(stateOfCharge)[Object.keys(stateOfCharge).length - 1] as number;
    return lastItem;
  }, [stateOfCharge]);

  const EnergyStoredBlock: React.FC = () => {
    const energyStorage = currentStateOfCharge * (capacityKw / 100);

    return (
      <div>
        Energy Stored:{' '}
        <span className={s.cost}>{formatter.toOptionalFixed(energyStorage, 2)} kWh</span>
      </div>
    );
  };

  const TopLeftTag: React.FC = () => {
    if (!deviceType) return null;

    const data: TDataOfTopLeftRows = [];
    switch (deviceType) {
      case 'PV':
        data.push({
          trade: ETradeType.Sold,
          num1: priceEnergyAreaBalance?.sold,
          num2: priceEnergyAreaBalance?.earned,
          icon: 'usd-black' as TIconNames,
        });
        break;
      case 'Load':
      case 'HeatPump':
        data.push({
          trade: ETradeType.Bought,
          num1: priceEnergyAreaBalance?.bought,
          num2: priceEnergyAreaBalance?.spent,
          icon: 'usd-white' as TIconNames,
        });
        break;
      case 'Storage':
        data.push({
          trade: ETradeType.Trade,
          num1: priceEnergyAreaBalance?.bought + priceEnergyAreaBalance?.sold,
          num2: priceEnergyAreaBalance?.earned - priceEnergyAreaBalance?.spent,
          icon: 'usd-black' as TIconNames,
        });
        break;
      case 'InfiniteBus':
        data.push({
          trade: ETradeType.Bought,
          num1: priceEnergyAreaBalance?.bought,
          num2: priceEnergyAreaBalance?.spent,
          icon: 'usd-white' as TIconNames,
        });
        data.push({
          trade: ETradeType.Sold,
          num1: priceEnergyAreaBalance?.sold,
          num2: priceEnergyAreaBalance?.earned,
          icon: 'usd-black' as TIconNames,
        });
        break;
    }

    return (
      <div className={s.topLeftTag}>
        {deviceType === 'Storage' && <EnergyStoredBlock />}
        {data.map((item) => (
          <TopLeftRow
            key={`${item.num1}-${item.num2}-${item.icon}`}
            num1={item.num1}
            num2={item.num2}
            isProfit={item.num2 >= 0}
            icon={item.icon}
            deviceType={deviceType}
            currencySymbol={currencySymbol}
            trade={item.trade as ETradeType}
          />
        ))}
      </div>
    );
  };

  const BottomRightTag: React.FC = () => {
    if (deviceType !== 'Storage') return null;

    return (
      <div className={s.bottomRight}>
        <CircularProgressbarWithChildren
          styles={buildStyles({
            pathColor: '#66ee66',
          })}
          value={currentStateOfCharge}>
          <BaseIcon icon="lightning" size={14} />
          <div style={{ fontSize: 12, marginTop: 3 }}>{`${formatter.toOptionalFixed(
            currentStateOfCharge,
            2,
          )}%`}</div>
        </CircularProgressbarWithChildren>
      </div>
    );
  };

  return (
    <>
      <AssetBlock
        className={s.maxHeight}
        chartAssetClass={s.assetIcon}
        topLeftComponent={<TopLeftTag />}
        bottomRightComponent={<BottomRightTag />}
        icon={icon}
        title=""
        subtitle=""
        // aspectRatio={115}
        theme="light"
      />
      {deviceType === 'Storage' && (
        <StorageInfo currency={currency} priceEnergyAreaBalance={priceEnergyAreaBalance} />
      )}
    </>
  );
};
