import React, { useMemo } from 'react';

import { EResultsTab } from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import { SidebarSubTab } from 'src/components/MapSidebarSCM/components/MapSidebarResults/components/SidebarSubTab';
import { TSidebarSubTabItemList } from 'src/components/MapSidebarSCM/components/MapSidebarResults/components/SidebarSubTab/SidebarSubTab.types';
import { selectActiveTabResults } from 'src/redux/scm/scm.selectors';
import { setActiveTabResults } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const HomeCommunityTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeResultsTab = useSelector(selectActiveTabResults);
  const { t } = useTranslation();

  const ResultsSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Results MyHome',
        onClick: () => dispatch(setActiveTabResults(EResultsTab.Home)),
        label: t('MY_HOME') || '',
        isActive: () => activeResultsTab === EResultsTab.Home,
      },
      {
        liTitle: 'Results Community',
        onClick: () => dispatch(setActiveTabResults(EResultsTab.Community)),
        label: t('COMMUNITY') || '',
        isActive: () => activeResultsTab === EResultsTab.Community,
      },
    ],
    [activeResultsTab, dispatch, t],
  );

  return <SidebarSubTab tabs={ResultsSubtabs} />;
};
