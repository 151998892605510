import React from 'react';
import { TAssetCreateNewTabs } from './AssetCreateNew.types';
import classNames from 'classnames';
import s from './AssetCreateNew.module.scss';

export const AssetCreateNewTabs: React.FC<TAssetCreateNewTabs> = ({
  activeSettingsTab,
  onTabSelect,
  mode,
}) => {
  switch (mode) {
    case 'Storage':
      return (
        <button type="button" className={classNames(s.active)}>
          Battery Settings
        </button>
      );
    case 'Load':
      return (
        <button type="button" className={classNames(s.active)}>
          Load Settings
        </button>
      );
    default:
      return (
        <>
          <button
            type="button"
            className={classNames({
              [s.active]: activeSettingsTab === 'basic',
            })}
            onClick={() => onTabSelect('basic')}>
            Basic Settings
          </button>
          <button
            type="button"
            className={classNames({
              [s.active]: activeSettingsTab === 'advanced',
            })}
            onClick={() => onTabSelect('advanced')}>
            Advanced Settings
          </button>
        </>
      );
  }
};
