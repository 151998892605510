import React from 'react';

import classnames from 'classnames';
import { BaseLink } from 'src/components/BaseLink';
import { routesConfig } from 'src/routes/routes.config';
import { TLandingPageFooterProps } from './LandingPageFooter.types';

import s from './LandingPageFooter.module.scss';

export const LandingPageFooter: React.FC<TLandingPageFooterProps> = ({
  variant,
}: TLandingPageFooterProps) => {
  const currentYear = new Date().getFullYear();
  console.log(s[variant]);
  return (
    <div className={classnames(s.copyrightContainer, s[variant])}>
      <div className={s.copyrightText}>Copyright © {currentYear} Grid Singularity Pte Ltd.</div>
      <BaseLink
        to={routesConfig.privacyPolicy()}
        className={classnames(s.privacyPolicyLink, s[variant], 'click-area')}
        theme="3">
        Privacy Policy
      </BaseLink>
      <BaseLink
        to={routesConfig.termsOfService()}
        className={classnames(s.termsOfService, s[variant], 'click-area')}
        theme="3">
        Terms of Service
      </BaseLink>
    </div>
  );
};
