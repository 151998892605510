import 'react-tooltip/dist/react-tooltip.css';

import {
  ConfigType,
  useListCollaborationsLazyQuery,
  useListCommunityInfoLazyQuery,
  useListScmLazyQuery,
} from 'src/graphql';
import {
  EListFilter,
  TCommunitiesListProps,
  TListHeaderProps,
  TMapSidebarCanaryProjects,
  TMapSidebarProjects,
} from './MapSidebarCommunitiesList.types';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectIsLoggedIn,
  selectTokenAuth,
  selectUserRole,
  selectUsername,
} from 'src/redux/auth/auth.selectors';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { CommunityListItem } from 'src/components/MapSidebarSCM/components/CommunityListItem';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral';
import { ContextMenu } from 'src/components/ContextMenu';
import { EAppMode } from 'src/utils/appMode';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { EModalSize } from 'src/constants/modals';
import { EUserRoles } from 'src/typings/base-types';
import { FixedSizeList } from 'react-window';
import { LoadingWrapper } from 'src/components/LoadingWrapper';
import ReactDOMServer from 'react-dom/server';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';
import { convertLngLat } from 'src/utils/worldMap/helpers';
import { debounce } from 'lodash';
import { getConfigurationLocation } from 'src/utils/configuration/getConfigurationLocation';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './MapSidebarCommunitiesList.module.scss';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { selectCommunitiesList } from 'src/redux/communities/communities.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectMeasurementRequests } from 'src/redux/scm/scm.selectors';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { setCommunitiesList } from 'src/redux/communities/communities.slice';
import { setModalCommunitySummaryView } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useDuplicateCommunity } from 'src/hooks/useDuplicateCommunity';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

const FILTERS = [
  { name: 'Simulations', value: EListFilter.Simulations },
  { name: 'Operational', value: EListFilter.Operational },
];

const ListHeader: React.FC<TListHeaderProps> = ({ activeFilter, onFilterChange, isLoggedIn }) => {
  return (
    <div className={s.listHeader}>
      {isLoggedIn && (
        <nav>
          <ul>
            {FILTERS.map((item) => (
              <li
                key={item.value}
                className={classnames({
                  [s.active]: activeFilter === item.value,
                })}>
                <button type="button" onClick={() => onFilterChange(item.value)}>
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
      {/* <BaseSearch
        name="communitiesSearch"
        theme="filled-gray"
        value={searchQuery}
        onChange={({ value }) => onSearchQueryChange(value)}
        label="Search by name"
        iconRight="search"
        iconRightColor={vars['color-black']}
      /> */}
      {/* <SimulationStatusFilters
        className={s.simulationFilterContainer}
        items={filterItems}
        name="baseTagsTimeline"
        value={simulationFilter}
        onChange={({ value }) => {
          setSimulationFilter(value);
        }}
      /> */}
    </div>
  );
};

const ListItem = ({ index, style, data }) => {
  const item = data.items[index];
  const {
    timestamp,
    configurations: [configuration],
  } = item;
  const location = getConfigurationLocation(item);

  if (!location) return null;

  const locationCoords = convertLngLat(location);

  return (
    <div className={s.listItem} style={style}>
      <CommunityListItem
        className={s.listItem}
        user={item.user}
        configurationUuid={configuration.uuid}
        title={configuration.name}
        key={configuration.uuid}
        configType={configuration.configType}
        isPrivate={item.private}
        description={
          timestamp
            ? `Updated on ${UTCMoment.fromBackend(timestamp).format(
                FRONTEND_DATE_FORMATS.COMMUNITY_DATE,
              )}`
            : ''
        }
        locationCoords={locationCoords}
        onMenuClick={(menuTriggerRef, { communityUuid }) =>
          data.onMenuClick(menuTriggerRef, {
            projectID: item.project?.uuid,
            communityUuid,
            configurationUuid: configuration.uuid,
            uuid: item.uuid,
          })
        }
      />
    </div>
  );
};

const CommunitiesList: React.FC<TCommunitiesListProps> = ({
  height,
  items,
  removeCommunity,
  updatePersonalCommunitiesList,
  onDuplicationComplete,
  setLoadingStatus,
  isOperationalTab = false,
}) => {
  const { id: modalId } = useModal();
  const dispatch = useAppDispatch();
  const { triggerCommunityDeleteAlert } = useContext(ApplicationContext);

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const userRole = useSelector(selectUserRole);
  const appMode = useSelector(selectAppMode);
  const userName = useSelector(selectUsername);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  //const userToAdminRequests = useSelector(selectUserToAdminRequests);

  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const [openedProjectUuid, setOpenedProjectUuid] = useState<string | null>(null);
  const [openedConfigurationUuid, setOpenedConfigurationUuid] = useState<string | undefined>(
    undefined,
  );
  const [openedModalConfigUuid, setOpenedModalConfigUuid] = useState<string>();

  const {
    getDuplicationName,
    duplicateCanaryNetwork,
    duplicateCommunity,
    duplicateCollaboration,
    duplicateCollaborationLoading,
    duplicateConfigLoading,
    duplicateCNLoading,
  } = useDuplicateCommunity();

  const closeActiveModal = () => {
    setOpenedProjectUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };
  const measurementRequests = useSelector(selectMeasurementRequests);
  const isAdmin = userRole === EUserRoles.Admin;

  const showInfoToolTip = useMemo(
    () => measurementRequests && measurementRequests.length > 0 && !isAdmin,
    [measurementRequests, isAdmin],
  );
  const onMenuClick = (menuTriggerRef, { communityUuid, configurationUuid, projectID }) => {
    setOpenedModalConfigUuid(communityUuid);
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedProjectUuid(projectID);
      setOpenedConfigurationUuid(configurationUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const openedDropdownParent = items.find(
    (item) => item.configurations[0]?.uuid === openedModalConfigUuid,
  );
  const openedDropdownParentIsCN =
    openedDropdownParent?.configurations[0]?.configType === ConfigType.CanaryNetwork;

  const openedDropdownParentIsCollaboration =
    openedDropdownParent?.configurations[0]?.configType === ConfigType.Collaboration;

  const isOwner = openedDropdownParent?.user === userName;

  //const isExchangeOperator = userRole === EUserRoles.ExchangeOperator;

  //const isRequestedForCn = useMemo(() => {
  //  return (
  //    openedConfigurationUuid &&
  //    userToAdminRequests[openedConfigurationUuid]?.find(
  //      (item) => item.type === 'CONVERT_SIM_TO_CN' && item.status === 'RECEIVED',
  //    )
  //  );
  //}, [openedConfigurationUuid, userToAdminRequests]);

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (openedDropdownParentIsCN ? isOwner || isAdmin : true) && !isOperationalTab) {
    dropDownItems.push({
      title: 'Duplicate',
      icon:
        duplicateCollaborationLoading || duplicateConfigLoading || duplicateCNLoading
          ? 'spinner'
          : 'duplicate',
      onClick: async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const configuration = openedDropdownParent?.configurations[0];
        if (configuration) {
          const duplicationName = getDuplicationName(configuration, items);

          try {
            if (openedDropdownParentIsCN) {
              duplicateCanaryNetwork(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else if (openedDropdownParentIsCollaboration) {
              duplicateCollaboration(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else {
              duplicateCommunity(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            }
          } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const msg = JSON.parse(`${(err as any).message}`).duplicate_canary_network[0];
            dispatch(
              openToast({
                message: msg,
                type: 'error',
              }),
            );
          }
          closeActiveModal();
          if (setLoadingStatus) setLoadingStatus(true);
        }
      },
    });
  }
  if (openedDropdownParent?.user === userName) {
    dropDownItems.push(
      {
        title: 'Edit',
        icon: 'pencil-create' as const,
        onClick: () => {
          if (openedModalConfigUuid) {
            dispatch(setActiveConfigurationUuid(openedModalConfigUuid));
            dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
          }
        },
      },
      {
        title: 'Delete',
        icon: 'custom-trash' as const,
        onClick: async () => {
          if (openedProjectUuid) {
            await triggerCommunityDeleteAlert();
            if (isOperationalTab && openedConfigurationUuid)
              await removeCommunity({ projectUuid: openedConfigurationUuid });
            else await removeCommunity({ projectUuid: openedProjectUuid });
            updatePersonalCommunitiesList();
            closeActiveModal();
          }
        },
      },
    );
  }

  return (
    <div className={s.communitiesList}>
      <div
        id="connect-community-anchor-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <h3> 👈 Connect Community</h3>
            <p>
              To connect live asset data you will first be asked to review final member and market
              settings
            </p>
          </div>,
        )}>
        <FixedSizeList
          height={height}
          itemData={{
            onMenuClick,
            items,
          }}
          itemCount={items.length}
          itemSize={appMode === EAppMode.Desktop ? 90 : 215}
          onScroll={closeActiveModal}
          width={EModalSize.L370}
          className={classnames(s.communitiesListWindow)}>
          {ListItem}
        </FixedSizeList>
      </div>
      <Tooltip
        anchorId="connect-community-anchor-element"
        isOpen={isOperationalTab && showInfoToolTip}
        place="right"
        position={{ x: 360, y: 375 }}
      />
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
    </div>
  );
};

export const MapSidebarList: React.FC<{
  mobileListClassName?: string;
  searchQuery;
  setSearchQuery;
  activeFilter: EListFilter;
  setActiveFilter: (val: EListFilter) => void;
}> = ({ mobileListClassName, searchQuery, setSearchQuery, activeFilter, setActiveFilter }) => {
  const dispatch = useAppDispatch();
  const [listHeight, setListHeight] = useState<number>();
  const communitiesList = useSelector(selectCommunitiesList);
  const token = useSelector(selectTokenAuth);
  const appMode = useSelector(selectAppMode);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [debouncedSearchQuery, __setDebouncedSearchQuery] = useState(searchQuery);
  const [isloading, setLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebouncedSearchQuery = useCallback(
    debounce((val) => {
      __setDebouncedSearchQuery(val);
    }, 400),
    [],
  );
  useEffect(() => {
    setDebouncedSearchQuery(searchQuery);
  }, [searchQuery, setDebouncedSearchQuery]);

  useEffect(() => {
    if (!isLoggedIn) {
      setActiveFilter(EListFilter.Simulations);
    }
  }, [isLoggedIn, setActiveFilter]);

  const onListContainerLayout = useCallback(
    (elem: HTMLDivElement) => {
      if (elem && !listHeight) {
        setListHeight(elem.clientHeight);
      }
    },
    [listHeight],
  );

  const [
    fetchCommunities,
    { data: allCommunities, loading: communitiesLoading },
  ] = useListCommunityInfoLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [fetchSCMCommunities, { data: allSCMNetworks }] = useListScmLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      configType: ConfigType.Collaboration,
    },
  });

  const [fetchOperationalSCMCommunities, { data: allOperationalSCMNetworks }] = useListScmLazyQuery(
    {
      fetchPolicy: 'no-cache',
      variables: {
        configType: ConfigType.CanaryNetwork,
      },
    },
  );

  const [fetchCollaborations, { data: allCollaborations }] = useListCollaborationsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    switch (activeFilter) {
      case EListFilter.Operational:
        fetchOperationalSCMCommunities();
        return;
      case EListFilter.Simulations:
        fetchSCMCommunities();
        return;
      default: {
        fetchCommunities();
      }
    }
  }, [
    activeFilter,
    fetchSCMCommunities,
    fetchCommunities,
    fetchOperationalSCMCommunities,
    fetchCollaborations,
    isLoggedIn,
  ]);

  const projects = useMemo(() => {
    if (appMode === EAppMode.Mobile) {
      return allCollaborations?.listCollaborations?.configurations || [];
    } else if (activeFilter === EListFilter.Operational && isLoggedIn) {
      const configurations = allOperationalSCMNetworks?.listScmCommunities?.configurations;
      return configurations ? [...configurations].reverse() : [];
    } else if (activeFilter === EListFilter.Simulations && isLoggedIn) {
      const configurations = allSCMNetworks?.listScmCommunities?.configurations;
      return configurations ? [...configurations].reverse() : [];
    } else {
      return allCommunities?.listCommunityInfo?.projects || [];
    }
  }, [
    activeFilter,
    allSCMNetworks?.listScmCommunities?.configurations,
    allCollaborations?.listCollaborations?.configurations,
    allCommunities?.listCommunityInfo?.projects,
    allOperationalSCMNetworks?.listScmCommunities?.configurations,
    appMode,
    isLoggedIn,
  ]);

  const onDuplicationComplete = () => {
    setActiveFilter(EListFilter.Simulations);
    setLoading(false);
    if (activeFilter === EListFilter.Operational) {
      fetchOperationalSCMCommunities();
    } else {
      fetchSCMCommunities();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let items: any = [];
    if (activeFilter && [EListFilter.Simulations, EListFilter.Operational].includes(activeFilter)) {
      items = (projects as TMapSidebarCanaryProjects)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: any, item) => {
          if (
            item.coordinates?.latitude &&
            item.coordinates?.longitude &&
            item.user === token?.username &&
            token?.userRole !== EUserRoles.Admin
          ) {
            acc.push({
              name: item.name || '',
              latitude: item.coordinates.latitude,
              locationVisible: true,
              longitude: item.coordinates.longitude,
              private: false,
              timestamp: item.timestamp,
              user: item.user,
              uuid: item.uuid,
              project: item.project,
              configurations: [
                {
                  configType: item.configType,
                  latitude: item.coordinates.latitude,
                  longitude: item.coordinates.longitude,
                  name: item.name,
                  uuid: item.uuid,
                },
              ],
            });
          } else if (
            item.coordinates?.latitude &&
            item.coordinates?.longitude &&
            token?.userRole === EUserRoles.Admin
          ) {
            acc.push({
              name: item.name || '',
              latitude: item.coordinates.latitude,
              locationVisible: true,
              longitude: item.coordinates.longitude,
              private: false,
              timestamp: item.timestamp,
              user: item.user,
              uuid: item.uuid,
              project: item.project,
              configurations: [
                {
                  configType: item.configType,
                  latitude: item.coordinates.latitude,
                  longitude: item.coordinates.longitude,
                  name: item.name,
                  uuid: item.uuid,
                },
              ],
            });
          }

          return acc;
        }, [])
        .filter((item) => {
          return item!.name!.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
        })
        .sort((a, b) => +new Date(b?.timestamp) - +new Date(a?.timestamp));
    } else {
      items = (projects as TMapSidebarProjects)
        .reduce((acc: TMapSidebarProjects, item) => {
          let include = false;

          if (!item) return acc;

          const communityHasLocation = Boolean(getConfigurationLocation(item));
          if (item.configurations?.length && communityHasLocation) {
            if (activeFilter === EListFilter.Simulations) {
              if (token?.username === item.user) {
                include = true;
              }
            } else {
              include = true;
            }
          }

          if (include) {
            item.configurations?.forEach((config) => {
              if (config) {
                acc.push({
                  ...item,
                  name: config.name,
                  configurations: [config],
                });
              }
            });
          }

          return acc;
        }, [])
        .filter((item) => {
          return item!.name!.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
        })
        .sort((a, b) => +new Date(b?.timestamp) - +new Date(a?.timestamp));
    }
    dispatch(setCommunitiesList(items));
  }, [dispatch, projects, debouncedSearchQuery, token?.username, token?.userRole, activeFilter]);

  if (appMode === EAppMode.Mobile) {
    return (
      <div className={classnames(s.list, mobileListClassName)} ref={onListContainerLayout}>
        <LoadingWrapper loading={!listHeight || communitiesLoading || isloading}>
          {() => (
            <ConfigurationManagementCentral>
              {({ handleCommunityRemove, handleCNCommunityRemove }) => (
                <CommunitiesList
                  items={communitiesList}
                  height={listHeight!}
                  removeCommunity={
                    activeFilter === EListFilter.Operational
                      ? handleCNCommunityRemove
                      : handleCommunityRemove
                  }
                  updatePersonalCommunitiesList={
                    activeFilter === EListFilter.Operational
                      ? fetchOperationalSCMCommunities
                      : fetchSCMCommunities
                  }
                  isOperationalTab={activeFilter === EListFilter.Operational}
                  onDuplicationComplete={onDuplicationComplete}
                />
              )}
            </ConfigurationManagementCentral>
          )}
        </LoadingWrapper>
      </div>
    );
  }
  return (
    <div className={s.listContainer}>
      <ListHeader
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        isLoggedIn={isLoggedIn}
      />
      <div className={s.list} ref={onListContainerLayout}>
        <LoadingWrapper loading={!listHeight || communitiesLoading || isloading}>
          {() => (
            <ConfigurationManagementCentral>
              {({ handleCommunityRemove, handleCNCommunityRemove }) => (
                <CommunitiesList
                  items={communitiesList}
                  height={listHeight!}
                  removeCommunity={
                    activeFilter === EListFilter.Operational
                      ? handleCNCommunityRemove
                      : handleCommunityRemove
                  }
                  updatePersonalCommunitiesList={
                    activeFilter === EListFilter.Operational
                      ? fetchOperationalSCMCommunities
                      : fetchSCMCommunities
                  }
                  isOperationalTab={activeFilter === EListFilter.Operational}
                  onDuplicationComplete={onDuplicationComplete}
                  setLoadingStatus={setLoading}
                />
              )}
            </ConfigurationManagementCentral>
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
};
