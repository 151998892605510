import { LibraryChooser, TLibrary } from 'src/components/LibraryChooser';
import React, { useEffect, useMemo, useState } from 'react';

import { AssetCreateNewTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNewTabs';
import { AssetSettings } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetSettings';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TAssetCreateNewProps } from './AssetCreateNew.types';
import { TFilterItem } from 'src/components/AssetsListWithSearch';
import { TLibrariesTags } from 'src/typings/base-types';
import classNames from 'classnames';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import s from './AssetCreateNew.module.scss';
import { setHideSidebarHeader } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAssetCreateTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/useAssetCreateTabs';

export const AssetCreateNew: React.FC<TAssetCreateNewProps> = ({
  data,
  selectedAsset,
  setMode,
  onUpdate,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  setSelectedAsset,
  // setSelectedItem,
}) =>
  //
  {
    const dispatch = useAppDispatch();
    const [activeFilter, setActiveFilter] = useState<TLibrariesTags[]>(LIBRARY_FILTERS_MAPPING.All);
    const [selectedNewAsset, setSelectedNewAsset] = useState<TLibrary>();

    useEffect(() => {
      dispatch(setHideSidebarHeader(true));
      return () => {
        dispatch(setHideSidebarHeader(false));
      };
    }, [dispatch]);

    const subtitle = useMemo(() => {
      switch (selectedNewAsset?.type) {
        case 'Load':
          return 'Consumption: -- kWh';
        case 'Storage':
          return 'Capacity: -- kWh';
        default:
          return '';
      }
    }, [selectedNewAsset]);

    const onAssetSelect = (data: TLibrary) => {
      setSelectedNewAsset(data);
    };

    const onFilterChange = (filter: TFilterItem) => setActiveFilter(filter.value);
    const onCancelClick = () => setMode('assetPreview');

    const onAssetCancelClick = () => {
      setSelectedNewAsset(undefined);
    };

    const { onTabSelect, activeSettingsTab } = useAssetCreateTabs({
      data: selectedNewAsset,
    });

    const onTreeUpdate = () => onUpdate();
    return (
      <div className={s.container}>
        <header className={s.header}>
          <p>Add Asset</p>
          <BaseButton theme="grey3" onClick={onCancelClick} className={s.cancelButton}>
            <BaseIcon icon="close" className={s.svgIcon} />
          </BaseButton>
        </header>
        <div className={s.content}>
          {!selectedNewAsset && (
            <>
              <div className={s.typeSelector}>
                <h5>Which asset do you want to add?</h5>
                <p>
                  You can select a custom home profile/asset provided below, or click on an asset
                  icon in the dark grey rectangle to configure each asset separately.
                </p>
              </div>
              <LibraryChooser
                onlyCustomAssets={false}
                removeLibraryHomes={true}
                onLibraryChoose={onAssetSelect}
                isAllButtonRequiredInFilter={false}
                activeFilter={activeFilter}
                onFilterChange={onFilterChange}
                theme="light"
                listViewProp="3grid"
                showLibraryTypeFilter={false}
                searchPosition="top"
                showCustomAssetFilter={true}
                disableDescription={true}
              />
            </>
          )}
          {selectedNewAsset && (
            <div className={s.createAsset}>
              <div className={s.hero}>
                <BaseButton
                  theme="grey2"
                  onClick={onAssetCancelClick}
                  className={classNames(s.cancelButton, s.assetCancel)}>
                  <BaseIcon icon="close" className={s.svgIcon} />
                </BaseButton>
                <div className={s.icon}>
                  <BaseIcon icon={get3dAssetIcon(selectedNewAsset.type)} />
                </div>
                <h5>{selectedNewAsset.title}</h5>
                <p>{subtitle}</p>
              </div>
              <nav className={s.navigation}>
                <AssetCreateNewTabs
                  onTabSelect={onTabSelect}
                  activeSettingsTab={activeSettingsTab}
                  mode={selectedNewAsset.type}
                />
              </nav>
              <AssetSettings
                onAddNewAsset={onAddNewAsset}
                onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
                mainParentData={data}
                selectedChildUuid={selectedAsset}
                newAssetData={selectedNewAsset}
                mode={activeSettingsTab}
                setMode={setMode}
                onUpdate={onTreeUpdate}
                onSaveAction="add"
                setSelectedAsset={setSelectedAsset}
                backToPreview={() => {
                  onCancelClick();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
