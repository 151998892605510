import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
} from 'src/utils/assetsFields/assetsFields.types';

import { EFormVariant } from 'src/typings/base-types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';

export type TInfiniteBusPhoenixArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
  configurationCharacteristic: TConfigurationCharacteristic;
};

export const InfiniteBusPhoenix = ({
  isLibrary,
  values,
  configurationCharacteristic,
}: TInfiniteBusPhoenixArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'geoTagType',
      type: 'enum',
      label: 'Location Type',
      options: [{ value: 'area', label: 'Area' }],
      tooltipText: 'Parent market already has location',
      formView: EFormVariant.GridMarket,
    },
    {
      name: 'exchangeInformation',
      type: 'enum',
      label: 'Exchange Information',
      options: [{ value: 'spot', label: 'Spot' }],
      disabled: true,
      EXCLUDE: isLibrary,
      formView: EFormVariant.GridMarket,
      tooltipText: 'Specify the market type of this exchange. Only Spot is available at the moment',
      showTooltip: true,
    },
    {
      name: 'gridFeeEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Grid Fees',
      EXCLUDE: isLibrary,
      formView: EFormVariant.GridMarket,
      tooltipText: 'Specify the grid fees charged to trade in this market',
      showTooltip: true,
    },
    // {
    //   name: 'gridFeePercentage',
    //   type: 'number',
    //   label: 'Grid fee value',
    //   unit: '%',
    //   EXCLUDE:
    //     isLibrary ||
    //     !values.gridFeeEnabled ||
    //     settingsData.gridFeeType !== GridFeeType.Percentage,
    //   formView: EFormVariant.GridMarket,
    // },
    {
      name: 'gridFeeConstant',
      type: 'number',
      label: 'Grid Fee Value',
      unit: 'cents / kWh',
      EXCLUDE: isLibrary || !values?.gridFeeEnabled,
      // settingsData.gridFeeType !== GridFeeType.Constant,
      formView: EFormVariant.GridMarket,
    },
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      formView: EFormVariant.GridAgent,
      EXCLUDE: true,
    },
    {
      name: 'gridConnected',
      type: 'enum',
      label: 'Role',
      options: [
        { value: true, label: 'Grid connected' },
        { value: false, label: 'Islanded' },
      ],
      disabled: true,
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'energyRateType',
      type: 'enum',
      label: 'Selling Rate Type',
      options: [
        { value: 0, label: 'User Input' },
        { value: 1, label: 'File Upload' },
      ],
      disabled: true,
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'energyRateProfile',
      type: 'file',
      label: 'Selling Rate Profile',
      placeholder: 'Choose a file',
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'energyRate',
      type: 'number',
      label: 'Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.GridAgent,
      EXCLUDE: configurationCharacteristic.gridMakerHasUploadedProfile,
      tooltipText:
        'Selling rate is the price at which the community buys energy deficit from the external grid (utility)',
      showTooltip: true,
    },
    {
      name: 'energyRateProfileUuid',
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'buyingRateType',
      type: 'enum',
      label: 'Buying Rate Type',
      options: [
        { value: 0, label: 'User Input' },
        { value: 1, label: 'File Upload' },
      ],
      disabled: true,
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'buyingRateProfile',
      type: 'file',
      label: 'Buying Rate Profile',
      placeholder: 'Choose a file',
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'energyBuyRate',
      type: 'number',
      label: 'Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.GridAgent,
      tooltipText:
        'Buying rate is the price at which the external grid (utility) buys surplus from the community',
      showTooltip: true,
    },
    {
      name: 'buyingRateProfileUuid',
      formView: EFormVariant.GridAgent,
    },
    {
      name: 'libraryUUID',
      formView: EFormVariant.GridAgent,
    },
  ];
};
