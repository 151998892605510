import { DeviceTypes, Pv } from 'src/graphql';
import {
  EOrientationParam,
  TOrientationParam,
} from 'src/components/CustomPV/components/Orientation/Orientation.types';
import { FormAssetsParams, TAssetsSaveProps } from 'src/components/FormAssetsParams';
import React, { useEffect, useRef, useState } from 'react';

import { EFormVariant } from 'src/typings/base-types';
import { TAssetSettingsProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetSettings/AssetSettings.types';
import s from './AssetSettings.module.scss';
import { selectAssetValuesForUuid } from 'src/redux/configuration/configuration.selectors';
import { selectCreatedSCMMemberUUID } from 'src/redux/configuration/configuration.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useSCMMemberEvents } from 'src/hooks/useSCMMemberEvents';
import { useSelector } from 'react-redux';

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

export const AssetSettings: React.FC<TAssetSettingsProps> = ({
  mainParentData,
  newAssetData,
  //selectedChildUuid,
  setMode,
  mode,
  onUpdate,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  onEditAsset,
  onSaveAction,
  backToPreview: backToPreviewProp,
}) => {
  const formHasErrorsRef = useRef(false);

  const assetValues = useSelector(selectAssetValuesForUuid(newAssetData.uuid));
  //const addNewLibrary = useSelector(selectAddNewLibrary);
  const hostAssetUuid = useSelector(selectCreatedSCMMemberUUID);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);

  const [activeSettingsTab, setActiveSettingsTab] = useState<EFormVariant>(EFormVariant.Advanced);

  const { handleCreateAreaLiveEvent } = useSCMMemberEvents({
    backToPreview: () => {
      //if (backToPreviewProp) backToPreviewProp();
    },
  });
  /*const [newAssetConfig, setNewAssetConfig] = useState<TAssetsSaveProps>({
    assetUuid: '',
    values: {},
    assetType: undefined,
  });*/

  const handleOnSubmit = ({ values }: TAssetsSaveProps) => {
    let tempValues = values;
    if (newAssetData.type === 'PV') {
      tempValues = {
        ...values,
        [EOrientationParam.AZIMUTH]: azimuthValuesForCustomPV[EOrientationParam.AZIMUTH],
        [EOrientationParam.TILT]: azimuthValuesForCustomPV[EOrientationParam.TILT],
      };
    }

    if (isOperationCommunity) {
      if (!hostAssetUuid) return;

      handleCreateAreaLiveEvent({
        data: tempValues,
        chosenAsset: newAssetData,
        connectorAssetUuid: hostAssetUuid,
      });
      setTimeout(() => {
        //onUpdate(newAssetData);
        //dispatch(setSelectedAssetUuid(hostAssetUuid));
        if (backToPreviewProp) backToPreviewProp();
        //if (setSelectedAsset) setSelectedAsset(hostAssetUuid);
      }, 3000);
      return;
    }

    if (onSaveAction === 'edit') {
      if (!onEditAsset) return;
      onEditAsset({
        assetUuid: newAssetData.uuid,
        values: tempValues,
        assetType: newAssetData.type,
      });
    } else if (!hostAssetUuid) {
      if (!onAddNewAsset) return;
      onAddNewAsset({
        values: tempValues,
        chosenAsset: newAssetData,
      });
    } else {
      if (!onAddNewAssetUnderUuid) return;
      onAddNewAssetUnderUuid({
        addUnderUuid: hostAssetUuid,
        values: tempValues,
        chosenAsset: newAssetData,
      });
    }
    setMode('assetPreview');
    onUpdate(newAssetData);
  };

  if (newAssetData.type === 'PV') {
    let scenarioData: DeviceTypes;
    if (typeof mainParentData?.scenarioData?.representation === 'string') {
      scenarioData = JSON.parse(mainParentData.scenarioData.representation) as Pv;
      setAzimuthValuesForCustomPV({
        azimuth: scenarioData.azimuth || 0,
        tilt: scenarioData.tilt || 0,
      });
    }
  }

  // mode
  useEffect(() => {
    const currentTab = EFormVariant[mode === 'basic' ? 'Express' : 'Advanced'];
    setActiveSettingsTab(currentTab);
  }, [mode]);

  return (
    <div className={s.container}>
      <FormAssetsParams
        hasErrorsRef={formHasErrorsRef}
        id="asset-settings"
        assetUuid={newAssetData.uuid}
        assetType={newAssetData.type}
        formVariant={activeSettingsTab}
        // onSubmit={onSettingsSubmit}
        onSubmit={handleOnSubmit}
        currentValues={assetValues}
        disableLocationField={false}
        isCustomPV={newAssetData.type === 'PV'}
        azimuthValuesForCustomPV={
          newAssetData.tilt !== undefined && newAssetData.azimuth !== undefined
            ? { tilt: newAssetData.tilt, azimuth: newAssetData.azimuth }
            : azimuthValuesForCustomPVInitialState
        }
        setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
        isEdit={true}
        isSCM={true}
        theme="dark"
      />

      <p className={s.hint}>
        Note: To get correct results, please make sure that all entries in the date column match the
        selected market time slots
      </p>

      <button type="submit" form="asset-settings" className={s.saveSettings}>
        Save
      </button>
    </div>
  );
};
