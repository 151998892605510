import {
  CanaryRegistryList,
  TCanaryRegistryTabs,
  TCanaryRegistryUserTileProps,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  LibraryChooser,
  TLibraryChooserProps,
} from 'src/components/MapSidebarSCM/components/LibraryChooser';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TCanaryMode, TMapSidebarCanaryProps } from './MapSidebarCanary.types';
import {
  TCanaryUserData,
  TCanaryUsersAssetsRelations,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import {
  selectActiveConfigurationUuid,
  selectAssetByUuid,
  selectAssetValuesForUuid,
  selectAssetsTreeRelations,
  selectAssetsValues,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';

import { AssetCreateNew } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/';
import { AssetEdit } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetEdit';
import { AssetsView } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { CanaryUserInvite } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryUserInvite';
import { EUserRoles } from 'src/typings/base-types';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { LibraryOutput } from 'src/graphql';
import { TAssetsListWithSearchProps } from 'src/components/AssetsListWithSearch';
import { UserAssetPreview } from 'src/components/MapSidebarSCM/components/UserAssetPreview';
import classnames from 'classnames';
import s from './MapSidebarCanary.module.scss';
import { selectCanaryNetworkUsers } from 'src/redux/canaryNetwork/canaryNetwork.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAssetsData } from 'src/hooks/useAssetsData';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useSCMMemberEvents } from 'src/hooks/useSCMMemberEvents';
import { useSelector } from 'react-redux';

//const SCM_USER_ROLES = ['Exchange Operator', 'GSy User'];
type TSelectedItem = {
  data: LibraryOutput;
  children: LibraryOutput[];
};

export const MapSidebarCanary: React.FC<TMapSidebarCanaryProps> = ({
  onAssetValuesSave,
  onAssetValuesSavePromise,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  onAssetRemove,
}) => {
  // Ctx

  const dispatch = useAppDispatch();

  // Selectors
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const users = useSelector(selectCanaryNetworkUsers);
  const assetsValues = useSelector(selectAssetsValues);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  // State
  const [activeFilter, setActiveFilter] = useState<TAssetsListWithSearchProps['activeFilter']>(
    LIBRARY_FILTERS_MAPPING.Area,
  );
  const [assetView, setAssetView] = useState<boolean>(false);
  const [mode, setMode] = useState<TCanaryMode>('assetPreview');
  const [addAssetView, setAddAssetView] = useState<boolean>(false);
  const [createAssetView, setCreateAssetView] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [assetsList, setAssetsList] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<TSelectedItem | undefined>(undefined);
  const [selectedMember, setSelectedMember] = useState<
    TCanaryRegistryUserTileProps['data'] | undefined
  >(undefined);

  const [activeTab, setActiveTab] = useState<TCanaryRegistryTabs>('Community Members');

  const { assetsData } = useAssetsData();
  const assetDataRef = useRef(JSON.stringify(assetsData));
  const [selectedAsset, setSelectedAsset] = useState<string>();

  const selectedAssetDataByUuid = useSelector(selectAssetValuesForUuid(selectedAsset || ''));
  const selectedAssetByUuid = useSelector(selectAssetByUuid(selectedAsset || ''));

  const combinedEditData = useRef<null | Record<string, unknown>>(null);
  const registryWrapperRef = useRef<HTMLDivElement>(null);

  const [addWrapperTop, setAddWrapperTop] = useState(0);
  const simulationStatus = useSelector(selectSimulationStatus);

  const { zoomIntoConfiguration } = useConfigurationUtils();
  const { isAdminInspecting } = useIsAdminInspecting();
  // Added because of the Asset Edit component.
  // It was working with destructive data like {...bla, ...blabla}
  // because of the enum selector which is in the formfieldgenerator, It caused many time re-render
  // so I added combined useRef and I handle the render cycle with this state
  const [, setForceRender] = useState<boolean>(false);

  const { handleReadSCMConfiguration } = useSCMMemberEvents({});

  const assetsTree = useSelector(selectAssetsTreeRelations);

  useEffect(() => {
    if (assetDataRef.current === JSON.stringify(assetsData)) return;

    if (selectedItem) {
      assetDataRef.current = JSON.stringify(assetsData);
      const newSelectedItem = assetsData.find((asset) => asset.uuid === selectedItem.data.uuid);

      const getParentUuid = (uuid: string) =>
        Object.keys(assetsTree).find((key) => assetsTree[key].includes(uuid));
      const children = assetsData.filter(
        (item) => getParentUuid(item.uuid) === selectedItem.data.uuid,
      );

      if (newSelectedItem) {
        setSelectedItem((prev) => {
          if (!prev) return prev;
          return {
            data: prev?.data,
            children: children ? children : [],
          };
        });
      }
    }
  }, [selectedItem, assetsData, assetsTree]);

  const usersData = useMemo<TCanaryUserData[]>(() => {
    return users.map((user) => ({
      uuid: `${user.id}`,
      name: user.email,
      subtitle: user.email,
      avatarUrl: user.profilePicture,
      requestStatus: user.requestStatus,
      canaryInvitations: user.canaryInvitations,
      isAggregator: user.userRole === 'Aggregator',
      isGridOperator: user.userRole === 'DSO',
      aggregatorInformation: user.aggregatorInformation,
    }));
  }, [users]);

  const objChangeCheck = useCallback<
    (obj: Record<string, unknown>, compareObj: Record<string, unknown>) => boolean
  >((obj, compareObj) => {
    const compareKeys = Object.keys(compareObj);
    for (let i = 0; i < compareKeys.length; i++) {
      if (obj[compareKeys[i]] === undefined || obj[compareKeys[i]] !== compareObj[compareKeys[i]])
        return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const tempCombined = {
      ...selectedAssetByUuid,
      ...selectedAssetDataByUuid,
    };
    if (!Object.values(tempCombined).length) return;

    if (
      combinedEditData.current === null ||
      objChangeCheck(combinedEditData.current, tempCombined)
    ) {
      combinedEditData.current = tempCombined;
      setForceRender((prev) => !prev);
    }
  }, [selectedAssetDataByUuid, selectedAssetByUuid, combinedEditData, objChangeCheck]);

  const onUpdate = () => {
    setSelectedItem(undefined);
  };

  const usersToAssetRelations = useMemo<TCanaryUsersAssetsRelations>(() => {
    const relations = {};
    users.forEach((user) => {
      relations[user.email] = {};
      user.assetsConfigured.forEach((asset) => {
        relations[user.email][asset.uuid] = asset.registrationStatus;
      });
    });
    return relations;
  }, [users]);

  useEffect(() => {
    if (mode == 'addNewAsset') setAssetView(false);
  }, [mode]);

  const clickOnAllMembers = useCallback(() => {
    toggleAssetView();
    setAssetsList([]);
    setSelectedMember(undefined);
    setAddAssetView(false);
    dispatch(setSelectedAssetUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
    if (configUuid)
      handleReadSCMConfiguration({
        variables: {
          uuid: configUuid,
        },
      });
  }, [
    setAssetsList,
    setSelectedMember,
    setAddAssetView,
    dispatch,
    zoomIntoConfiguration,
    configUuid,
    handleReadSCMConfiguration,
    assetsValues,
  ]);

  const toggleAssetView = () => {
    setAssetView((prevState) => !prevState);
  };

  const toggleAddAssetView = () => {
    setAddAssetView((prevState) => !prevState);
  };

  const handleLibraryChoose: TLibraryChooserProps['onLibraryChoose'] = (library) => {
    const libraryRep = library?.scenarioData?.representation?.serialized
      ? JSON.parse(library?.scenarioData?.representation?.serialized)
      : null;
    const selectedItemData = {
      data: { ...library, name: library.title } as LibraryOutput,
      children: libraryRep?.children,
    };
    setSelectedItem(selectedItemData);
    setCreateAssetView(true);
  };

  const HeaderWithTitleAndClose = ({ title, onClose }) => (
    <div className={s.headerContainer}>
      <div className={s.innerHeader}>
        <h1>{title}</h1>
        <BaseButtonSquare
          size="2"
          svgSize="3"
          icon="close"
          theme="flat-gray-dark"
          onClick={onClose}
        />
      </div>
    </div>
  );

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setAddWrapperTop(scrollTop);
  };

  useEffect(() => {
    if (!registryWrapperRef || !registryWrapperRef.current) return;
    registryWrapperRef.current.removeEventListener('scroll', handleScroll);
    //const relatedListener = registryWrapperRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!registryWrapperRef || !registryWrapperRef.current) return;
      // remove the event listener with the same reference
      // eslint-disable-next-line react-hooks/exhaustive-deps
      registryWrapperRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [registryWrapperRef]);

  const renderSidebarContent = () => {
    if (mode == 'addNewAsset') {
      return (
        <AssetCreateNew
          data={selectedItem}
          selectedAsset={selectedAsset}
          setMode={setMode}
          onAddNewAsset={onAddNewAsset}
          onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
          setSelectedAsset={setSelectedAsset}
          onUpdate={onUpdate}
          setSelectedItem={setSelectedItem}
        />
      );
    } else if (mode == 'editAsset') {
      if (!combinedEditData.current) return null;
      return (
        <AssetEdit
          key={(() => Object.values(combinedEditData.current).join('-'))()}
          data={combinedEditData.current}
          setMode={setMode}
          onAssetValuesSave={onAssetValuesSave}
        />
      );
    } else if (selectedItem) {
      return (
        <>
          {createAssetView && (
            <div className={s.createAssetContainer}>
              <HeaderWithTitleAndClose
                title={'Create Assets'}
                onClose={() => setSelectedItem(undefined)}
              />
            </div>
          )}
          <UserAssetPreview
            key={`userAssetPreview${selectedItem?.data?.uuid}`}
            selectedItem={{ ...selectedItem }}
            backToDashboard={() => setSelectedItem(undefined)}
            onRemove={() => {
              setSelectedItem(undefined);
              setSelectedMember(undefined);
              setAddAssetView(false);
              setAssetView(false);
              dispatch(setSelectedAssetUuid(undefined));
              zoomIntoConfiguration({ assetsValues });
            }}
            selectedAsset={undefined}
            handleSetMode={(mode: TCanaryMode) => {
              setAssetView(false);
              setMode(mode);
              //setSelectedItem(undefined);
            }}
            setSelectedAsset={setSelectedAsset}
            onAssetValuesSave={onAssetValuesSave}
            onAssetValuesSavePromise={onAssetValuesSavePromise}
            isCreateAssetView={createAssetView}
            setAssetsList={setAssetsList}
          />
        </>
      );
    } else if (assetView) {
      return (
        <>
          <AssetsView
            key={`assetsView${selectedMember?.uuid}${assetsList?.data?.uuid}`}
            selectedMember={selectedMember}
            assetsList={assetsList}
            onAddAssetClick={() => {
              if (assetsList?.data?.uuid) dispatch(setSelectedAssetUuid(assetsList?.data?.uuid));

              //toggleAssetView();
              //toggleAddAssetView();
              setMode('addNewAsset');
            }}
            onAssetClick={(data) => {
              setSelectedItem(data);
            }}
            toggleAssetView={toggleAssetView}
            clickOnAllMembers={clickOnAllMembers}
          />
        </>
      );
    } else if (addAssetView) {
      return (
        <div className={s.addAssetContainer}>
          <HeaderWithTitleAndClose title={'Add Assets'} onClose={toggleAddAssetView} />
          <div className={s.heading}>What asset you want to add?</div>
          <div className={s.description}>
            You can add based on any asset we have or create a custom one, your choice.
          </div>
          <LibraryChooser
            paddingClassName={s.paddingLR}
            disabledFilters={undefined}
            isAllButtonRequiredInFilter={false}
            onLibraryChoose={handleLibraryChoose}
            activeFilter={activeFilter}
            onFilterChange={(v) => setActiveFilter(v.value)}
            theme="light"
          />
        </div>
      );
    } else {
      return (
        <>
          {((userRole === EUserRoles.Admin && !isAdminInspecting) ||
            userRole === EUserRoles.ExchangeOperator) && (
            <div
              className={classnames({
                [s.disableContainer]: simulationStatus == 'running' && !isOperationCommunity,
              })}>
              <CanaryUserInvite
                setSelectedMember={setSelectedMember}
                setAssetView={setAssetView}
                wrapperTop={addWrapperTop}
              />
            </div>
          )}
          <CanaryRegistryList
            assetsData={assetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
            setAssetView={setAssetView}
            setAddAssetView={setAddAssetView}
            setAssetsList={setAssetsList}
            onAssetRemove={onAssetRemove}
            setSelectedMember={setSelectedMember}
            invitationEmails={[]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            wrapperTop={addWrapperTop}
          />
          {/* <div className={s.formButtonsWrapper}>
            <BaseButton type="submit" className={s.formButton} onClick={inviteUsers}>
              Save
            </BaseButton>
          </div> */}
        </>
      );
    }
  };

  return (
    <div className={s.registry} ref={registryWrapperRef}>
      <div className={s.container}>
        {/* {invitationEmails.length ? (
        <CanaryInvitationPanel emails={invitationEmails} onCancel={() => setInvitationEmails([])} />
      ) : ( */}
        {renderSidebarContent()}
        {/* )} */}
      </div>
    </div>
  );
};
