import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import {
  CommunityEnergyProfileInfoText,
  SCMMyCommunityNotification,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity/SCMMyCommunityConstants';
import React, { useCallback, useEffect } from 'react';
import {
  selectCommunityBillsDifference,
  selectCommunityKpi,
  selectCommunityKpiDifference,
  selectDataResolution,
  selectIsOperationalCommunity,
} from 'src/redux/scm/scm.selectors';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  selectSettingsData,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  setCommunityBillsDifferences,
  setCommunityKpi,
  setCommunityKpiDifference,
} from 'src/redux/scm/scm.slice';

import { CustomResultHeader } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/DownloadResultsButton';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EScreenMode } from 'src/typings/configuration.types';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { LiteWorldMap } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity/LiteWorldMap';
import { TKpi } from 'src/typings/simulation.types';
import { TSCMMyCommunityProps } from './SCMMyCommunity.types';
import { TableSCMBills } from 'src/components/_charts/TableSCMBills';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './SCMMyCommunity.module.scss';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAccumulatedResultsWithSubscription } from 'src/hooks/useAccumulatedResultsWithSubscription';
import { useAppDispatch } from 'src/redux/store';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSelector } from 'react-redux';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import { useTranslation } from 'react-i18next';

export const SCMMyCommunity: React.FC<TSCMMyCommunityProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const settingsData = useSelector(selectSettingsData);
  const communityKpi = useSelector(selectCommunityKpi);
  const communityKpiDifference = useSelector(selectCommunityKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const communityBillsDifferences = useSelector(selectCommunityBillsDifference);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const simulationStatus = useSelector(selectSimulationStatus);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
    handleResolutionButton,
  } = useAccumulatedResults({
    initialRunQueries:
      //simulationStatus === 'running' && isOperationCommunity
      //  ? []
      //  :
      ['getAccumulatedResults', 'getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
    mode: 'community',
  });

  useSingleNotification({
    singleNotification: SCMMyCommunityNotification,
    checkStatement: !isOperationCommunity && !isUserACommunityMember && isOwnerCM,
  });

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  useAccumulatedResultsWithSubscription({
    mode: 'community',
  });

  useEffect(() => {
    if (isOperationCommunity && simulationStatus === 'running') {
    }
  }, [isOperationCommunity, simulationStatus]);

  useEffect(() => {
    if (resultsData?.kpi && resultsData?.billsEnergy)
      dispatch(
        setCommunityKpi(
          pickKpiData({
            kpi: JSON.parse(resultsData?.kpi),
            billsEnergy: JSON.parse(resultsData?.billsEnergy),
          }),
        ),
      );
  }, [resultsData, dispatch]);

  //create a useEffect to dispatch the setCommunityBillsDifferences
  useEffect(() => {
    if (billsDifferenceData) {
      dispatch(setCommunityBillsDifferences(objectCamelCase(JSON.parse(billsDifferenceData))));
    }
  }, [billsDifferenceData, dispatch]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    if (kpiDifferenceData) {
      dispatch(setCommunityKpiDifference(objectCamelCase(JSON.parse(kpiDifferenceData))));
    }
  }, [kpiDifferenceData, dispatch]);

  const onBillClick = useCallback(
    (uuid: string) => {
      //if (isOperationCommunity) return;
      dispatch(setSidebarExpanded(true));
      dispatch(setSelectedAssetUuid(uuid));
    },
    [
      //isOperationCommunity,
      dispatch,
    ],
  );

  if (!sidebarExpanded) return null;

  return (
    <>
      <div className={s.rowWrapper}>
        <CustomResultHeader
          handleResolutionButton={handleResolutionButton}
          selectedResolution={dataResolution}
          isHomeNameActive={false}
          isCommunityLabelActive={!isUserACommunityMember}
          flag={isOperationCommunity ? 'Operation' : 'Simulation'}
        />
        {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
          <div className={s.downloadResultContainer}>
            <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
          </div>
        )}
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          <KeyResultsSCM
            horizontal={false}
            mode={'Community'}
            currency={settingsData.currency}
            title=""
            communitySavings={communityBillsDifferences?.savings}
            communitySelfSufficiency={(communityKpiDifference?.selfSufficiency || 0) * 100}
          />
        </div>
        <div className={s.rightColumn}>
          {isUserACommunityMember && screenMode === EScreenMode.Desktop && (
            <LiteWorldMap className={s.worldMap} />
          )}
          {!isUserACommunityMember && <TableSCMBills onBillClick={onBillClick} />}
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          {communityKpiDifference && (
            <div className={s.selfSufficiencyWrapper}>
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(communityKpiDifference)}
                type={EChartSelfType.Sufficiency}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={t(SelfSufficiencyInfoText) || ''}
              />
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(communityKpiDifference)}
                type={EChartSelfType.Consumption}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={t(SelfConsumptionInfoText) || ''}
              />
            </div>
          )}
        </div>
        <div className={s.rightColumn}>
          <HomeEnergyInfo
            generatedValue={`${
              communityKpiDifference?.totalEnergyProducedWh
                ? (communityKpiDifference?.totalEnergyProducedWh / 1000).toFixed(0)
                : 0
            } kWh`}
            //consumedValue={`${homeBillsDifferences?.homeBalanceKWh.toFixed(0)} kWh`}
            consumedValue={`${
              communityKpiDifference?.totalEnergyDemandedWh
                ? (communityKpiDifference?.totalEnergyDemandedWh / 1000).toFixed(0)
                : 0
            } kWh`}
          />
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.fullColumn}>
          <EnergyProfileComponent
            title={t('COMMUNITY_ENERGY_PROFILE')}
            mode={dataResolution}
            field="community"
            initialData={communityKpi || []}
            info={t(CommunityEnergyProfileInfoText) || ''}
          />
        </div>
      </div>
    </>
  );
};
