import GraphImage from 'src/assets/images/MarketingPage/graph.png';

interface ICustomerConfig {
  srcLogo: string;
  color: string;
}

export const getCustomerSettings = (scmGroupSettingsName: string): ICustomerConfig | null => {
  switch (scmGroupSettingsName) {
    case 'DEFAULT_STATIC':
    case 'DEFAULT_DYNAMIC':
    case 'VERGY':
    case 'TARRAGONA':
    case 'ENGIE_HEMERA':
      return {
        srcLogo: GraphImage, // import image of the logo and set here
        color: "#FFF"
      }

    default:
      return null;
  }
}
