import gql from 'graphql-tag';

export const ScmAccumulatedBillsDifferencesCommunity = gql`
  subscription scmAccumulatedBillsDifferencesCommunity(
    $jobId: String!
    $areaUUID: String!
    $startTime: DateTime!
  ) {
    scmAccumulatedBillsDifferencesCommunity(
      jobId: $jobId
      areaUUID: $areaUUID
      startTime: $startTime
    )
  }
`;
