import { ELsKey, ls } from 'src/utils/localStorage';
import React, { useMemo } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { TSupportedLanguages } from 'src/components/Header/LanguageSelect/LanguageSelect.types';
import classNames from 'classnames';
import { languagesList } from 'src/components/Header/LanguageSelect/LanguageSelectConstants';
import s from './LanguageSelect.module.scss';
import { selectLanguage } from 'src/redux/application/application.selectors';
import { setLanguage } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LanguageSelect: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  // use the useSelect hook to get the language state from the store
  const language = useSelector(selectLanguage);

  // use the useAppDispatch hook to get the dispatch function from the store
  const dispatch = useAppDispatch();

  // create a function to dispatch the setLanguage action
  const onClickButton = (language: TSupportedLanguages) => {
    ls.set(ELsKey.LANGUAGE, language);
    dispatch(setLanguage(language));
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  const activeLanguageInfo = useMemo(
    () => languagesList.find((languageInfo) => languageInfo.code === language) || languagesList[0],
    [language],
  );

  // create return with a dropdown without the select element
  return (
    <>
      <div className={s.languageLabel} onClick={() => setIsOpen((prev) => !prev)}>
        <span className={s.code}>{language ? language : activeLanguageInfo.code}</span>
        <span className={s.labelText}>{t('LANGUAGE')}</span>
        <BaseIcon icon="chevron-down" style={{ display: 'flex' }} size={15} />
      </div>
      {isOpen && (
        <div className={s.languageSelectContainer}>
          {languagesList.map((languageInfo) => (
            <button
              key={languageInfo.code}
              type="button"
              className={classNames(s.selectButton, {
                [s.active]: activeLanguageInfo.code === languageInfo.code,
              })}
              onClick={() => onClickButton(languageInfo.code)}>
              <span className={s.code}>{languageInfo.code}</span>
              <span>{languageInfo.name}</span>
            </button>
          ))}
        </div>
      )}
    </>
  );
};
