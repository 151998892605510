import { SettingsInput, SpotMarketType } from 'src/graphql';

import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';

export function prepareSettingsInput(
  settingsData: TSettingsData,
  isSCMFlow: boolean,
): SettingsInput {
  const {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    scmGroupSettingsName,
    startDate,
    endDate,
    spotMarketType,
    bidOfferMatchAlgo,
    gridFeeType,
    currency,
  } = settingsData;

  const output: SettingsInput = {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    scmGroupSettingsName,
    startDate,
    endDate,
    spotMarketType,
    ...((spotMarketType === SpotMarketType.TwoSided || isSCMFlow) && { bidOfferMatchAlgo }),
    gridFeeType,
    currency,
  };

  return output;
}
