import React, { useMemo } from 'react';

import { ESCMGroupSettingsName } from 'src/utils/assetsFields/assetsFields.types';
import { getCustomerSettings } from 'src/utils/customerSettings';
import s from './TableSCMBills.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useSelector } from 'react-redux';

export const TableSCMBillsHeader: React.FC = () => {
  const { scmGroupSettingsName } = useSelector(selectSettingsData);

  /**
   * For instance this below would load the configurations including logo, color and etc
   * according to scmGroupSettingsName
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectCustomerConfig = getCustomerSettings(scmGroupSettingsName);

  const isVergy = useMemo(() => scmGroupSettingsName === ESCMGroupSettingsName.VERGY, [
    scmGroupSettingsName,
  ]);
  return (
    <thead>
      <tr className={s.darkTableHeader}>
        <th style={{ width: '20%' }}></th>
        <th className={s.rightBorder} colSpan={3}>
          Bought from Grid
        </th>
        <th className={s.rightBorder} colSpan={2}>
          Sold to Grid
        </th>
        {isVergy ? (
          <> </>
        ) : (
          <>
            <th className={s.rightBorder} colSpan={2}>
              Bought from Community
            </th>
            <th className={s.rightBorder} colSpan={2}>
              Sold to Community
            </th>
          </>
        )}
        <th colSpan={2}>Total Balance</th>
      </tr>
      <tr className={s.columnTitlesHeader}>
        <th className={s.textLeft}>Asset</th>
        <th className={s.greyBackground}>Energy (kWh)</th>
        <th colSpan={2}>Paid (€)</th>
        <th className={s.greyBackground}>Energy (kWh)</th>
        <th>Revenue (€)</th>
        {isVergy ? (
          <></>
        ) : (
          <>
            <th className={s.greyBackground}>Energy (kWh)</th>
            <th>Paid (€)</th>
            <th className={s.greyBackground}>Energy (kWh)</th>
            <th>Revenue (€)</th>
          </>
        )}

        <th className={s.greyBackground}>Energy (kWh)</th>
        <th>Total (€)</th>
      </tr>
      <tr className={s.informationsHeader}>
        <th></th>
        <th></th>
        <th className={s.informationCell}>Trade Cost (€)</th>
        <th className={s.informationCell}>Grid Fees (€)</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};
