import React, { useEffect } from 'react';
import {
  TCanaryRegistryListHeaderProps,
  TCanaryRegistryTabs,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';

import { BaseButton } from 'src/components/BaseButton';
import { BaseInput } from 'src/components/BaseInput';
import { EUserRoles } from 'src/typings/base-types';
import classNames from 'classnames';
import s from './CanaryRegistryList.module.scss';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useSelector } from 'react-redux';

const TABS: TCanaryRegistryTabs[] = ['Community Members', 'Community Assets'];
//const FILTERS: TCanaryRegistryListFilters[] = ['All', 'Unregistered', 'Pending', 'Registered'];

export const CanaryRegistryListHeader: React.FC<TCanaryRegistryListHeaderProps> = ({
  // activeFilter,
  // setActiveFilter,
  setActiveTab,
  activeTab,
  searchValue,
  setSearchValue,
  // selectAllItems,
  // isSelectAllTriggered,
  showApplyButton,
  onClickApplyButton,
  // assetsCount,
  // membersCount,
}) => {
  const userRole = useSelector(selectUserRole);
  const { isAdminInspecting, isOwnerAdmin } = useIsAdminInspecting();

  useEffect(() => {
    if (isAdminInspecting && !isOwnerAdmin) setActiveTab('Community Assets');
  }, [isAdminInspecting, isOwnerAdmin, setActiveTab]);

  const tabs =
    userRole && [EUserRoles.Researcher, EUserRoles.DSO].includes(userRole)
      ? [TABS[0]]
      : isAdminInspecting && !isOwnerAdmin
      ? [TABS[1]]
      : TABS;

  const getTabTitle = (tabName: TCanaryRegistryTabs): string => {
    if (userRole === EUserRoles.Researcher) return 'Canary Assets';
    if (userRole === EUserRoles.DSO) return 'Community Assets';
    return tabName;
  };

  /*const setActiveFilterHandler = (title) => {
    if (title === activeFilter) {
      setActiveFilter('All');
    } else {
      setActiveFilter(title);
    }
  };*/

  return (
    <header className={s.listHeader}>
      <div className={s.tabsSelecor}>
        {tabs.map((item, i) => (
          <button
            key={i}
            type="button"
            onClick={() => {
              setActiveTab(item);
            }}
            className={classNames({
              [s.active]: activeTab === item,
            })}>
            {getTabTitle(item)}
          </button>
        ))}
      </div>
      <div className={s.searchAndSelect}>
        <BaseInput
          type="text"
          name="search"
          className={s.input}
          inputHeight="2"
          theme="filled-gray"
          placeholder="Search"
          value={searchValue}
          onChange={({ value }) => setSearchValue(`${value}`)}
        />
        {showApplyButton && (
          <BaseButton size="mini" theme="primary" onClick={onClickApplyButton}>
            Apply
          </BaseButton>
        )}
      </div>
    </header>
  );
};
