import { TCanaryRegistryUserTileProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import { TInvitationUserItem } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/MapSidebarCanary.types';

export type TAssetsViewProps = {
  className?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetsList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAssetClick?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddAssetClick?: (value: any) => void;
  selectedMember?: TCanaryRegistryUserTileProps['data'];
  onChangeNewUser?: (data: TInvitationUserItem) => void;
  clickOnAllMembers?: () => void;
  toggleAssetView?: () => void;
};

export enum NewUserRole {
  Member = 'Member',
  Manager = 'Manager',
}

export type TAssetsViewUserTileProps = {
  uuid: string;
  name: string;
  avatarUrl?: string;
  email: string;
  isAdmin: boolean;
  address?: string;
  isNewUser: boolean;
  zipCode?: string;
  onChangeNewUser?: (data: TInvitationUserItem) => void;
  toggleAssetView?: () => void;
};

export type TUserChangeableInfo = Pick<
  TAssetsViewUserTileProps,
  'name' | 'email' | 'address' | 'zipCode'
>;

export type TUserTileShowDataProps = TUserChangeableInfo & {
  setFormData: React.Dispatch<React.SetStateAction<TUserChangeableInfo>>;
  onSubmit: () => void;
};

export type TFormErrorAreas = Partial<Record<keyof TFormDataState, string>>;

export type TUserTileFormProps = TUserChangeableInfo & {
  setFormData: React.Dispatch<React.SetStateAction<TUserChangeableInfo>>;
  formErrorAreas: TFormErrorAreas;
  isNewUser: boolean;
};
export type TFormDataState = TUserChangeableInfo;
