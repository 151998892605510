import gql from 'graphql-tag';

export const ScmProfileResultsStats = gql`
  subscription scmProfileResults($jobId: String!, $areaUUID: String!, $startTime: DateTime!) {
    scmProfileResults(jobId: $jobId, areaUUID: $areaUUID, startTime: $startTime) {
      kpi
      billsEnergy
    }
  }
`;
