import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import {
  EnergyBillComponents,
  EnergyBillSubtitles,
  TListEnergyBillInfo,
} from 'src/components/EnergyBillComponents';
import {
  EnergyBillInfoContent,
  MyEnergyProfileInfoText,
  SCMMyHomeNotification,
  SavingsBenchmarkInfoText,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome/SCMMyHomeConstants';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  TBillsDifference,
  TSCMHomeKpi,
  TSCMMyHomeProps,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome/SCMMyHome.types';
import {
  selectAssetsValues,
  selectSelectedAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectDataResolution,
  selectHomeBillsDifferences,
  selectHomeKpi,
  selectIsOperationalCommunity,
  selectKpiDifference,
} from 'src/redux/scm/scm.selectors';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  setHomeBillsDifferences,
  setHomeKpi,
  setIsAppLoading,
  setKpiDifference,
} from 'src/redux/scm/scm.slice';

import { CustomResultHeader } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/DownloadResultsButton';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EScreenMode } from 'src/typings/configuration.types';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultProgress } from 'src/components/KeyResultsSCM/components/KeyResultProgress';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { TKpi } from 'src/typings/simulation.types';
import classNames from 'classnames';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './SCMMyHome.module.scss';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAccumulatedResultsWithSubscription } from 'src/hooks/useAccumulatedResultsWithSubscription';
import { useAppDispatch } from 'src/redux/store';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSelector } from 'react-redux';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import { useTranslation } from 'react-i18next';
import { whToKwh } from 'src/utils/whToKwh';

export const SCMMyHome: React.FC<TSCMMyHomeProps> = () => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const homeBillsDifferences = useSelector(selectHomeBillsDifferences);
  const settingsData = useSelector(selectSettingsData);
  const homeKpi = useSelector(selectHomeKpi);
  const kpiDifference = useSelector(selectKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const assetsValues = useSelector(selectAssetsValues);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
    handleResolutionButton,
  } = useAccumulatedResults({
    initialRunQueries:
      //isOperationalCommunity
      //? []
      //:
      ['getAccumulatedResults', 'getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
    mode: 'home',
  });

  useAccumulatedResultsWithSubscription({
    mode: 'home',
  });

  useSingleNotification({
    singleNotification: SCMMyHomeNotification,
    checkStatement: !isOperationalCommunity && isOwnerCM,
  });

  /*const energyFlowProps = useMemo<TEnergyFlowProps | null>(() => {
    if (!kpiDifference || !kpiDifference.assetEnergyRequirementsKWh || !assets || !assetsValues)
      return null;

    // get keys of the assetEnergyRequirementsKWh object
    const keys = Object.keys(kpiDifference.assetEnergyRequirementsKWh);

    // check the keys are exists in the assets and assetsValues
    if (!keys.every((key) => assets[key] && assetsValues[key])) return null;

    // iterate over the keys and create an array of objects with the data
    const data = keys.map((key) => {
      const value = kpiDifference.assetEnergyRequirementsKWh[key];
      return {
        title: assetsValues[key].name || '',
        // keep 2 decimals
        value: Math.abs(Math.abs(value) < 10 ? Math.floor(value * 100) / 100 : Math.floor(value)),
        flowType: (value < 0 ? 'Import' : 'Export') as ArrayElement<
          TEnergyFlowProps['data']
        >['flowType'],
        assetType: assets[key].type as TEnergyFlowAssetType,
      };
    });

    return {
      data,
      netTotalEnergy: Math.floor(kpiDifference.areaEnergyRequirementsKWh * 100) / 100,
    };
  }, [kpiDifference, assets, assetsValues]);*/

  const currencySymbol = formatter.getCurrencySymbol(settingsData.currency);
  const withCurrency = useCallback<(value: number) => string>(
    (value: number) => {
      return `${currencySymbol}${
        value > 10 ? Math.round(value) : value.toFixed(2).replace('.', ',')
      }`;
    },
    [currencySymbol],
  );

  const listEnergyBillInfo = useMemo<TListEnergyBillInfo | null>(() => {
    if (!homeBillsDifferences) return null;

    const {
      assistanceFee,
      assistanceFeePercent,
      fixedFee,
      gsyEnergyBillExclRevenueWithoutFees,
      gsyEnergyBillExclRevenueWithoutFeesPercent,
      marketplaceFee,
      marketplaceFeePercent,
    } = homeBillsDifferences;

    // get human readable percantage from 0.XXX format to from 0 to 100
    const getPercentage = function (value: number) {
      return Math.floor(value * 100);
    };

    return [
      {
        labelColor: '#66EE66',
        label: translate('ENERGY_COST'),
        percentage: getPercentage(gsyEnergyBillExclRevenueWithoutFeesPercent),
        cost: withCurrency(gsyEnergyBillExclRevenueWithoutFees),
      },
      {
        labelColor: '#1641F7',
        label: translate('SERVICE_FEE'),
        percentage: getPercentage(fixedFee),
        cost: withCurrency(fixedFee),
      },
      {
        labelColor: '#FC1355',
        label: translate('ASSISTANCE_FEE'),
        percentage: getPercentage(assistanceFeePercent),
        cost: withCurrency(assistanceFee),
      },
      {
        labelColor: '#80B0F8',
        label: translate('MONTHLY_FEE'),
        percentage: getPercentage(marketplaceFeePercent),
        cost: withCurrency(marketplaceFee),
      },
      // NOTE: TAXES and SURCHARGES are removed
    ];
  }, [homeBillsDifferences, withCurrency, translate]);
  //useAccumulatedResultsWithSubscription({ initialRunQueries: [], mode: 'home' });

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  // unset the homebillsdifferences, homekpi, kpidifference when unmounting the component
  useEffect(() => {
    return () => {
      dispatch(setHomeBillsDifferences(undefined));
      dispatch(setHomeKpi(undefined));
      dispatch(setKpiDifference(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (billsDifferenceData)
      dispatch(
        setHomeBillsDifferences(objectCamelCase<TBillsDifference>(JSON.parse(billsDifferenceData))),
      );
  }, [billsDifferenceData, dispatch]);

  useEffect(() => {
    if (resultsData?.kpi && resultsData?.billsEnergy)
      dispatch(
        setHomeKpi(
          pickKpiData({
            kpi: JSON.parse(resultsData?.kpi),
            billsEnergy: JSON.parse(resultsData?.billsEnergy),
          }),
        ),
      );
    if (!isOperationalCommunity && resultsData?.kpi) {
      dispatch(setIsAppLoading(false));
    }
  }, [resultsData, dispatch, isOperationalCommunity]);

  useEffect(() => {
    if (kpiDifferenceData)
      dispatch(setKpiDifference(objectCamelCase<TSCMHomeKpi>(JSON.parse(kpiDifferenceData))));
  }, [kpiDifferenceData, dispatch]);

  if (!sidebarExpanded) return null;

  return (
    <>
      <div className={s.rowWrapper}>
        <CustomResultHeader
          handleResolutionButton={handleResolutionButton}
          selectedResolution={dataResolution}
          isHomeNameActive={!isUserACommunityMember}
          isCommunityLabelActive={false}
          flag={isOperationalCommunity ? 'Operation' : 'Simulation'}
          homeName={selectedAssetUuid ? assetsValues[selectedAssetUuid]?.name : ''}
        />
        {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
          <div className={s.downloadResultContainer}>
            <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
          </div>
        )}
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          <KeyResultsSCM
            horizontal={true}
            mode={'Home'}
            currency={settingsData.currency}
            homeBillValue={homeBillsDifferences?.gsyEnergyBill}
            homeSavings={homeBillsDifferences?.savingsVergy}
            totalBenefit={homeBillsDifferences?.gsyTotalBenefit}
            title={`⚡ ${translate('ENERGY_BILL')}`}
          />
        </div>
        <div className={s.rightColumn}>
          <HomeEnergyInfo
            generatedValue={`${
              kpiDifference?.totalEnergyProducedWh
                ? whToKwh(kpiDifference?.totalEnergyProducedWh)
                : 0
            } kWh`}
            //consumedValue={`${homeBillsDifferences?.homeBalanceKWh.toFixed(0)} kWh`}
            consumedValue={`${
              kpiDifference?.totalEnergyDemandedWh
                ? whToKwh(kpiDifference?.totalEnergyDemandedWh)
                : 0
            } kWh`}
          />
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          {listEnergyBillInfo && (
            <EnergyBillComponents
              key={listEnergyBillInfo.map((item) => item.cost).join('')}
              list={listEnergyBillInfo}
              total={
                homeBillsDifferences?.gsyEnergyBillExclRevenue
                  ? withCurrency(homeBillsDifferences.gsyEnergyBillExclRevenue)
                  : ''
              }
              info={<EnergyBillInfoContent />}
              title={`${translate('ENERGY_BILL_COMPONENTS')}`}
              subTitle={translate(EnergyBillSubtitles[dataResolution]) || ''}
            />
          )}
        </div>
        <div className={classNames(s.rightColumn, s.selfandprogress)}>
          {kpiDifference && (
            <div className={s.selfSufficiencyWrapper}>
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Sufficiency}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={translate(SelfSufficiencyInfoText) || ''}
                isCommaSeparated={true}
              />
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Consumption}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={translate(SelfConsumptionInfoText) || ''}
                isCommaSeparated={true}
              />
            </div>
          )}
          {homeBillsDifferences && (
            <KeyResultProgress
              title={translate('MY_ENERGY_SAVINGS_RANKING_IN_COMMUNITY')}
              info={translate(SavingsBenchmarkInfoText) || ''}
              percentage={parseInt((homeBillsDifferences.energyBenchmark * 100).toFixed(0))}
              badges={[]}
              className={s.savingsRanking}
            />
          )}
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.rightColumn}>
          {homeKpi && (
            <EnergyProfileComponent
              title={translate('MY_ENERGY_PROFILE')}
              info={translate(MyEnergyProfileInfoText) || ''}
              mode={dataResolution}
              initialData={homeKpi}
              field="home"
            />
          )}
        </div>
        {/*energyFlowProps && !isUserACommunityMember && (
          <div className={s.leftColumn}>
            <EnergyFlow
              showSwitcher={false}
              title={translate('MY_ENERGY_FLOWS') || ''}
              info={MyEnergyFlowsInfoText}
              wrapperClassNames={{
                className: s.energyFlowWrapper,
                headerClassName: s.energyFlowContent,
                contentClassName: s.energyFlowContent,
              }}
              {...energyFlowProps}
            />
          </div>
            )*/}
      </div>
    </>
  );
};
