import React, { CSSProperties, useMemo } from 'react';

import { EScreenOrientation } from 'src/typings/configuration.types';
import { TMobileLineChartProps } from 'src/components/ChartEnergyProfile/ChartEnergyProfile.types';
import { selectScreenOrientation } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

export const MobileLineChartWrapper: React.FC<TMobileLineChartProps> = ({ children }) => {
  const screenOrientation = useSelector(selectScreenOrientation);

  const styleProps = useMemo(() => {
    if (screenOrientation === EScreenOrientation.Portrait) {
      // These are a square chartWrapper styles for the portrait mode
      return {
        width: `${window.innerWidth / 0.7 - 40}px`,
        height: `${window.innerWidth / 0.7 / 2}px`,
        position: 'absolute',
        scale: '0.7',
        left: `-${(window.innerWidth * 0.7) / 2 - 50}px`,
        top: `-${(window.innerWidth * 0.7) / 4 - 25}px`,
      };
    }

    // These are the chartWrapper styles for landscape mode
    return {
      width: `${window.innerWidth / 0.7 - 50}px`,
      height: `${window.innerHeight}px`,
      position: 'absolute',
      scale: '0.7',
      left: `-${window.innerWidth * 0.25 - 30}px`,
      top: '-50px',
    };
  }, [screenOrientation]);

  const containerHeight = useMemo(() => {
    if (screenOrientation === EScreenOrientation.Portrait) {
      return window.innerWidth / 1.4;
    }
    return window.innerHeight * 0.7;
  }, [screenOrientation]);

  return (
    <div style={{ width: 300, position: 'relative', height: containerHeight }}>
      <div style={styleProps as CSSProperties}>{children}</div>
    </div>
  );
};
