import React from 'react';
import { TEnergyBadgeProps } from './EnergyBadge.types';
import classNames from 'classnames';
import s from './EnergyBadge.module.scss';

export const EnergyBadge: React.FC<TEnergyBadgeProps> = ({
  icon,
  amount,
  title,
  onClick,
  size,
  className,
}) => (
  <div
    className={classNames(
      s.badge,
      className,
      size === 'S' && s.sizeS,
      size === 'M' && s.sizeM,
      onClick && s.clickable,
    )}
    onClick={() => onClick && onClick()}>
    {icon && icon}
    <span className={classNames(amount && s.titleWithAmount, icon && s.titleWithIcon)}>
      {title}
    </span>
    {amount && <span className={s.amount}>{amount}</span>}
  </div>
);
