import { AreaOutput, LibraryOutput } from 'src/graphql';

import { $Keys } from 'utility-types';
import { CSSProperties } from 'react';
import { TCanaryMode } from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TAssetListItemProps = {
  uuid: string;
  title: string;
  icon: TIconNames;
  isSelected: boolean;
  isSelectable: boolean;
  style?: CSSProperties;
  onClick?(): void;
  menuActive?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMenuClick?: any;
};

export type TAssetsListData = Omit<TAssetListItemProps, 'style'> & {
  children?: TAssetsListData[];
};

export type TExtendedOutput = LibraryOutput & Partial<Omit<AreaOutput, $Keys<LibraryOutput>>>;

export type TSelectedItem = {
  data: TExtendedOutput;
  children: TExtendedOutput[];
};

export type TUserAssetPreviewProps = {
  selectedItem: TSelectedItem;
  selectedAsset: string | undefined;
  handleSetMode: (mode: TCanaryMode) => void;
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
  backToDashboard?: () => void;
  onRemove?: () => void;
  menuActive?: TAssetListItemProps['menuActive'];
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  isCreateAssetView?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAssetsList?: (value: any) => void;
};

export enum EUserAssetPreviewTabs {
  Assets = 'Assets',
  Basic = 'Basic Settings',
  Advanced = 'Advanced Settings',
}
