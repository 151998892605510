import {
  ENotificationPlace,
  TSingleNotification,
} from 'src/redux/notifications/notifications.types';

export const SelfSufficiencyInfoText = 'COMMUNITY_SLEF_SUFFICIENCY_INFO_TEXT';

export const SelfConsumptionInfoText = 'COMMUNITY_SELF_CONSUMPTION_INFO_TEXT';

export const CommunityEnergyProfileInfoText = 'MY_ENERGY_PROFILE_COMMUNITY_INFO_TEXT';

export const SCMMyCommunityNotification: TSingleNotification = {
  notificationPlace: ENotificationPlace.HEADER_RUN_SIMULATION,
  characterIcon: '☝',
  description:
    'You can also rerun the same simulation or go back to change member or market settings and run a new simulation to optimise configuration ahead of moving to operation.',
};
