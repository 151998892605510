import { TSCMSimulationGraphData } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';

type TPickKpiDataArgs = {
  kpi: Array<{
    simulation_time: string;
    energy_demanded_wh: number;
    energy_produced_wh: number;
  }>;
  billsEnergy: Array<{
    simulation_time: string;
    sold_to_grid: number;
  }>;
};

export const pickKpiData = function (obj: TPickKpiDataArgs): TSCMSimulationGraphData {
  if (!obj) return [];

  return obj.kpi
    .filter((item) => item.simulation_time)
    .map((item) => {
      const sold: number =
        ((obj.billsEnergy.find((bill) => bill.simulation_time === item.simulation_time)
          ?.sold_to_grid || 0) as number) * 1000;

      return {
        time: item.simulation_time,
        consumption: item.energy_demanded_wh,
        generation: item.energy_produced_wh,
        sold: Math.round(sold * 100) / 100,
      };
    });
};
