import { TLanguageList } from 'src/components/Header/LanguageSelect/LanguageSelect.types';

// these constants are temporary until we have a proper translation system :))
export const languagesList: TLanguageList = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Español',
  },
];
