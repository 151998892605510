import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import {
  selectAssets,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigurationCenter,
} from 'src/redux/configuration/configuration.selectors';

import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { LiteSummaryPopup } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity/LiteSummaryPopup';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { WorldMapService } from 'src/services/map/map.service';
import { getPlaceTypeZoom } from 'src/utils/worldMap/helpers';
import s from './SCMMyCommunity.module.scss';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { setModalCommunitySummaryView } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

const MAP_SETTINGS: Partial<mapboxgl.MapboxOptions> = {
  center: [7, 48],
  zoom: 3,
  minPitch: 0,
  maxPitch: 50,
  style: process.env.REACT_APP_D3A_MAPBOX_STYLE,
};

export const LiteWorldMap: React.FC<TLiteWorldMapProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const mapboxRef = useRef<HTMLDivElement | null>(null);
  const [mapService, setMapService] = useState<WorldMapService | null>(null);
  const selectedLocation = useSelector(selectSelectedLocation);
  const assetsValues = useSelector(selectAssetsValues);
  const prevAssetsValues = useRef<TConfigurationState['assetsValues'] | null>(null);
  const assets = useSelector(selectAssets);
  const communityAsset = useSelector(selectCommunityAsset);
  const configurationCenter = useSelector(selectConfigurationCenter);

  useEffect(() => {
    if (mapboxRef.current && !mapService) {
      mapboxgl.accessToken = process.env.REACT_APP_D3A_MAPBOX_KEY as string;

      if (selectedLocation && selectedLocation.lng && selectedLocation.lat) {
        MAP_SETTINGS.center = [selectedLocation.lng, selectedLocation.lat];
        MAP_SETTINGS.zoom = getPlaceTypeZoom(
          (selectedLocation.place_type && selectedLocation.place_type[0]) || '',
        );
      }
      const service = new WorldMapService(mapboxRef.current, MAP_SETTINGS as MapboxOptions);
      setMapService(service);
    }
  }, [mapService, selectedLocation, setMapService]);

  useEffect(() => {
    if (!mapService || !assets || !assetsValues || !communityAsset || !configurationCenter) return;

    prevAssetsValues.current = assetsValues;

    mapService.threeboxController.setMaxZoomGlobalViz(0);
    mapService.threeboxController.setBuildMode(true);
    mapService?.threeboxController.setCommunityAssets(
      assets,
      assetsValues,
      communityAsset,
      configurationCenter,
    );
    if (configurationCenter) mapService.flyTo(configurationCenter);
    // mapService.map.setZoom(5);
    // mapService.set3DView(true);
  }, [assets, mapService, assetsValues, configurationCenter, communityAsset]);

  useEffect(() => {
    dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
  }, [dispatch]);

  return (
    <div className={className}>
      <div ref={mapboxRef} className={s.theMap} />
      {communityAsset && configurationCenter && mapService && mapboxRef.current && (
        <Popup
          lat={configurationCenter.lat}
          lng={configurationCenter.lng}
          className={s.popupMini}
          higherZIndex={true}
          mapServiceProp={mapService}
          mapboxClassNames={[s.popupMini]}>
          <div>
            <LiteSummaryPopup />
          </div>
        </Popup>
      )}
    </div>
  );
};

type TLiteWorldMapProps = {
  className?: string;
};
