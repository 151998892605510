import React, { useMemo } from 'react';

import { AssetCreateNewTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNewTabs';
import { AssetSettings } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetSettings';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { TAssetEditProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetEdit.types';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import s from './AssetCreateNew.module.scss';
import { useAssetCreateTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/useAssetCreateTabs';

export const AssetEdit: React.FC<TAssetEditProps> = ({ data, setMode, onAssetValuesSave }) => {
  const { onTabSelect, activeSettingsTab } = useAssetCreateTabs({ data });

  const onCancelClick = () => setMode('assetPreview');

  const subtitle = useMemo(() => {
    switch (data.type) {
      case 'PV':
        return data.capacityKw ? `Capacity: ${data.capacityKw} kWh` : '';
      case 'Storage':
        return data.batteryCapacityKwh ? `Storage: ${data.batteryCapacityKwh} kWh` : '';
      default:
        return '';
    }
  }, [data]);

  return (
    <div className={s.createAsset}>
      <header className={s.header}>
        <p>Edit Asset</p>
        <BaseButton theme="grey3" onClick={onCancelClick} className={s.cancelButton}>
          <BaseIcon icon="close" className={s.svgIcon} />
        </BaseButton>
      </header>
      <div className={s.hero}>
        <div className={s.icon}>
          <BaseIcon icon={get3dAssetIcon(data.type)} />
        </div>
        <h5>{data.name}</h5>
        <p>{subtitle}</p>
      </div>
      <nav className={s.navigation}>
        <AssetCreateNewTabs
          onTabSelect={onTabSelect}
          activeSettingsTab={activeSettingsTab}
          mode={data.type}
        />
      </nav>
      <AssetSettings
        mainParentData={data}
        selectedChildUuid={data.uuid}
        newAssetData={data}
        mode={activeSettingsTab}
        setMode={setMode}
        onUpdate={() => undefined}
        onEditAsset={onAssetValuesSave}
        onSaveAction="edit"
      />
    </div>
  );
};
