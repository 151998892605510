import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';

export type TUserProfileProps = {
  className?: string;
  style?: React.CSSProperties;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
};

export enum EProfileTab {
  Profile = 'Profile',
  Home = 'Home',
}
