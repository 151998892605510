import { ChartEnergyBill } from 'src/components/_charts/ChartEnergyBill';
import { EnergyBillListItem } from 'src/components/EnergyBillComponents/EnergyBillListItem';
import { InfoHelper } from 'src/components/InfoHelper';
import React from 'react';
import { TEnergyBillComponentsProps } from 'src/components/EnergyBillComponents/EnergyBillComponents.types';
import s from './EnergyBillComponents.module.scss';

export const EnergyBillComponents: React.FC<TEnergyBillComponentsProps> = ({
  list,
  total,
  info,
  title,
  subTitle,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3>{title}</h3>
        <InfoHelper info={info} isPlainText={false} position="bottom" />
      </div>
      <div className={s.container}>
        <div className={s.chartWrapper}>
          <div className={s.total}>
            <span>Total</span>
            <h3>{total}</h3>
            <span className={s.today}>{subTitle}</span>
          </div>
          <ChartEnergyBill list={list} />
        </div>
        <div className={s.listArea}>
          {list.map((item, index) => (
            <EnergyBillListItem key={`${item.label}${index}`} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};
