import { BaseIcon } from 'src/components/BaseIcon';
import { EScreenMode } from 'src/typings/configuration.types';
import React from 'react';
import { TBaseMobileModalProps } from 'src/components/BaseMobileModal/BaseMobileModal.types';
import { closeModal } from 'src/redux/modals/modals.slice';
import s from './BaseMobileModal.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const BaseMobileModal: React.FC<TBaseMobileModalProps> = ({ children, modalId }) => {
  const isOpened = useSelector(selectIsModalOpened(modalId));
  const screenMode = useSelector(selectScreenMode);
  const dispatch = useAppDispatch();

  if (screenMode !== EScreenMode.Mobile) return null;

  return isOpened ? (
    <div className={s.mobileModal}>
      <BaseIcon
        icon="close"
        className={s.closeIcon}
        onClick={() => dispatch(closeModal(modalId))}
      />
      {children}
    </div>
  ) : null;
};
