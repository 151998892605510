import { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';

export type TGraphIndexes = { startIndex: number; endIndex: number };

type TUseGraphBrushArgs<T> = {
  initialData?: Array<T>;
  initialGraphIndexes?: TGraphIndexes;
};

type TUseGraphBrushResponse<T> = {
  graphIndexes: TGraphIndexes;
  data: Array<T>;
  setDebouncedGraphIndexes: (data: TGraphIndexes) => void;
  setGraphIndexes: React.Dispatch<React.SetStateAction<TGraphIndexes>>;
  setData: React.Dispatch<React.SetStateAction<Array<T>>>;
};

export const useGraphBrush = <T>({
  initialData,
  initialGraphIndexes,
}: TUseGraphBrushArgs<T>): TUseGraphBrushResponse<T> => {
  const getInitialGraphIndexes = useCallback<(data: Array<T>) => TGraphIndexes>((data) => {
    return {
      startIndex: 0,
      endIndex: Math.max(data.length - 1, 0),
    };
  }, []);

  const [graphIndexes, setGraphIndexes] = useState<TGraphIndexes>(
    initialGraphIndexes ? initialGraphIndexes : getInitialGraphIndexes(initialData || []),
  );
  const [data, setData] = useState<Array<T>>(initialData || []);

  const debouncedGraphIndex = useRef(
    debounce((data: TGraphIndexes) => {
      setGraphIndexes(data);
    }, 500),
  ).current;

  useEffect(() => {
    if (!initialData || initialData.length === 0) return;
    setGraphIndexes(getInitialGraphIndexes(initialData));
  }, [initialData, initialData?.length, getInitialGraphIndexes]);

  const setDebouncedGraphIndexes = function (data: TGraphIndexes) {
    debouncedGraphIndex(data);
  };

  useEffect(() => {
    if (!graphIndexes || !initialData || initialData.length === 0) return;
    setData(initialData.slice(graphIndexes.startIndex, graphIndexes.endIndex));
  }, [graphIndexes, initialData, initialData?.length]);

  return {
    graphIndexes,
    data,
    setDebouncedGraphIndexes,
    setGraphIndexes,
    setData,
  };
};
