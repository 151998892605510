import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import React, { useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { TFormInviteUserProps } from './FormInviteUser.types';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './FormInviteUser.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useTranslation } from 'react-i18next';

const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const FormInviteUser: React.FC<TFormInviteUserProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const invitationLink = window.location.origin + '/scm';
  const onValueChange: TBaseInputProps['onChange'] = ({ value }) => {
    if (typeof value === 'string') {
      value = value.replaceAll(' ', ';');
      setInputValue(value);
    }
  };

  const inviteClick = () => {
    const emails = inputValue.split(';').filter((item) => item.length > 0);

    const inInvalid = emails.map(validateEmail).some((item) => item === null);

    if (inInvalid) {
      if (emails.length > 1) {
        setInputError('Some of given emails is not valid');
      } else {
        setInputError('Email is not valid');
      }
    } else {
      if (emails.length === 0) {
        setInputError('Input is empty');
      } else {
        setInputError('');
        window.location.href =
          'mailto:' +
          emails[0] +
          '?subject=Join the Energy Community&body=Hi,%0A I am inviting you to join my energy community. %0A Click on the link below to learn more and get in touch with my community manager! %0A ' +
          invitationLink;
        //'%0A Thank you';
      }
    }
  };

  return (
    <div className={s.container}>
      <div className={s.emailInvitation}>
        <p className={s.inviteHeader}> 🙌 </p>
        <p className={s.inviteHeader}>{t('INVITE_FRIENDS')}</p>

        <a
          className={s.inviteSubHeader}
          onClick={() => {
            navigator.clipboard.writeText(
              'Hi. I am inviting you to join my energy community. Click on the link below to learn more and get in touch with my community manager! - ' +
                invitationLink,
              //' Thank you',
            );
            dispatch(
              openToast({
                message: 'Copied to clipboard',
                type: 'success',
              }),
            );
          }}>
          {t('COPY_INVITATION_LINK')}
        </a>
        <h3 className={s.inviteFormHeader}>{t('OR_SEND_EMAIL_INVITATION')}</h3>
        <div>
          <BaseInput
            inputHeight="1"
            className={s.input}
            theme="filled-gray"
            type="text"
            name="email"
            placeholder="Email"
            error={inputError}
            value={inputValue}
            onChange={onValueChange}
          />
          <BaseButton size="medium" theme="primary" onClick={inviteClick}>
            {t('INVITE')}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
