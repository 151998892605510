import {
  CURRENCY_OPTIONS,
  LANGUAGE_OPTIONS,
  SCM_TRADING_OPTIONS,
  SPOT_MARKET_TYPE_OPTIONS,
  SWITCHER_ICON_SIZES,
  TIMEZONE_OPTIONS,
} from 'src/constants/application';
import {
  TCommunitySettingsFields,
  TFieldsUnionWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import { TFormFieldsArgs, TRelatedFormFields } from 'src/components/FormSettingsData';
import { TValidators, rules } from 'src/utils/validation';
import { configType, isLibrary } from 'src/mocks/configurationSettings';

import { BACKEND_DATE_FORMATS } from 'src/utils/UTCMoment';
import { EFormVariant } from 'src/typings/base-types';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { getAllFieldTemplatesWithValuesForAsset } from 'src/utils/assetsFields/fieldTemplatesWithValues';

export const fieldTemplates = ({
  values,
  configurationCharacteristic,
}: TFormFieldsArgs): TFieldsUnionWithValue[] => {
  const communityAssetFields = getAllFieldTemplatesWithValuesForAsset({
    type: 'Area',
    settingsData: values,
    isLibrary,
    values: {
      gridFeeEnabled: values.gridFeeEnabled,
      gridFeePercentage: values.gridFeePercentage || undefined,
      gridFeeConstant: values.gridFeeConstant || undefined,
      transformerCapacityEnabled: values.transformerCapacityEnabled,
      importCapacityKva: values.importCapacityKva || undefined,
      exportCapacityKva: values.exportCapacityKva || undefined,
      coefficientPercentage: values.coefficientPercentage || undefined,
      baselinePeakEnergyEnabled: values.baselinePeakEnergyEnabled,
      baselinePeakEnergyImportKwh: values.baselinePeakEnergyImportKwh || undefined,
      baselinePeakEnergyExportKwh: values.baselinePeakEnergyExportKwh || undefined,
    },
    configType,
    configurationCharacteristic,
    includeAll: true,
  }).filter((f) =>
    [
      'gridFeeEnabled',
      'gridFeePercentage',
      'gridFeeConstant',
      'transformerCapacityEnabled',
      'importCapacityKva',
      'exportCapacityKva',
      'coefficientPercentage',
      'baselinePeakEnergyEnabled',
      'baselinePeakEnergyImportKwh',
      'baselinePeakEnergyExportKwh',
    ].includes(f.name),
  );

  const output: TFieldsUnionWithValue[] = [
    {
      name: 'name',
      type: 'text',
      // label: 'Name your community',
      formView: EFormVariant.Express,
      value: values.name,
    },
    {
      name: 'currency',
      type: 'enum',
      // label: 'Community Currency',
      options: CURRENCY_OPTIONS,
      formView: EFormVariant.Express,
      value: values.currency,
    },
    {
      name: 'startEndDate',
      type: 'dateRange',
      valueFormat: BACKEND_DATE_FORMATS.SETTINGS_DATA,
      formView: EFormVariant.Express,
      EXCLUDE: true,
      value: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
    },
    {
      name: 'locationVisible',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      value: values.locationVisible,
      label: 'Show my community on map',
      formView: EFormVariant.Express,
      tooltipText: 'Show my community on map',
      showTooltip: true,
    },
    {
      name: 'description',
      type: 'textarea',
      label: 'Description',
      formView: EFormVariant.Advanced,
      value: values.description,
    },
    {
      name: 'timezone',
      type: 'enum',
      // label: 'Timezone',
      options: TIMEZONE_OPTIONS,
      formView: EFormVariant.Advanced,
      value: values.timezone,
    },
    {
      name: 'language',
      type: 'enum',
      options: LANGUAGE_OPTIONS,
      formView: EFormVariant.Advanced,
      value: 1,
    },
    {
      name: 'spotMarketType',
      type: 'enum',
      label: 'Spot Market Type',
      options: SPOT_MARKET_TYPE_OPTIONS,
      formView: EFormVariant.Advanced,
      value: values.spotMarketType,
    },
    {
      name: 'bidOfferMatchAlgo',
      type: 'enum',
      // label: 'Market Type',
      options: SCM_TRADING_OPTIONS,
      formView: EFormVariant.Advanced,
      value: values.bidOfferMatchAlgo,
    },
    {
      name: 'marketCount',
      type: 'number',
      label: 'Spot Markets Number',
      formView: EFormVariant.Advanced,
      value: values.marketCount,
    },
    {
      name: 'slotLengthMinutes',
      type: 'number',
      label: 'Spot Market Length',
      unit: 'min',
      formView: EFormVariant.Advanced,
      value: values.slotLengthMinutes,
    },
    {
      name: 'tickLengthSeconds',
      type: 'number',
      label: 'Tick Length',
      unit: 'sec',
      formView: EFormVariant.Advanced,
      value: values.tickLengthSeconds,
    },
    {
      name: 'slotLengthRealtimeSeconds',
      type: 'number',
      label: 'Market Slot Real Time Duration',
      unit: 'sec',
      formView: EFormVariant.Advanced,
      value: values.slotLengthRealtimeSeconds,
    },
    // {
    //   name: 'gridFeeType',
    //   type: 'enum',
    //   label: 'Grid Fees',
    //   options: GRID_FEE_TYPE_OPTIONS,
    //   formView: EFormVariant.Advanced,
    //   value: values.gridFeeType,
    // },
    ...communityAssetFields,
  ];

  return output;
};

export const relatedFormFields: TRelatedFormFields = [
  {
    fieldName: 'baselinePeakEnergyEnabled',
    relatedFields: ['baselinePeakEnergyImportKwh', 'baselinePeakEnergyExportKwh'],
  },
  {
    fieldName: 'transformerCapacityEnabled',
    relatedFields: ['importCapacityKva', 'exportCapacityKva'],
  },
];

// It is the maximum integer value at the Backend. it equals to (2^32)-1
const maxIntValueForBE = 2147483647;
const exportImportValidator = (n) => [rules.integer(n), rules.range(n, 0, maxIntValueForBE)];

export const validators = ({
  usedProjectNames,
  currentProjectName,
}: {
  usedProjectNames: string[];
  currentProjectName: string;
}): TValidators<
  keyof TCommunitySettingsFields &
    keyof Pick<TValuesByFieldName, 'gridFeeConstant' | 'gridFeePercentage'>
> => ({
  name: [
    ...((n) => [
      rules.required(n),
      rules.isNameUnique(usedProjectNames, currentProjectName),
      rules.maxLength(n, 50),
    ])('Name'),
  ],
  marketCount: [...((n) => [rules.integer(n), rules.range(n, 0, 100)])('Spot Markets Number')],
  slotLengthRealtimeSeconds: [
    ...((n) => [rules.integer(n), rules.range(n, 0, 900)])('Market Slot Real Time Duration'),
  ],
  slotLengthMinutes: [...((n) => [rules.integer(n), rules.range(n, 2, 60)])('Spot Market Length')],
  gridFeeConstant: [...((n) => [rules.integer(n), rules.range(n, 0, 1000000)])('Grid fee value')],
  gridFeePercentage: [
    ...((n) => [rules.integer(n), rules.range(n, 0, 100)])('Grid fee percentage'),
  ],
  importCapacityKva: [...exportImportValidator('Import Capacity kVa')],
  exportCapacityKva: [...exportImportValidator('Export Capacity kVa')],
  coefficientPercentage: [...((n) => [rules.float(n), rules.range(n, 0, 1)])('0 to 1 is valid')],
  baselinePeakEnergyImportKwh: [...exportImportValidator('Baseline Peak Import kWh')],
  baselinePeakEnergyExportKwh: [...exportImportValidator('Baseline Peak Export kWh')],
  tickLengthSeconds: [...((n) => [rules.integer(n), rules.range(n, 1, 90)])('Tick Length')],
  bidOfferMatchAlgo: [...((n) => [rules.required(n)])('Matching Algorithm')],
});
