import { BaseErrorMessage, TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import React, { useState, useRef, RefObject } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/validation';
import { initFormData, interestOptions } from 'src/components/FormSignup/useSignupFormData';

import { BaseButton } from 'src/components/BaseButton';
import { BaseCheckbox } from '../BaseCheckbox';
import { BaseInput } from '../BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { EPredefinedModalIds } from 'src/constants/modals';
import { PASSWORD_MIN_LENGTH } from 'src/constants/application';
import { ProfilePicture } from 'src/components/ProfilePicture';
import { TFormSignupProps } from './FormSignup.types';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './FormSignup.module.scss';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useGraphQLMessage } from 'src/hooks/useGraphQLMessage';
import { useSignupMutation } from 'src/graphql';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';

const validators = () => ({
  name: [...((n) => [rules.required(n)])('Name')],
  email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  password: [...((n) => [rules.required(n), rules.minLength(n, PASSWORD_MIN_LENGTH)])('Password')],
  other: [...((n) => [rules.required(n)])('Other')] || {},
});

export const FormSignup: React.FC<TFormSignupProps> = ({
  className,
  onSuccess,
  formData,
  handleFieldChange,
}) => {
  const dispatch = useAppDispatch();
  const [termsError, setTermsError] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [signupMutation, { loading, error }] = useSignupMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const recaptchaRef = useRef() as RefObject<ReCAPTCHA>;
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!formData.interest) {
      setInterestError(true);
      return;
    }

    if (!formData.termsAccepted) {
      setTermsError(true);
      return;
    }

    setTermsError(false);

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    if (errors) {
      const keys = Object.keys(errors),
        key = keys[0];

      if (formData.interest === 'Other' || keys.length !== 1 || key !== 'other') return;
    }

    const recaptchaValue = (recaptchaRef && recaptchaRef.current?.getValue()) || false;

    if (recaptchaValue) {
      try {
        const { data } = await signupMutation({
          variables: {
            email: formData.email,
            name: formData.name,
            organisation: '',
            password: formData.password,
            interest: formData.interest === 'Other' ? formData.other : formData.interest,
            profilePicture: formData.picture,
          },
        });

        if (data && recaptchaRef.current?.getValue()) {
          const token = data.registerUser?.authToken;
          if (token) {
            dispatch(setTokenAuth(token));
            onSuccess?.();
          }
        }
      } catch (err) {}
    } else {
      setReCaptchaError(true);
    }
  }

  const generatedErrorMessage: string = termsError
    ? 'You must agree with the terms and conditions.'
    : ``;

  const reCaptchaErrorMessage = 'Google ReCaptcha is required.';

  return (
    <form className={className} onSubmit={handleSubmit}>
      <div className={s.inputsWrapper}>
        <ProfilePicture changePicture={handleFieldChange} />
      </div>
      <div className={s.inputsWrapper}>
        <BaseInput
          type="text"
          name="name"
          value={formData.name}
          onChange={handleFieldChange}
          label="Your Name"
          theme="line-light"
          className={s.input}
          error={validationErrors.name}
          onBlur={(e) => handleFieldBlur(e, validators().name)}
        />
        <BaseInput
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFieldChange}
          label="Your Email"
          theme="line-light"
          className={s.input}
          error={validationErrors.email}
          onBlur={(e) => handleFieldBlur(e, validators().email)}
        />
      </div>
      <div className={s.inputsWrapper}>
        <BaseInput
          type="password"
          name="password"
          value={formData.password}
          onChange={handleFieldChange}
          label="Password"
          theme="line-light"
          className={s.input}
          error={validationErrors.password}
          onBlur={(e) => handleFieldBlur(e, validators().password)}
        />
        <div className={s.input}>
          <BaseSelect
            label="What will you use the map for?"
            name="interest"
            onChange={(e) => {
              handleFieldChange(e);
              setInterestError(false);
            }}
            value={formData.interest}
            options={interestOptions}
            error={interestError ? 'Choose your interest' : ''}
          />
        </div>
      </div>
      {formData.interest === 'Other' && (
        <div className={classnames(s.inputsWrapper, s.rightDirection)}>
          <BaseInput
            type="text"
            name="other"
            value={formData.other}
            onChange={handleFieldChange}
            label="Your Other Interest"
            theme="line-light"
            className={s.input}
            error={validationErrors.other}
            onBlur={(e) => handleFieldBlur(e, validators().other)}
          />
        </div>
      )}

      <div className={s.recaptchaContainer}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
          onChange={() => reCaptchaError && setReCaptchaError(false)}
        />
      </div>

      <BaseButton type="submit" isLoading={loading} className={s.buttonRegister}>
        Get Started
      </BaseButton>

      <div className={s.checkboxWrapper}>
        <BaseCheckbox
          value={formData.termsAccepted}
          onChange={handleFieldChange}
          theme="light"
          label={
            <div>
              I accept the{' '}
              <button
                type="button"
                className={s.termsBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(openModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
                }}>
                Terms of Service
              </button>
            </div>
          }
          className={s.checkbox}
          name="termsAccepted"
          error={generatedErrorMessage}
        />
      </div>
      {graphqlMessage && <BaseErrorMessage>{graphqlMessage}</BaseErrorMessage>}
      {reCaptchaError && <BaseErrorMessage>{reCaptchaErrorMessage}</BaseErrorMessage>}
    </form>
  );
};
