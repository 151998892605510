import { ConfigType, ConfigurationOutput, ListCommunityInfoQuery } from 'src/graphql';

import { TChildrenPayload } from 'src/components/ConfigurationManagementCentral';

export type TMapSidebarCommunitiesListProps = {
  className?: string;
  style?: React.CSSProperties;
};

export enum EListFilter {
  Simulations = 'COLLABORATIONS',
  Operational = 'CANARY_NETWORKS',
}

export type TListHeaderProps = {
  activeFilter: EListFilter;
  onFilterChange: (value: EListFilter) => void;
  searchQuery: string;
  onSearchQueryChange: (data: string) => void;
  isLoggedIn: boolean;
};

export type TCommunitiesListProps = {
  height: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  removeCommunity: TChildrenPayload['handleCommunityRemove'];
  updatePersonalCommunitiesList: () => void;
  onDuplicationComplete: () => void;
  setLoadingStatus?: (status: boolean) => void;
  isOperationalTab?: boolean;
};

export type MapSidebarCommunitiesHeaderProps = Record<string, never>;

export type TMapSidebarProjects = NonNullable<
  NonNullable<ListCommunityInfoQuery['listCommunityInfo']>['projects']
>;

export type TMapSidebarCanaryProjects = Array<
  Pick<
    ConfigurationOutput,
    'name' | 'uuid' | 'timestamp' | 'user' | 'private' | 'resultsStatus' | 'coordinates' | 'project'
  > & { configType?: ConfigType }
>;
