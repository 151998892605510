import gql from 'graphql-tag';

export const ScmAccumulatedKpiDifferences = gql`
  subscription scmAccumulatedKpiDifferences(
    $jobId: String!
    $areaUUID: String!
    $startTime: DateTime!
  ) {
    scmAccumulatedKpiDifferences(jobId: $jobId, areaUUID: $areaUUID, startTime: $startTime)
  }
`;
