import { RootState } from 'src/redux/store';
import { TSCMState } from 'src/redux/scm/scm.slice';

export const selectSCMDataSheetResponse = (state: RootState): TSCMState['dataSheetResponse'] =>
  state.scm.dataSheetResponse;

export const selectActiveSCMStep = (state: RootState): TSCMState['activeSCMStep'] =>
  state.scm.activeSCMStep;

export const selectSCMFlow = (state: RootState): TSCMState['isSCMFlow'] => state.scm.isSCMFlow;

export const selectIsAppLoading = (state: RootState): TSCMState['isAppLoading'] =>
  state.scm.isAppLoading;
export const selectSCMCommunityMember = (state: RootState): TSCMState['scmCommunityManager'] =>
  state.scm.scmCommunityManager;
export const selectScmSidebarTab = (state: RootState): TSCMState['scmSidebarTab'] =>
  state.scm.scmSidebarTab;
export const selectSCMHomeDetails = (state: RootState): TSCMState['scmHomeDetails'] =>
  state.scm.scmHomeDetails;

export const selectHomeBillsDifferences = (state: RootState): TSCMState['homeBillsDifferences'] =>
  state.scm.homeBillsDifferences;

export const selectHomeKpi = (state: RootState): TSCMState['homeKpi'] => state.scm.homeKpi;
export const selectKpiDifference = (state: RootState): TSCMState['kpiDifference'] =>
  state.scm.kpiDifference;

export const selectDataResolution = (state: RootState): TSCMState['dataResolution'] =>
  state.scm.dataResolution;

export const selectCommunityKpi = (state: RootState): TSCMState['communityKpi'] =>
  state.scm.communityKpi;

export const selectCommunityBillsDifference = (
  state: RootState,
): TSCMState['communityBillsDifferences'] => state.scm.communityBillsDifferences;

export const selectCommunityKpiDifference = (
  state: RootState,
): TSCMState['communityKpiDifference'] => state.scm.communityKpiDifference;

export const selectActiveTabResults = (state: RootState): TSCMState['activeTabResults'] =>
  state.scm.activeTabResults;

export const selectActiveCommunityListTab = (
  state: RootState,
): TSCMState['activeCommunityListTab'] => state.scm.activeCommunityListTab;

export const selectNewOperationalCommunityUuid = (
  state: RootState,
): TSCMState['newOperationalCommunityUuid'] => state.scm.newOperationalCommunityUuid;

export const selectIsOperationalCommunity = (
  state: RootState,
): TSCMState['isOperationalCommunity'] => state.scm.isOperationalCommunity;

export const selectMeasurementRequests = (state: RootState): TSCMState['measurementRequests'] =>
  state.scm.measurementRequests;

export const selectActiveLiveAccumulatedSubscriptionId = (
  state: RootState,
): TSCMState['activeLiveAccumulatedSubscriptionId'] =>
  state.scm.activeLiveAccumulatedSubscriptionId;

export const selectSCMBillTableData = (state: RootState): TSCMState['scmBillTableData'] =>
  state.scm.scmBillTableData;

export const selectPreventPushResultsScreen = (
  state: RootState,
): TSCMState['preventPushResultsScreen'] => state.scm.preventPushResultsScreen;

export const selectSelectedMonthlyIndicator = (
  state: RootState,
): TSCMState['selectedMonthlyIndicator'] => state.scm.selectedMonthlyIndicator;

export const selectSelectedDailyIndicator = (
  state: RootState,
): TSCMState['selectedDailyIndicator'] => state.scm.selectedDailyIndicator;

export const selectHideSidebarHeader = (state: RootState): TSCMState['hideSidebarHeader'] =>
  state.scm.hideSidebarHeader;

export const selectUnreadMessageCount = (state: RootState): TSCMState['unreadMessageCount'] =>
  state.scm.unreadMessageCount;

export const selectUserListItemClicked = (state: RootState): TSCMState['userListItemClicked'] =>
  state.scm.userListItemClicked;

export const selectDisableScmTabChanges = (state: RootState): TSCMState['disableScmTabChanges'] =>
  state.scm.disableScmTabChanges;
