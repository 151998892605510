import { BaseTags, TBaseTagsProps } from '../../BaseTags';
import { Chart, ChartDataset } from 'chart.js';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { useMemo, useRef, useState } from 'react';

import { AxisXCustom } from 'src/components/_charts/AxisXCustom/AxisXCustom';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartPriceColors } from 'src/constants/chart';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { TChartPricesProps } from './ChartPrices.types';
import { formatter } from 'src/utils/formatter';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useSelector } from 'react-redux';

export const ChartPrices: React.FC<TChartPricesProps> = ({
  parsedPriceEnergyDay,
  startUnix,
  endUnix,
}) => {
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [disabledItems, setDisabledItems] = useState<number[]>([]);
  const { currency } = useSelector(selectSettingsData);

  const setChartPricesVisibleItems: TBaseTagsProps['onChange'] = ({ value }) => {
    setDisabledItems(value);
  };

  const datasets = useMemo(() => {
    const commonDatasetOptions: Partial<ChartDataset<'line'>> = {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderCapStyle: 'square',
      borderJoinStyle: 'miter',
      tension: 1,
      cubicInterpolationMode: 'monotone',
    };

    return [
      {
        ...commonDatasetOptions,
        data: parsedPriceEnergyDay?.averageData || [],
        borderColor: ChartPriceColors.averageLine,
        backgroundColor: ChartPriceColors.averageLine,
        hidden: disabledItems.includes(0),
        // label: currency + '/CURRENCY/KWH',
        label: JSON.stringify({
          title: 'Average',
          unit: 'kWh',
          currency,
        }),
      },
      {
        ...commonDatasetOptions,
        data: parsedPriceEnergyDay?.minData || [],
        borderColor: ChartPriceColors.minLine,
        backgroundColor: ChartPriceColors.minLine,
        hidden: disabledItems.includes(1),
        label: JSON.stringify({
          title: 'Min',
          unit: 'kWh',
          currency,
        }),
      },
      {
        ...commonDatasetOptions,
        data: parsedPriceEnergyDay?.maxData || [],
        borderColor: ChartPriceColors.maxLine,
        backgroundColor: ChartPriceColors.maxLine,
        hidden: disabledItems.includes(2),
        label: JSON.stringify({
          title: 'Max',
          unit: 'kWh',
          currency,
        }),
      },
      {
        ...commonDatasetOptions,
        data: parsedPriceEnergyDay?.gridFee || [],
        borderColor: ChartPriceColors.gridFeeLine,
        backgroundColor: ChartPriceColors.gridFeeLine,
        hidden: disabledItems.includes(3),
        label: JSON.stringify({
          title: 'Grid Fee',
          unit: 'kWh',
          currency,
        }),
      },
    ];
  }, [
    parsedPriceEnergyDay?.averageData,
    parsedPriceEnergyDay?.minData,
    parsedPriceEnergyDay?.maxData,
    parsedPriceEnergyDay?.gridFee,
    disabledItems,
    currency,
  ]);

  useChartJS(
    EChartName.Prices,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: {
        scales: {
          x: {
            max: endUnix * 1000,
            min: startUnix * 1000,
          },
          y: {
            ticks: {
              display: true,
              font: {
                size: 8,
              },
            },
          },
        },
      },
      datasetsLengthWillChange: true,
    },
  );

  const chartPricesItems = [
    { name: 'Average', color: ChartPriceColors.averageLine },
    { name: 'Min', color: ChartPriceColors.minLine },
    { name: 'Max', color: ChartPriceColors.maxLine },
    { name: 'Grid Fee', color: ChartPriceColors.gridFeeLine },
  ];

  return (
    <ChartWrapper
      title="Price"
      info={getChartTooltip(EChartTooltips.TOOLTIP_PRICES, formatter.getCurrencySymbol(currency))}
      footerComponent={
        <BaseTags
          items={chartPricesItems}
          name="baseTags1"
          value={disabledItems}
          onChange={(value) => setChartPricesVisibleItems(value)}
        />
      }>
      <ChartDataLoadingWrapper loading={!parsedPriceEnergyDay}>
        <canvas ref={canvasRef} height={180} />

        <AxisXCustom startUnix={startUnix} endUnix={endUnix} style={{ marginLeft: 30 }} />
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
