import { AppLocationContextProvider, TAppLocationContextValue } from 'src/hooks/useAppLocation';
import React, { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Route, useLocation } from 'react-router-dom';

import { ModalAuth } from 'src/components/ModalAuth';
import { ModalNetworkCanary } from 'src/components/ModalNetworkCanary';
import { TAppRouteProps } from 'src/routes/routes.config';

const createBaseUrl = () => {
  if (window.location.href.includes('phoenix.ui-dev.gridsingularity')) {
    return 'https://scm.ui-dev.gridsingularity.com/scm-map';
  }
  else if (window.location.href.includes('localhost')) {
    return 'http://localhost:3000/scm-map'
  }
  else {
    return 'https://scm.gridsingularity.com/scm-map'
  }
}

const SCM_BASE_URL = createBaseUrl();

export const AppRoute: React.FC<TAppRouteProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    const favicon = document.getElementById('js-favicon') as HTMLLinkElement;

    if (props.routeName !== 'home') {
      if (favicon) {
        // favicon.href = FaviconGreen;
        favicon.href = process.env.PUBLIC_URL + '/favicon-green.png';
      }
    } else {
      if (favicon) {
        favicon.href = process.env.PUBLIC_URL + '/favicon-red.png';
      }
    }
  }, [props.routeName]);

  const value: TAppLocationContextValue = useMemo(() => {
    return {
      ...location,
      routeName: props.routeName,
    };
  }, [location, props.routeName]);

  if (location.pathname.includes('scm-map')) {
    window.location.href = SCM_BASE_URL;
    return null;
  }

  return (
    <AppLocationContextProvider value={value}>
      <Route {...props} />

      {/* ModalAuth */}
      <ModalAuth />

      {/* ModalNetworkCanary */}
      <ModalNetworkCanary />
    </AppLocationContextProvider>
  );
};
