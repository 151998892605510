import {
  TEnergyData,
  TGatheredValues,
  TLegendExtraProps,
  TMultipler,
  TParsedAreaThoughput,
  TParsedEnergyTradeProfile,
  TParserState,
  TUseEnergyTradeProfileDataProps,
} from 'src/components/_charts/ChartEnergyTradeProfile';
import { useCallback, useMemo } from 'react';

import { BACKEND_DATE_FORMATS } from 'src/utils/UTCMoment';
import { NET_ENERGY_KEY } from 'src/constants/application';
import { NN } from 'src/typings/helpers';
import { TBaseSwitchProps } from 'src/components/BaseSwitch';
import { TBaseTagsProps } from 'src/components/BaseTags';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { getColor } from 'src/components/_charts/ChartEnergyTradeProfile/helpers';
import { getUnixFromDate } from 'src/utils/getUnixFromDate';
import { useMergedState } from 'src/hooks/useMergedState';
import vars from 'src/assets/styles/utils/vars.module.scss';

const INITIAL_STATE: Readonly<TParserState> = {
  isolated: new Set(),
  showPeakAnalysis: false,
  showNetEnergy: false,
};

function storeMaxImportExport(
  container: TParsedAreaThoughput,
  data: NN<NN<TSimulationResultData['energyTradeProfileAreaThroughput']>['areaThroughput']>['Date'],
  transferDirection: 'import' | 'export',
) {
  Object.entries(data[transferDirection]).forEach(([type, value]) => {
    const storedValue = container[`${transferDirection}Data`][type] || 0;

    if (value > storedValue) {
      container[`${transferDirection}Data`][type] = value;
    }
  });
}

export type TUseParsedEnergyTradeProfileOutput = ReturnType<typeof useParsedEnergyTradeProfile>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useParsedEnergyTradeProfile({
  data,
  assetName,
  assetUuid,
}: TUseEnergyTradeProfileDataProps) {
  const [{ isolated, showNetEnergy, showPeakAnalysis }, setState] = useMergedState<TParserState>(
    INITIAL_STATE,
  );

  const parsedAreaThroughput = useMemo(() => {
    const output: TParsedAreaThoughput = {
      netEnergyData: [],
      importData: {},
      exportData: {},
    };

    if (data?.areaThroughput) {
      Object.entries(data.areaThroughput).forEach(([date, values]) => {
        const timestamp = getUnixFromDate(date, BACKEND_DATE_FORMATS.AREA_THROUGHPUT);
        storeMaxImportExport(output, values, 'export');
        storeMaxImportExport(output, values, 'import');

        const netEnergyFlow = values.net_energy_flow?.peak_energy_kWh;

        if (typeof netEnergyFlow === 'number' && netEnergyFlow !== 0) {
          output.netEnergyData.push({
            x: timestamp,
            y: netEnergyFlow,
          });
        }
      });
    }

    return output;
  }, [data?.areaThroughput]);

  const parsedEnergyTradeProfile: TParsedEnergyTradeProfile = useMemo(() => {
    const output = {
      soldEnergy: {},
      boughtEnergy: {},
    };
    const gatheredValues: TGatheredValues = {};

    if (!data?.energyTradeProfile) {
      return output;
    }

    const { bought_energy, sold_energy } = data.energyTradeProfile;

    const gatherTrades = (
      input:
        | NN<TUseEnergyTradeProfileDataProps['data']>['energyTradeProfile']['bought_energy']
        | NN<TUseEnergyTradeProfileDataProps['data']>['energyTradeProfile']['sold_energy'],
      container: TEnergyData,
      multiplerA: TMultipler,
    ) => {
      const gather = (name: string, date: string, value: number, multiplerB: TMultipler) => {
        const y = value * multiplerB * -1;
        const x = getUnixFromDate(date, BACKEND_DATE_FORMATS.ENERGY_TRADE_PROFILE);

        if (!container[name]) {
          container[name] = [];
          gatheredValues[name] = {};
        }

        const isValueGathered = x in gatheredValues[name];

        if (isValueGathered) {
          const existingPoint = container[name].find((item) => item.x === x);
          existingPoint!.y = existingPoint!.y + y;
        } else {
          gatheredValues[name][x] = y;

          container[name].push({
            x,
            y,
          });
        }
      };

      const gatherObject = (
        object: { [date: string]: number },
        name: string,
        multiplerC: TMultipler,
      ) => {
        Object.entries(object).forEach(([date, value]) => {
          gather(name, date, value, multiplerC);
        });
      };

      if (isolated.size) {
        isolated.forEach((name) => {
          if (!(name in input)) return;

          const { accumulated, ...energy } = input[name];

          if (!showPeakAnalysis) {
            gatherObject(accumulated, name, multiplerA);
          }

          Object.entries(energy).forEach(([contractorName, contractorData]) => {
            if (isolated.has(contractorName)) return;
            gatherObject(contractorData, contractorName, (multiplerA * -1) as TMultipler);
          });
        });
      } else {
        Object.entries(input).forEach(([name, data]) => {
          const { accumulated } = data;
          gatherObject(accumulated, name, multiplerA);
        });
      }
    };

    gatherTrades(sold_energy, output.soldEnergy, 1);
    gatherTrades(bought_energy, output.boughtEnergy, -1);

    return output;
  }, [data?.energyTradeProfile, isolated, showPeakAnalysis]);

  const legendPre: TBaseTagsProps<TLegendExtraProps>['items'] = useMemo(() => {
    if (!data?.energyTradeProfile || !assetUuid) return [];

    const { sold_energy, bought_energy } = data.energyTradeProfile;
    const allContractors = Array.from(
      new Set([...Object.keys(bought_energy), ...Object.keys(sold_energy)]),
    );
    const contractors = allContractors.reduce(
      (acc: TBaseTagsProps<TLegendExtraProps>['items'], name) => {
        const externalTrades = name === assetName;

        if (showPeakAnalysis && externalTrades) return acc;

        acc.push({
          name: externalTrades ? 'Grid Market' : name,
          value: name,
          color: getColor(name, assetUuid),
        });

        return acc;
      },
      [],
    );

    return [
      ...contractors,
      { name: NET_ENERGY_KEY, value: NET_ENERGY_KEY, color: vars['color-net-energy'] },
    ];
  }, [assetName, assetUuid, data?.energyTradeProfile, showPeakAnalysis]);

  const legendFinal = useMemo(() => {
    return legendPre.map((item) => {
      const isNetEnergy = item.name === NET_ENERGY_KEY;
      const output = {
        ...item,
      };

      if (showPeakAnalysis && !isNetEnergy) {
        output.disabled = true;
      } else {
        if (isNetEnergy) {
          output.inactive = !showNetEnergy;
        } else {
          if (showNetEnergy) {
            output.disabled = true;
          } else if (isolated.size) {
            output.inactive = !isolated.has(item.value);
          }
        }
      }

      return output;
    });
  }, [legendPre, showPeakAnalysis, showNetEnergy, isolated]);

  const togglePeakAnalysis: TBaseSwitchProps['onChange'] = useCallback(
    ({ value }) => {
      setState(() => {
        const showPeakAnalysis = value as boolean;
        const isolated = showPeakAnalysis ? new Set([assetName]) : new Set<string>();

        return {
          isolated,
          showPeakAnalysis,
          showNetEnergy: false,
        };
      });
    },
    [assetName, setState],
  );

  const toggleNetEnergy = useCallback(
    (value?: boolean) => {
      setState((curr) => ({
        showNetEnergy: typeof value !== 'boolean' ? !curr.showNetEnergy : value,
      }));
    },
    [setState],
  );

  const toggleIsolated = useCallback(
    (dataKey: string) => {
      setState((curr) => {
        const newIsolated = new Set(curr.isolated);

        if (newIsolated.has(dataKey)) {
          newIsolated.delete(dataKey);
        } else {
          newIsolated.add(dataKey);

          const legendHasNetEnergy = legendFinal.findIndex((p) => p.name === NET_ENERGY_KEY) > -1;
          const sizeReference = legendHasNetEnergy ? legendFinal.length - 1 : legendFinal.length;
          if (newIsolated.size === sizeReference) {
            newIsolated.clear();
          }
        }

        return { isolated: newIsolated };
      });
    },
    [setState, legendFinal],
  );

  return {
    isolated,
    showNetEnergy,
    showPeakAnalysis,
    legend: legendFinal,
    ...parsedAreaThroughput,
    ...parsedEnergyTradeProfile,
    hasExternalTrades:
      data?.energyTradeProfile.sold_energy[assetName] ||
      data?.energyTradeProfile.bought_energy[assetName],
    toggleIsolated,
    togglePeakAnalysis,
    toggleNetEnergy,
  };
}
