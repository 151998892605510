import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
} from 'src/utils/assetsFields/assetsFields.types';

import { EFormVariant } from 'src/typings/base-types';

export type TAreaSCMArgs = {
  isLibrary: boolean;
  values: TAssetFieldTemplatesArgs['values'];
};

export const AreaSCM = ({ isLibrary, values }: TAreaSCMArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'libraryUUID',
      formView: EFormVariant.Express,
    },
    {
      name: 'name',
      type: 'text',
      label: 'Home Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      EXCLUDE: isLibrary,
      formView: EFormVariant.Express,
    },
    {
      name: 'marketMakerRate',
      type: 'number',
      label: 'Selling Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Express,
      tooltipText:
        'Selling rate is the price at which the community buys energy deficit from the external grid (utility)',
      showTooltip: true,
    },
    {
      name: 'feedInTariff',
      type: 'number',
      label: 'Buying Rate',
      unit: 'cents / kWh',
      formView: EFormVariant.Express,
      tooltipText:
        'Buying rate is the price at which the external grid (utility) buys surplus from the community',
      showTooltip: true,
    },
    {
      name: 'importCapacityKva',
      type: 'number',
      label: 'Import',
      unit: 'kVA',
      inlineAlign: 'left',
      EXCLUDE: !values?.transformerCapacityEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'exportCapacityKva',
      type: 'number',
      label: 'Export',
      unit: 'kVA',
      inlineAlign: 'right',
      EXCLUDE: !values?.transformerCapacityEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'baselinePeakEnergyImportKwh',
      type: 'number',
      label: 'Baseline Peak Import',
      unit: 'kWh',
      inlineAlign: 'left',
      EXCLUDE: isLibrary || !values?.baselinePeakEnergyEnabled,
      formView: EFormVariant.Advanced,
    },
    {
      name: 'baselinePeakEnergyExportKwh',
      type: 'number',
      label: 'Baseline Peak Export',
      unit: 'kWh',
      inlineAlign: 'right',
      EXCLUDE: isLibrary || !values?.baselinePeakEnergyEnabled,
      formView: EFormVariant.Advanced,
    },

    {
      name: 'fixedMonthlyFee',
      type: 'number',
      label: 'Full Coverage Service Fee',
      unit: '€ / month',
      formView: EFormVariant.GridMarketEnergy,
    },
    {
      name: 'assistanceMonthlyFee',
      type: 'number',
      label: 'Assistance Fee',
      unit: '€ / month',
      formView: EFormVariant.GridMarketEnergy,
    },
    {
      name: 'marketplaceMonthlyFee',
      type: 'number',
      label: 'Monthly Fee',
      unit: '€ / month',
      formView: EFormVariant.GridMarketEnergy,
    },
    // {
    //   name: 'taxesSurcharges',
    //   type: 'number',
    //   label: 'Taxes and Surcharges',
    //   unit: '€ / kWh',
    //   formView: EFormVariant.GridMarketEnergy,
    // },
    // {
    //   name: 'gridFeeConstant',
    //   type: 'number',
    //   label: 'Grid Fees',
    //   unit: '€ / kWh',
    //   formView: EFormVariant.GridMarketEnergy,
    // },
  ];
};
