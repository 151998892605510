/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import { BaseTextarea, TBaseTextareaProps } from 'src/components/BaseTextarea';
import {
  ConfigType,
  GridFeeType,
  ListCollaborationsDocument,
  ListCollaborationsQuery,
  SettingsDataFieldsFragment,
} from 'src/graphql';
import {
  selectCommunityAssetSettings,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectIsCommunityCreatedInDB,
  selectReadOnly,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { EFormVariant, TFieldValue } from 'src/typings/base-types';
import {
  TCommunitySettingsFields,
  TFieldsUnionWithValue,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldsVisibility,
  TFormSettingsDataProps,
  TSettingsSaveProps,
} from './FormSettingsData.types';
import { fieldTemplates, relatedFormFields, validators } from './formFields';

import classnames from 'classnames';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { BaseDateRangePicker } from 'src/components/BaseDateRangePicker';
import { BaseSelect } from 'src/components/BaseSelect';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { GridMarketModalHeader } from 'src/components/MapSidebarSCM/components/GridMarket/GridMarketModalHeader';
import { isLibrary } from 'src/mocks/configurationSettings';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';
import { getAssetValues } from 'src/utils/assetsFields/fieldTemplatesWithValues';
import { findField } from 'src/utils/fieldUtils';
import { validateFields } from 'src/utils/validation';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';
import s from './FormSettingsData.module.scss';

function getVisibleFields({
  fields,
  fieldsVisibility,
  formVariant,
}: {
  fields: ReturnType<typeof fieldTemplates>;
  fieldsVisibility: TFieldsVisibility;
  formVariant: TFormSettingsDataProps['formVariant'];
  isAdmin: boolean;
}) {
  if (!fields) return fields;

  return fields.reduce((acc: ReturnType<typeof fieldTemplates>, field) => {
    const conditionA =
      formVariant === EFormVariant.Express ? field.formView === EFormVariant.Express : true;
    const conditionB = field.name in fieldsVisibility ? fieldsVisibility[field.name] : true;

    if (conditionA && conditionB) {
      acc.push(field);
    }

    return acc;
  }, []);
}

type THandleChangeArgs = {
  name: keyof TSettingsData | 'locationVisible' | 'name';
  value: TFieldValue;
};

export const FormSettingsData: React.FC<TFormSettingsDataProps> = ({
  formVariant = EFormVariant.Advanced,
  //hasErrorsRef,
  onSubmit,
  //theme = 'light',
  id,
  className,
  isBasicConfiguration = false,
  isStartEndDateDisabled = false,
  //...formFieldsGeneratorProps
}) => {
  const client = useApolloClient();

  const isAdmin = useSelector(selectIsAdmin);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;
  const {
    gridFeePercentage,
    gridFeeConstant,
    //bidOfferMatchAlgo,
    importCapacityKva,
    exportCapacityKva,
    coefficientPercentage,
    baselinePeakEnergyImportKwh,
    baselinePeakEnergyExportKwh,
  } = useSelector(selectCommunityAssetSettings) || {};
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);

  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;

  const [allFields, setAllFields] = useState(
    fieldTemplates({
      values: {
        ...settingsData,
        name: configurationCharacteristic.name,
        description: configurationCharacteristic.description,
        timezone: configurationCharacteristic.timezone,
        locationVisible: configurationCharacteristic.locationVisible,
        gridFeeEnabled: Boolean(gridFeePercentage || gridFeeConstant),
        gridFeePercentage,
        gridFeeConstant,
        transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
        importCapacityKva,
        exportCapacityKva,
        coefficientPercentage,
        baselinePeakEnergyEnabled: Boolean(
          baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
        ),
        baselinePeakEnergyImportKwh,
        baselinePeakEnergyExportKwh,
      },
      configurationCharacteristic,
    }),
  );

  const [allFieldsStored, setAllFieldsStored] = useState<TFieldsUnionWithValue[] | undefined>(
    undefined,
  );

  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);
  const settingsDataMemo = useMemo(() => settingsData, [settingsData]);
  const configurationCharacteristicMemo = useMemo(() => configurationCharacteristic, [
    configurationCharacteristic,
  ]);
  const gridFeePercentageMemo = useMemo(() => gridFeePercentage, [gridFeePercentage]);
  const gridFeeConstantMemo = useMemo(() => gridFeeConstant, [gridFeeConstant]);

  const combineValues = useMemo(
    () =>
      fieldTemplates({
        values: {
          ...settingsDataMemo,
          name: configurationCharacteristicMemo.name,
          description: configurationCharacteristicMemo.description,
          timezone: configurationCharacteristicMemo.timezone,
          locationVisible: configurationCharacteristicMemo.locationVisible,
          gridFeeEnabled: Boolean(gridFeePercentageMemo || gridFeeConstantMemo),
          gridFeePercentage: gridFeePercentageMemo,
          gridFeeConstant: gridFeeConstantMemo,
          transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
          importCapacityKva,
          exportCapacityKva,
          coefficientPercentage,
          baselinePeakEnergyEnabled: Boolean(
            baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
          ),
          baselinePeakEnergyImportKwh,
          baselinePeakEnergyExportKwh,
        },
        configurationCharacteristic: configurationCharacteristicMemo,
      }),
    [
      settingsDataMemo,
      configurationCharacteristicMemo,
      gridFeePercentageMemo,
      gridFeeConstantMemo,
      importCapacityKva,
      exportCapacityKva,
      coefficientPercentage,
      baselinePeakEnergyImportKwh,
      baselinePeakEnergyExportKwh,
    ],
  );

  useEffect(() => {
    if (allFieldsStored && JSON.stringify(combineValues) !== JSON.stringify(allFieldsStored)) {
      setAllFields(combineValues);
      setAllFieldsStored(combineValues);
    }
  }, [combineValues, allFieldsStored, setAllFields, setAllFieldsStored]);

  useEffect(() => {
    if (!allFieldsStored) {
      setAllFieldsStored(allFields);
    }
  }, [setAllFieldsStored, allFields, allFieldsStored]);

  // If a field is not present in the object it will be visible by default.
  const fieldsVisibility: TFieldsVisibility = {
    currency: !isLibrary,
    startEndDate: !isCanaryNetwork && !isLibrary,
    tickLengthSeconds: !isLibrary,
    gridFeeType: !isLibrary,
    slotLengthRealtimeSeconds: !isCanaryNetwork && !isLibrary,
    gridFeeEnabled: !isLibrary,
    gridFeePercentage: Boolean(
      !isLibrary &&
        findField(allFields, 'gridFeeEnabled')?.value &&
        findField(allFields, 'gridFeeType')?.value === GridFeeType.Percentage,
    ),
    gridFeeConstant: Boolean(
      !isLibrary &&
        findField(allFields, 'gridFeeEnabled')?.value &&
        findField(allFields, 'gridFeeType')?.value === GridFeeType.Constant,
    ),
    locationVisible: !isCanaryNetwork,
    timezone: isCanaryNetwork,
    importCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
    exportCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
    coefficientPercentage: false,
    baselinePeakEnergyImportKwh: Boolean(findField(allFields, 'baselinePeakEnergyEnabled')?.value),
    baselinePeakEnergyExportKwh: Boolean(findField(allFields, 'baselinePeakEnergyEnabled')?.value),
    bidOfferMatchAlgo: !isLibrary,
  };

  const visibleFields = getVisibleFields({
    fields: allFields,
    fieldsVisibility,
    formVariant,
    isAdmin,
  });

  const getUsedProjectNames = useCallback(async (): Promise<string[]> => {
    const {
      data,
    }: ApolloQueryResult<ListCollaborationsQuery> = await client.query<ListCollaborationsQuery>({
      query: ListCollaborationsDocument,
      fetchPolicy: 'cache-first',
    });

    const projects = data?.listCollaborations?.configurations || [];

    return projects
      .map((item) => item?.name)
      .filter((item): item is string => typeof item === 'string');
  }, [client]);

  const validateFieldsWrapper = useCallback(
    (fields: TFieldsUnionWithValue[]) => {
      return getUsedProjectNames().then((result) => {
        const output = validateFields({
          validators: validators({
            usedProjectNames: result,
            currentProjectName: isCommunityCreatedInDB ? configurationCharacteristic.name : '',
          }),
          fields,
        });
        setErrors(output.errors);

        return output;
      });
    },
    [setErrors, configurationCharacteristic.name, getUsedProjectNames, isCommunityCreatedInDB],
  );

  const handleChange = useCallback(
    ({ name, value }: THandleChangeArgs) => {
      if (!allFields) return;

      const allFieldsNew = [...allFields];
      /*if (
        // fieldToUpdate?.name === 'transformerCapacityEnabled' ||
        fieldToUpdate?.name === 'baselinePeakEnergyEnabled'
      ) {
        allFieldsNew = updateFields({
          type: 'Area',
          fields: allFields,
          updatedField: { name: name as TAllFieldNames, value },
          ...payload,
        });
      }*/

      // We use immer for immutability, cloneDeep is too expensive
      const newFields = produce(allFieldsNew, (draftState) => {
        const fieldToUpdate = draftState.find((f) => f.name === name);

        if (fieldToUpdate) {
          fieldToUpdate.value = value;

          // Update sibling fields
          switch (fieldToUpdate.name) {
            case 'startEndDate':
              const startEndDate = draftState.find((f) => f.name === 'startEndDate');

              if (startEndDate) {
                startEndDate.EXCLUDE = isCanaryNetwork;
              }
              break;
            case 'gridFeeEnabled':
            case 'gridFeeType':
              // if (gridFeeType) {
              const gridFeePercentageField = draftState.find((f) => f.name === 'gridFeePercentage');
              const gridFeeConstantField = draftState.find((f) => f.name === 'gridFeeConstant');
              // if (gridFeeType === GridFeeType.Constant) {
              if (gridFeePercentageField) {
                gridFeePercentageField.value = null;
                gridFeePercentageField.EXCLUDE = true;
              }
              if (gridFeeConstantField) {
                gridFeeConstantField.value = 0;
                gridFeeConstantField.EXCLUDE = false;
              }
              // } else if (gridFeeType === GridFeeType.Percentage) {
              //   if (gridFeePercentageField) {
              //     gridFeePercentageField.value = 0;
              //     gridFeePercentageField.EXCLUDE = false;
              //   }
              //   if (gridFeeConstantField) {
              //     gridFeeConstantField.value = null;
              //     gridFeeConstantField.EXCLUDE = true;
              //   }
              // }
              // }

              break;
            case 'baselinePeakEnergyEnabled':
            case 'transformerCapacityEnabled':
              const fieldEnabled = draftState.find((f) => f.name === fieldToUpdate.name)?.value;
              const relatedFieldsItem = relatedFormFields.find(
                (item) => item.fieldName === fieldToUpdate.name,
              )?.relatedFields;

              if (relatedFieldsItem) {
                const importExport = relatedFieldsItem.map((subitem) =>
                  draftState.find((f) => f.name === subitem),
                );
                importExport.forEach((item) => {
                  if (item) {
                    item.EXCLUDE = !fieldEnabled;
                    item.value = 0;
                  }
                });
              }
              break;
            default:
              break;
          }
        }
      });
      validateFieldsWrapper(newFields);
      setAllFields(newFields);
    },
    [allFields, validateFieldsWrapper, isCanaryNetwork],
  );

  const renderedFields = useMemo(() => {
    switch (formVariant) {
      case EFormVariant.Advanced:
        return visibleFields?.filter(
          (f) => f.formView === EFormVariant.Advanced || f.formView === EFormVariant.Express,
        );

      default:
        return visibleFields?.filter((f) => f.formView === formVariant);
    }
  }, [visibleFields, formVariant]);

  const handleSubmit = async () => {
    const { errors } = await validateFieldsWrapper(renderedFields);
    if (errors) return;

    const values = getAssetValues(
      allFields.filter((f) => (f.name in fieldsVisibility ? fieldsVisibility[f.name] : true)),
    ) as TCommunitySettingsFields & TSettingsSaveProps['communityAssetSettings'];

    if (values.startEndDate) {
      values.startDate = values.startEndDate.startDate as string;
      values.endDate = values.startEndDate.endDate as string;
    }

    const {
      name,
      description,
      locationVisible,
      // timezone,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      startEndDate,
      gridFeeConstant,
      gridFeePercentage,
      ...newSettingsData
    } = values;
    const timezoneValue = timezoneField?.value || '';

    onSubmit({
      name,
      description,
      locationVisible,
      timezone: timezoneValue,
      settingsData: newSettingsData,
      communityAssetSettings: {
        gridFeeConstant: gridFeeConstant,
        gridFeePercentage: gridFeePercentage,
      },
    });
  };

  const containerRef = useRef<HTMLFormElement>(null);

  const nameField = useMemo(() => renderedFields.find((x) => x.name === 'name'), [renderedFields]);

  const descriptionField = useMemo(() => renderedFields.find((x) => x.name === 'description'), [
    renderedFields,
  ]);
  const startEndDateField: any = useMemo(
    () => renderedFields.find((x) => x.name === 'startEndDate'),
    [renderedFields],
  );
  const currencyField: any = useMemo(() => renderedFields.find((x) => x.name === 'currency'), [
    renderedFields,
  ]);
  const languageField: any = useMemo(() => allFields.find((x) => x.name === 'language'), [
    allFields,
  ]);
  const timezoneField: any = useMemo(() => allFields.find((x) => x.name === 'timezone'), [
    allFields,
  ]);

  return (
    <form
      className={classnames(s.container, className)}
      onSubmit={(e) => {
        e.preventDefault();

        if (readOnly) return;
        handleSubmit();
      }}
      id={id}
      ref={containerRef}>
      {!isBasicConfiguration && (
        <>
          <GridMarketModalHeader formTitle={'Market Type'} />
          <h4 className={s.inputValue}>Community trading </h4>
          {/* <FieldContainer className={classnames(s.inputField)}>
            <BaseSelect
              name={bidOfferMatchAlgoField?.name}
              label={bidOfferMatchAlgoField?.label}
              value={bidOfferMatchAlgoField?.value}
              options={bidOfferMatchAlgoField?.options}
              onChange={(val: any) => handleChange(val)}
              theme={'filled-gray'}
            />
          </FieldContainer> */}
        </>
      )}
      <GridMarketModalHeader formTitle={'Community Name'} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseInput
          name={nameField?.name || ''}
          type={(nameField?.type || 'text') as TBaseInputProps['type']}
          label={nameField?.label}
          theme={'filled-gray'}
          value={(nameField?.value || '') as TBaseInputProps['value']}
          onChange={(val) => handleChange(val as THandleChangeArgs)}
          autoComplete="off"
          error={errors?.[nameField?.name || 0]}
        />
      </FieldContainer>
      <GridMarketModalHeader formTitle={'Notes'} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseTextarea
          name={descriptionField?.name || ''}
          theme={'filled-gray'}
          inputHeight="10"
          value={(descriptionField?.value || '') as TBaseTextareaProps['value']}
          onChange={(val) => handleChange(val as THandleChangeArgs)}
        />
      </FieldContainer>
      {!isBasicConfiguration && (
        <>
          {!isStartEndDateDisabled && (
            <>
              <GridMarketModalHeader formTitle={'Simulation Length'} />
              <FieldContainer className={s.datePickerContainer}>
                <BaseDateRangePicker
                  theme={'light'}
                  startValue={startEndDateField?.value.startDate}
                  endValue={startEndDateField?.value.endDate}
                  valueFormat={startEndDateField?.valueFormat}
                  minDate={UTCMoment.utc(startEndDateField?.value.startDate).add(1, 'day').toDate()}
                  maxDate={UTCMoment.utc(startEndDateField?.value.endDate)
                    .subtract(1, 'day')
                    .toDate()}
                  onChange={({ startDate, endDate }) => {
                    handleChange({
                      name: startEndDateField?.name,
                      value: { startDate: startDate, endDate: endDate },
                    });
                  }}
                  tags={[
                    {
                      value: 7,
                      label: '1 Week',
                      badge: 'Recommended',
                    },
                    //{
                    //  value: 14,
                    //  label: '2 Weeks',
                    //},
                    {
                      value: 30,
                      label: '1 Month',
                    },
                  ]}
                />
              </FieldContainer>
            </>
          )}
          <GridMarketModalHeader formTitle={'Simulation Currency'} />
          <FieldContainer className={classnames(s.inputField)}>
            <BaseSelect
              name={currencyField?.name}
              label={currencyField?.label}
              value={currencyField?.value}
              options={currencyField?.options}
              theme={'filled-gray'}
              onChange={(val: any) => handleChange(val)}
            />
          </FieldContainer>
        </>
      )}
      <GridMarketModalHeader formTitle={'Community Time Zone'} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseSelect
          name={timezoneField?.name}
          label={timezoneField?.label}
          value={timezoneField?.value}
          options={timezoneField?.options}
          theme={'filled-gray'}
          onChange={(val: any) => handleChange(val)}
        />
      </FieldContainer>
      {/* Hide below fields as per the requirements*/}
      {/* {!isBasicConfiguration && (
        <>
          <FieldContainer className={classnames(s.inputField)}>
            <BaseSelect
              name={spotMarketTypeField?.name}
              label={spotMarketTypeField?.label}
              value={spotMarketTypeField?.value}
              options={spotMarketTypeField?.options}
              onChange={(val: any) => handleChange(val)}
              theme={'line-dark'}
            />
          </FieldContainer>

          <FieldContainer className={classnames(s.inputField)}>
            <BaseInput
              name={slotLengthMinutesField?.name}
              type={tickLengthField?.type}
              label={slotLengthMinutesField?.label}
              value={slotLengthMinutesField?.value}
              unit={tickLengthField?.unit}
              onChange={(val: any) => handleChange(val)}
              theme={'line-dark'}
            />
          </FieldContainer>

          <FieldContainer className={classnames(s.inputField)}>
            <BaseInput
              name={tickLengthField?.name}
              type={tickLengthField?.type}
              label={tickLengthField?.label}
              value={tickLengthField?.value}
              unit={tickLengthField?.unit}
              onChange={(val: any) => handleChange(val)}
              theme={'line-dark'}
            />
          </FieldContainer>

          <FieldContainer className={s.switchWrapper}>
            <BaseSwitch
              name={gridFeesField?.name}
              label={gridFeesField?.label}
              onChange={(val: any) => handleChange(val)}
              className={s.switch}
              value={gridFeesField?.value}
              options={gridFeesField?.options}
              theme={'gradient-dark'}
              variant="horizontal-edge"
            />
          </FieldContainer>
          <FieldContainer className={s.switchWrapper}>
            <BaseSwitch
              name={transformerCapacityField?.name}
              label={transformerCapacityField?.label}
              onChange={(val: any) => handleChange(val)}
              className={s.switch}
              value={transformerCapacityField?.value}
              options={transformerCapacityField?.options}
              theme={'gradient-dark'}
              variant="horizontal-edge"
            />
          </FieldContainer>
          <FieldContainer className={s.switchWrapper}>
            <BaseSwitch
              name={baselinePeakEnergyField?.name}
              label={baselinePeakEnergyField?.label}
              onChange={(val: any) => handleChange(val)}
              className={s.switch}
              value={baselinePeakEnergyField?.value}
              options={baselinePeakEnergyField?.options}
              theme={'gradient-dark'}
              variant="horizontal-edge"
            />
          </FieldContainer>
        </>
      )} */}
      {isBasicConfiguration && (
        <>
          <GridMarketModalHeader formTitle={'Language'} />
          <FieldContainer className={classnames(s.inputField)}>
            <BaseSelect
              name={languageField?.name}
              label={languageField?.label}
              value={languageField?.value}
              options={languageField?.options}
              theme={'filled-gray'}
              onChange={(val: any) => handleChange(val)}
            />
          </FieldContainer>
        </>
      )}
    </form>
  );
};
