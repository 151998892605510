import 'react-tooltip/dist/react-tooltip.css';

import React, { useMemo } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import { TCanaryUserInviteProps } from './CanaryUserInvite.types';
import { Tooltip } from 'react-tooltip';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './CanaryUserInvite.module.scss';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import { selectMeasurementRequests } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const CanaryUserInvite: React.FC<TCanaryUserInviteProps> = ({
  setSelectedMember,
  setAssetView,
  wrapperTop,
}) => {
  const dispatch = useAppDispatch();
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const isAdmin = useSelector(selectIsAdmin);
  const measurementRequests = useSelector(selectMeasurementRequests);

  const showInfoToolTip = useMemo(
    () => measurementRequests && measurementRequests.length > 0 && !isAdmin,
    [measurementRequests, isAdmin],
  );
  const addClick = () => {
    setSelectedMember({
      uuid: '',
      name: '',
      assignedAssets: {},
      isAggregator: false,
      isGridOperator: false,
      joined: false,
      isNewUser: true,
    });
    setAssetView(true);
    return;
  };

  const openUploadDataSheetModal = () => {
    dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
  };

  return (
    <div className={s.container}>
      {/* PH-1272-Disable user invite for SCM */}
      <div className={s.emailInvitation}>
        {/*<div>
          <h3>Add Members</h3>
  </div>*/}
        <div>
          {/*<BaseInput
            inputHeight="1"
            className={s.input}
            theme="filled-gray"
            type="text"
            name="email"
            placeholder="Email"
            error={inputError}
            value={inputValue}
            onChange={onValueChange}
          />
          <div
            id="my-anchor-element"
            data-tooltip-html={ReactDOMServer.renderToString(
              <div className={s.tooltipContent}>
                <h3> 👈 Add member here</h3>
                <p>
                  This is to manually add a member. You can also upload an updated datasheet below.
                </p>
              </div>,
            )}>*/}
          <BaseButton
            className={s.fullWidthButton}
            size="medium"
            theme="primary"
            onClick={addClick}>
            Add Member <BaseIcon icon="plus-circle" size={12} />
          </BaseButton>
          {
            //</div>
          }
          <Tooltip
            anchorId="my-anchor-element"
            isOpen={activeSCMStep !== 4 && showInfoToolTip}
            place="right"
            position={{ x: 350, y: 255 - wrapperTop }}
          />
        </div>
      </div>
      <div className={s.uploadSheetLink} onClick={openUploadDataSheetModal}>
        <BaseIcon icon="file-upload" size={16} />
        Upload an updated community datasheet
      </div>
      <div className={s.line}></div>
    </div>
  );
};
