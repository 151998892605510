import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { Currencies } from 'src/graphql';
import { TAssetType } from 'src/typings/base-types';

export type TChartAssetProps = {
  deviceType: TAssetType;
  currency: Currencies;
  priceEnergyAreaBalance;
  stateOfCharge;
  capacityKw: number;
};

export enum ETradeType {
  Trade = 'Trade',
  Sold = 'Sold',
  Bought = 'Bought',
}

export type TTopLeftRowProps = {
  trade: ETradeType;
  isProfit: boolean;
  icon: TIconNames;
  currencySymbol: string;
  num1: number;
  num2: number;
  deviceType: TAssetType;
};

export type TDataOfTopLeftRows = Array<Pick<TTopLeftRowProps, 'trade' | 'num1' | 'num2' | 'icon'>>;
