export const intervalListener = (callback: () => void, checkBusyStatement: () => boolean): void => {
  let isBusy = true;
  const customListener = setInterval(() => {
    isBusy = checkBusyStatement();
    if (isBusy === false) {
      clearInterval(customListener);
      callback();
    }
  }, 1000);
};
